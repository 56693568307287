import { fetchUtils, AuthProvider } from 'react-admin';
const httpClient = fetchUtils.fetchJson;
const authOptions = {
    user : {
        authenticated: true,
        token: localStorage.getItem("token") || undefined,
  }
};

const authProvider: AuthProvider = {
    login: ({ username }) => {
        localStorage.setItem('username', username);
        return Promise.resolve();
    },
    logout: () => {
        return new Promise((res: any) => {
            httpClient(`/logout`, { method: 'GET' })
                .then(() => {
                    localStorage.removeItem('username')
                    localStorage.removeItem('token')
                    res()
                })
                .catch(() => res())
        })
    },
    checkError: (params) => {
        const status = params.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('username');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: () => {
        if (localStorage.getItem('username'))
            return Promise.resolve()

        return new Promise((res: any, rej) => {
            httpClient(`/api/profile`, { method: 'GET', ...authOptions })
                .then(({ json }) => {
                    if (!json.mail || !json.mail.length) window.location.href = '/#/confirm_email';
                    localStorage.setItem('username', json.id);
                    res();
                })
                .catch(() => rej())
        })
    },
    getPermissions: () => Promise.reject('Unknown method'),
    getIdentity: () => {
        return new Promise((res: any) => {
            httpClient(`/api/profile`, { method: 'GET', ...authOptions })
                .then(({ json }) => {
                    res({
                        id: json.id,
                        avatar: json.photo,
                        roles: json.roles,
                        role: json.role,
                        memberOf: json.memberOf,
                        mail: json.mail,
                        isAppAdmin:json.isAppAdmin,
                        isRoleAdmin:json.isRoleAdmin
                    })
                })
                .catch(() => res())
        })
    },
}

export default authProvider;

export const fetchProvider = (path: string, method?: string, body?: any) => {
    try {
        const token = localStorage.getItem('token');
        return fetch(path, {
            method: method || 'GET',
            body: JSON.stringify(body),
            headers: (() => {
                const headers = new Headers({
                    'Content-Type': 'application/json'
                })
                if (token?.trim()) headers.append('Authorization', token!);
                return headers;
            })(),
        });
    } catch (e) {
        return Promise.reject();
    }
}
