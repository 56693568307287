import React, { RefObject } from "react";
import { Card, CardContent } from '@mui/material';
import { useDataProvider, useGetIdentity, useNotify, useTranslate } from "react-admin";
import styles from './ProfileCard.module.css';
import Icon from '../../../assets/Work.png';
import { IProfile } from "../../../utils/types";
import ActionsButton from "../../buttons/actionsButton/ActionsButton";
import DotsIcon from '../../../assets/moreVer.png';
import ActionsProfileMenu from "../../popovers/actionsProfileMenu/ActionsProfileMenu";
import PhotoCameraIcon from '../../../assets/PhotoCamera.png';
import { getBase64 } from "../../../utils/utils";
import { AVATAR_HEIGHT, AVATAR_WIDTH, MAX_SIZE_AVATAR, USER_ROLES } from "../../../utils/constants";
import CustomModal from "../../modals/customModal/CustomModal";
import UploadPhotoModal from "../../modals/uploadPhotoModal/UploadPhotoModal";

type ProfileCardProps = {
  profile: IProfile,
  editable: boolean,
  deletable: boolean,
  isAdmin: boolean,
}

const ProfileCard = (props: ProfileCardProps) => {
  const { profile, editable, deletable, isAdmin } = props;
  const fileInput: RefObject<any> = React.createRef();
  const dataProvider = useDataProvider();
  const [photo, setPhoto] = React.useState(profile ? profile?.photo : '');
  const [active, setActive] = React.useState(profile ? profile?.userAccountControl : '');
  const [showChangePhotoModal, setShowChangePhotoModal] = React.useState(false);
  const notify = useNotify();
  const translate = useTranslate();
  const { identity: user } = useGetIdentity();

  const changePhoto = () => {
    if (isAdmin) onShowAddRoleModal();
    else onUploadFromFile();
  }

  const onUploadFromFile = () => {
    if (fileInput.current) fileInput.current.click();
  }

  const onGetScreenShot = (base64: string) => {
    submitPhoto(base64);
  }

  const onShowAddRoleModal = () => {
    setShowChangePhotoModal(true);
  }

  const onCloseChangePhotoModal = () => {
    setShowChangePhotoModal(false);
  }

  const validPhotoSize = (file: any) => {
    return new Promise((resolve) => {
      const img = new Image()
      img.onload = (e: any) => {
        if (!e.path && !e.target) {
          throw new Error("");
        }

        let width = 0;
        let height = 0;
        if (e.path) {
          width = e.path[0].width;
          height = e.path[0].height;
        }

        if (e.target) {
          width = e.target.width;
          height = e.target.height;
        }

        if (width > AVATAR_WIDTH || height > AVATAR_HEIGHT) resolve(false);
        else resolve(true);
      }
      img.src = URL.createObjectURL(file);
    })
  }

  const submitPhoto = (base64: string) => {
    dataProvider.update('user', { id: profile.id, data: { photo: base64 }, previousData: profile })
      .then((res: any) => {
        if (res?.data?.photo) {
          setPhoto(String(base64));
          onCloseChangePhotoModal();
        } else
          notify('ra.notification.approvePhoto', { type: 'success', messageArgs: { smart_count: 1 } });
      })
  }

  const checkValidPhoto = (file: File):boolean => {
    if (file && ['jpg', 'jpeg', 'png', 'svg+xml'].includes(file.type.split('/')[1]) && file.size <= MAX_SIZE_AVATAR) {
      return true;
    } else if (file && !['jpg', 'jpeg', 'png', 'svg+xml'].includes(file.type.split('/')[1])) {
      notify('ra.notification.approvePhotoErrorFormat', { type: 'warning', messageArgs: { smart_count: 1 } });
      return false;
    } else if (file && file.size > MAX_SIZE_AVATAR) {
      notify('ra.notification.approvePhotoErrorSize', { type: 'warning', messageArgs: { smart_count: 1 } });
      return false;
    } return false;
  }

  const onSelectPhoto = async () => {
    const file = fileInput.current.files && fileInput.current.files[0];
    const isValidSize = await validPhotoSize(file);
    if (!isValidSize) notify('ra.notification.approvePhotoErrorResolution', { type: 'warning', messageArgs: { smart_count: 1 } });
    else if (checkValidPhoto(file)) {
      const base64 = await getBase64(file);
      submitPhoto(base64);
    }
  }

  React.useEffect(() => {
    const photo = profile.photo || '';
    setPhoto(String(photo))
    setActive(profile.userAccountControl)
  }, [profile, dataProvider]);

  return (
    <Card>
      <CardContent>
        <div className={styles.root}>
          <div style={{ backgroundImage: `url(${photo || ''})`, backgroundPosition: 'center center', backgroundSize: 'cover' }} className={styles.icon}>
            {
              photo?.length ? null : <img src={Icon} alt="icon" />
            }
            {
              profile.editable && (isAdmin || profile?.id === user?.id) ? <div className={styles.icon_camera} onClick={changePhoto}>
                <img src={PhotoCameraIcon} alt="" />
              </div> : null
            }
            <input
              type="file"
              name="photo"
              id="upload_person_photo"
              accept="image/*"
              ref={fileInput}
              onChange={onSelectPhoto}
              className={styles.input_photo}
            />
          </div>
          <div className={styles.info}>
            <div className={styles.name}>
              <div>{`${profile?.sn ? profile?.sn : ''} ${profile?.givenName}`}</div>
              <span className={active ? styles.status_active : styles.status_blocked}>{{ 'true': translate('operations.params.STATUS.active'), 'false': translate('operations.params.STATUS.disabled') }[String(active)]}</span>
            </div>
            <span className={styles.role}>{USER_ROLES[profile.role]}</span>
          </div>
          {
            editable ?
              <div className={styles.button}>
                <ActionsButton icon={DotsIcon} MenuElement={ActionsProfileMenu} menuElementProps={{ record: profile, deletable }} />
              </div>
              :
              null
          }
        </div>
      </CardContent>
      <CustomModal
        open={showChangePhotoModal}
        close={onCloseChangePhotoModal}
      >
        <UploadPhotoModal
          closeModal={onCloseChangePhotoModal}
          openFile={onUploadFromFile}
          setPhoto={onGetScreenShot}
        />
      </CustomModal>
    </Card>
  )
};

export default ProfileCard;