import React from "react";
import styles from './BlockUserModal.module.css';
import CloseIcon from '../../../assets/close.png';
import CancelButton from "../../buttons/cancelButton/CancelButton";
import CustomButton from "../../buttons/customButton/CustomButton";
import { useTranslate } from 'react-admin';
import CustomTextArea from '../../inputs/customTextArea/CustomTextArea';

type BlockUserModalProps = {
	closeModal: () => void,
	onBlockUser: (v: string) => void,
	names: string | string[],
}

const BlockUserModal = (props: BlockUserModalProps) => {
	const { onBlockUser, closeModal, names } = props;
	const translate = useTranslate();
	const [step, setStep] = React.useState('creating');
	const [comment, setComment] = React.useState('');

	const onChangeCause = (v: string) => {
		setComment(v);
	}

	const onComeBack = () => {
		setStep('creating');
	}

	const onComeForvard = () => {
		setStep('creating_next');
	}

	return (
		step === 'creating' ?
			<div className={styles.root}>
				<div className={styles.close_button} onClick={closeModal}>
					<img src={CloseIcon} alt="" />
				</div>
				<h1 className={styles.header}>{translate('messages.blockUser')}</h1>
				<span className={styles.text}>{`${translate('messages.reqBlockUser')} ${Array.isArray(names) ? translate('messages.multipleUsers') : translate('messages.fromUser')} ${Array.isArray(names) ? `(${names.length})` : names}?`}</span>
				<div className={styles.buttons}>
					<CancelButton text={translate('ra.action.cancel')} onClick={closeModal} />
					<CustomButton text={translate('ra.action.block')} onClick={onComeForvard} />
				</div>
			</div> :
			<div className={styles.root}>
				<h1 className={styles.header}>{translate('messages.inputReason')}</h1>
				<CustomTextArea placeholder={translate('messages.commentText')} mode={'root'} value={comment} changeInput={onChangeCause} focus={false} limit={255} />
				<div className={styles.buttons_wrapper}>
					<CancelButton text={translate('ra.action.comeback')} onClick={onComeBack} />
					<div className={styles.buttons_textarea}>
						<CancelButton text={translate('ra.action.cancel')} onClick={closeModal} />
						<CustomButton text={translate('ra.action.confirm')} onClick={() => onBlockUser(comment)} />
					</div>
				</div>
			</div>
	)
}

export default BlockUserModal;