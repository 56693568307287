import {
	AutocompleteArrayInput,
	BooleanField,
	Datagrid,
	DateInputProps,
	FilterForm,
	FunctionField, Labeled,
	List,
	Loading,
	NullableBooleanInput, ReferenceArrayInput,
	ReferenceField,
	SearchInput,
	SelectArrayInput,
	TextField, useInput,
	useListContext,
	useListController,
	useTranslate
} from 'react-admin';
import { dateFormat, downloadURI } from "../../../utils/utils";
import styles from './OperationsPage.module.css';
import CustomDateRangePicker from "../../inputs/customDateRangePicker/CustomDateRangePicker";
import Tooltip from '@material-ui/core/Tooltip';
import React from "react";
import ReportsMenu from "../../popovers/reportsMenu/reportsMenu";
import { Stack } from '@mui/material';
import customRuMessages from '../../../i18n/ru';
import CustomFilterButton from '../../buttons/customFilterButton/CustomFilterButton';

const OperationPage = () => {
	const translate = useTranslate();
	const { filterValues, setFilters, displayedFilters, isLoading } = useListController();

	const labeledStyle = {
		backgroundColor: 'rgba(0, 0, 0, 0.04)',
		borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
		'& p span': {
			fontFamily: 'Roboto, sans-serif',
			fontStyle: 'normal',
			fontWeight: 400,
			fontSize: '.8rem',
			marginLeft: '10px',
			marginTop: '5px',
		}
	}

	const CustomDateInput = (props: DateInputProps) => {
		const { field } = useInput(props);
		return (
			<Labeled label={translate('resources.operation.fields.createdAt')} sx={labeledStyle}>
				<CustomDateRangePicker
					startDate={Array.isArray(field.value) ? new Date(field.value[0]) : null}
					endDate={Array.isArray(field.value) ? new Date(field.value[1]) : null}
					onChangeDate={(d1, d2) => {
						const start = new Date(d1);
						const end = new Date(d2);
						start.setHours(0);
						start.setMinutes(0);
						start.setSeconds(0);
						start.setMilliseconds(0);
						end.setHours(23);
						end.setMinutes(59);
						end.setSeconds(59);
						setFilters({ ...filterValues, createdAt: [start, end] }, displayedFilters);
					}}
				/>
			</Labeled>
		)
	}

	const operationFilters = [
		<SearchInput source="q" alwaysOn />,
		<CustomDateInput source='createdAt'
			margin='none'
		/>,
		<ReferenceArrayInput
			source="owner"
			label='Автор'
			reference="user"
			sort={{ field: 'displayName', order: 'ASC' }}
			filter={{ type: 0 }}
			perPage={1000}
			sx={{
				maxWidth: '200px'
			}}
		>
			<AutocompleteArrayInput
				fullWidth={true} sx={{ minWidth: 240 }}
				noOptionsText={'ra.navigation.no_results'}
				label='Автор'
				optionText="displayName"
			/>
		</ReferenceArrayInput>,
		<SelectArrayInput source='type' choices={
			['ADD', 'POST', 'PUT', 'DELETE', 'LOGIN', 'LOGOUT', 'MOVE']
				.map(item => ({ id: item, name: `operations.actions.${item}` }))
		} />,
		<SelectArrayInput source='entry' choices={
			['AUTH', 'USER', 'GROUP', 'APP', 'ROLE', 'APPROVAL', 'MEMBERS', 'ADMINS', 'CONFIRMEMAIL', 'MOVEAPP']
				.map(item => ({ id: item, name: `operations.entities.${item}` }))
		} />,
		<NullableBooleanInput source='success' margin='none' fullWidth={true} sx={{ minWidth: 100 }}
			label="resources.operation.fields.success"
			nullLabel="operations.params.STATUS.any"
			falseLabel="operations.params.STATUS.fail"
			trueLabel="operations.params.STATUS.success"
		/>]

	const CustomListToolBar = () => {
		const { filterValues, filter, sort } = useListContext();

		const uploadReport = (reportId: string) => {
			const reportFilter = JSON.stringify({ ...filter, ...filterValues });
			const reportSort = JSON.stringify([sort?.field, sort?.order]);

			downloadURI(`/api/report/${reportId}?filter=${reportFilter}&sort=${reportSort}&_t=${new Date().getTime()}`)
		}

		return (
			<Stack direction="row" justifyContent="space-between" sx={{ width: "100%", alignItems: "center" }}>
				<FilterForm filters={operationFilters} />
				<div className={styles.filters}>
					<CustomFilterButton filters={operationFilters} />
					<Stack direction="row" sx={{ alignItems: "center" }}>
						<ReportsMenu closePopover={uploadReport} ids={[3]} />
					</Stack>
				</div>
			</Stack>
		)
	}

	if (isLoading) return <Loading />

	return (
		<div>
			<h1>{translate('operations.entities.EVENTS')}</h1>
			<List
				actions={<CustomListToolBar />}
				exporter={false}
				disableSyncWithLocation
				sort={{ field: 'createdAt', order: 'DESC' }}>
				<Datagrid optimized isRowSelectable={() => false} bulkActionButtons={false}
					sx={{
						'& .RaDatagrid-headerCell': {
							borderColor: "#e0e0e0",
							backgroundColor: "#fff0",
						},
						'& .RaDatagrid-rowCell': { maxWidth: 200 },
					}}
				>
					<FunctionField source="createdAt" sortByOrder="DESC"
						render={(record: any) => dateFormat(record.createdAt, { showTime: true, showMilliseconds: true })}
					/>
					<ReferenceField source="owner" reference="user" link={(record: any) => record.owner === 'SYSTEM' ? "#" : "show"} >
						<FunctionField source="givenName"
							render={(record: any) => `${record.sn || ''} ${record.givenName || ''}`}
						/>
					</ReferenceField >
					<FunctionField source="type"
						render={(record: any) => translate(`operations.actions.${record.type}`)}
					/>
					<FunctionField source="entry"
						render={(record: any) => translate(`operations.entities.${record.entry}`)}
					/>
					<FunctionField source="identity"
						render={(record: any) => {
							const { identity } = record;
							if (!identity) return null;
							const entry = ['MEMBERS', 'ADMINS'].includes(record.entry) ? 'USER' : record.entry;
							const showLink = record.success && record.type === 'DELETE' && !['MEMBERS', 'ADMINS'].includes(record.entry) ? false : "show";
							switch (entry) {
								case 'USER': return <ReferenceField source="identity" reference="user" link={showLink} emptyText={identity} ><FunctionField source="givenName" render={(record: any) => `${record.sn || ''} ${record.givenName || ''}`} /></ReferenceField >
								case 'ROLE': return <ReferenceField source="identity" reference="role" link={showLink} emptyText={identity} ><TextField source="name" /></ReferenceField >
								case 'MOVEAPP': return <ReferenceField source="identity" reference="role" link={showLink} emptyText={identity} ><TextField source="name" /></ReferenceField >
								case 'APP': return <ReferenceField source="identity" reference="app" link={showLink} emptyText={identity} ><TextField source="name" /></ReferenceField >
								case 'APPROVAL': return <ReferenceField source="identity" reference="approval" link="show" >
									<FunctionField source="id" render={(record: any) =>
										record.type === 'photo' ?
											<img className={styles.icon} alt="" src={record.target} />
											:
											< ReferenceField source="target" reference="role" link={`/approval/${record.id}/show`} ><TextField source="name" /></ReferenceField >
									} /></ReferenceField >
								default: return identity
							}
						}}
					/>
					<FunctionField source="params"
						render={(record: any) => {
							if (!record.success) return <Tooltip title={record.params}><a>{translate(`ra.notification.http_error`)}</a></Tooltip>;
							if (!record.params) return null;

							if (customRuMessages.operations.params[record.entry] && customRuMessages.operations.params[record.entry][record.params])
								return translate(`operations.params.${record.entry}.${record.params}`);
							return record.params;
						}}
					/>
					<FunctionField source="comment"
						render={(record: any) => <Tooltip title={record.comment}><span>{record.comment}</span></Tooltip>}
					/>
					<BooleanField source="success"
						valueLabelTrue={'operations.params.STATUS.success'}
						valueLabelFalse={'operations.params.STATUS.fail'}
					/>
				</Datagrid>
			</List>
		</div >
	)
};
export default OperationPage;