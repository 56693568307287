import React from "react";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ClickAwayListener } from "@material-ui/core";
import ruLocale from 'date-fns/locale/ru';
import './CustomDatePicker.css';
import { useTranslate } from "react-admin";

type CustomDatePickerProps = {
  date: Date,
  onChangeDate: (value: Date | null) => void,
}

const CustomDatePicker = (props: CustomDatePickerProps) => {
  const {date, onChangeDate} = props;
  const [openPicker, setOpenPicker] = React.useState(false);
	const translate = useTranslate();

  const openPickerHandler = () => {
    setOpenPicker(true);
  }

  const closePickerHandler = () => {
    setOpenPicker(false);
  }

  const clickOut = (e: any) => {
    if (!e.target.closest('.MuiCalendarPicker-root')) setOpenPicker(false);
  }

  React.useEffect(() => {
    if (openPicker) {
      document.addEventListener('closeDatePicker', closePickerHandler);
      return () => document.removeEventListener('closeDatePicker', closePickerHandler);
    }
    //eslint-disable-next-line
  }, [openPicker]);

  return (
    <div className={'custom_date_picker'}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
        <ClickAwayListener onClickAway={clickOut}>
          <DatePicker
            views={['year', 'month', 'day']}
            onChange={onChangeDate}
            value={date}
            //@ts-ignore
            date={date}
            //@ts-ignore
            openPicker={openPickerHandler}
            onClose={closePickerHandler}
            open={openPicker}
            renderInput={(params: any) => {
              params.inputProps.placeholder = translate('operations.params.APP.dateFormat')
              return <TextField {...params} variant={'outlined'}/>
            }}
            rawValue={date}
            mask="__.__.____"
          />
        </ClickAwayListener>
      </LocalizationProvider>
    </div>
  )
}
export default CustomDatePicker;