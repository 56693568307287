import React, { RefObject } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Card, CardContent } from '@mui/material';
import {
  useDataProvider,
  useNotify,
  useRedirect,
  useGetOne,
  useUpdate, useGetIdentity, useTranslate
} from "react-admin";
import styles from './CreateAppPage.module.css';
import CustomTooltip from "../../tooltips/customTooltip/CustomTooltip";
import backIcon from '../../../assets/Back.png';
import CustomInput from "../../inputs/customInput/CustomInput";
import CancelButton from "../../buttons/cancelButton/CancelButton";
import CustomButton from "../../buttons/customButton/CustomButton";
import SaveIcon from '../../../assets/Save.png';
import AvatarIcon from '../../../assets/WidgetsGray.png';
import CustomTextArea from "../../inputs/customTextArea/CustomTextArea";
import CustomInputGroup from "../../inputs/customInputGroup/CustomInputGroup";
import { getBase64 } from "../../../utils/utils";
import CustomBreadcrumbs from "../../customBreadcrumbs/CustomBreadcrumbs";
import { MAX_SIZE_LOGO } from "../../../utils/constants";
import CustomCheckbox from '../../inputs/customCheckbox/CustomCheckbox';

const CreateAppPage = () => {
  const dataProvider = useDataProvider();
  const id = useParams().id || 'null';

  useGetOne('app', { id }, {
    onSuccess: (data) => setApp(data),
    onError: () => false,
    retry: false,
  });
  const notify = useNotify();
  const redirect = useRedirect();
  const [app, setApp]: any = React.useState(null);
  const [name, setName] = React.useState(app && app.name ? app.name : '');
  const [description, setDescription] = React.useState(app && app.description ? app.description : '');
  const [address, setAddress] = React.useState(app && app.url ? app.url : '');
  const [validAddress, setValidAddress] = React.useState(true);
  const [addressErrorMessage, setAddressErrorMessage] = React.useState('');
  const [avatarSrc, setAvatarSrc] = React.useState(app && app.logo ? app.logo : null);
  const [selectedGroup, setSelectedGroup] = React.useState(app && app.adminGroup ? app.adminGroup : '');
  const [fileError, setFileError] = React.useState(false);
  const [messageFile, setMessageFile] = React.useState('');
  const [isActive, setIsActive] = React.useState<boolean>(app && (app.active !== undefined && app.active !== null) ? app.active : true);
  const navigate = useNavigate();
  const fileInput: RefObject<any> = React.createRef();
  const { identity: user } = useGetIdentity();
  const isCreate = useLocation().pathname.includes('create');
	const translate = useTranslate();

  const [updateApp] = useUpdate(
    'app',
    {
      id: id,
      data: {
        name,
        description,
        adminGroup: selectedGroup,
        url: address,
        owner: 'admin',
        logo: typeof avatarSrc === 'string' ? avatarSrc : getBase64(avatarSrc),
        active: isActive
      }
    },
    {
      mutationMode: 'undoable',
      onSuccess: () => {
        notify('ra.notification.updated', { type: 'success', messageArgs: { smart_count: 1 }, undoable: true });
        redirect('show', 'app', id);
      },
      onError: () => notify('ra.notification.canceled', { type: 'warning' })
    }
  );

  const onChangeName = (v: string) => {
    if (v.length <= 50) setName(v);
  }

  const onChangeDescription = (v: string) => {
    setDescription(v);
  }

  const onChangeAddress = (v: string) => {
    // eslint-disable-next-line
    if (/(^[a-zA-Z0-9]{3,5}):\/\/((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|((([a-zA-Z\-0-9]{2,}\.)+[a-zA-Z]{2,})+[:\/a-zA-Z0-9]*))$/.test(v) && v.length <= 255) {
      setValidAddress(true);
      setAddressErrorMessage('');
    } else if (v.length > 255) {
      setValidAddress(false);
      setAddressErrorMessage(translate('operations.params.APP.appAddressLimit'));
    } else {
      setValidAddress(false);
      setAddressErrorMessage(translate('operations.params.APP.invalidLinkFormat'));
    }
    setAddress(v);
  }

  const onComeBack = () => {
    navigate(-1);
  }

  const changePhoto = () => {
    if (fileInput.current) fileInput.current.click();
  }

  const removePhoto = () => {
    if (avatarSrc) {
      setAvatarSrc(null)
    }
  }

  const onSelectPhoto = async () => {
    if (fileInput.current.files && fileInput.current.files[0] && ['jpg', 'jpeg', 'png', 'svg+xml'].includes(fileInput.current.files[0].type.split('/')[1]) && fileInput.current.files[0].size <= MAX_SIZE_LOGO) {
      const file = fileInput.current.files[0];
      setAvatarSrc(await getBase64(file));
      setFileError(false);
      setMessageFile('');
    } else if (fileInput.current.files && fileInput.current.files[0] && !['jpg', 'jpeg', 'png', 'svg+xml'].includes(fileInput.current.files[0].type.split('/')[1])) {
      setFileError(true);
      setMessageFile(translate('ra.notification.approvePhotoErrorFormat'));
    } else if (fileInput.current.files && fileInput.current.files[0] && fileInput.current.files[0].size > MAX_SIZE_LOGO) {
      setFileError(true);
      setMessageFile(translate('operations.params.APP.imageSize'));
    }
  }

  const onSuccess = (id: number) => {
    notify('ra.notification.updated', { type: 'success', messageArgs: { smart_count: 1 }, undoable: true });
    redirect('show', 'app', id);
  };

  const onCreateApp = async () => {
    if (app && !isCreate) {
      updateApp()
    } else {
      const data: any = {
        name,
        description,
        adminGroup: selectedGroup,
        url: address,
        owner: 'admin',
        active: isActive
      }
      if (avatarSrc && typeof avatarSrc !== 'string') data.logo = await getBase64(avatarSrc);
      else if (avatarSrc) data.logo = avatarSrc;
      dataProvider.create('app', { data: data })
        .then(({ data }) => onSuccess(data.id))
        .catch(() => notify('ra.notification.canceled', { type: 'warning' }))
    }
  }

  const onClickBeadcrumb = (e: any) => {
    if (e.target.innerText === translate('operations.entities.APP')) navigate('/app');
    else navigate(`/app/${id}/show`);
  }

  const handleChangeActive = (e:any) => {
    setIsActive((current:any) => !current);
  };

  React.useEffect(() => {
    if (!isCreate && app) {
      setName(app.name);
      setDescription(app.description);
      setAddress(app.url);
      setSelectedGroup(app.adminGroup);
      setAvatarSrc(app.logo);
      setIsActive((app.active !== undefined && app.active !== null) ? app.active : true)
    } else if (isCreate) {
      setName('');
      setDescription('');
      setAddress('');
      setSelectedGroup('');
      setAvatarSrc('');
      setApp(null)
      setIsActive(true)
    }
  }, [app, isCreate])

  return (
    <div className={styles.wrapper}>
      <div className={styles.root}>
        {
          id && app && !isCreate ?
            <header>
              <CustomBreadcrumbs breadcrumb={`${translate('operations.params.APP.settings')},${app.name}`} onClickBreadcrumb={onClickBeadcrumb} title={translate('operations.entities.APP')} />
            </header> :
            <header>
              <CustomTooltip title={translate('ra.action.comeback')}>
                <div className={styles.back_icon} onClick={onComeBack}>
                  <img src={backIcon} alt="icon" />
                </div>
              </CustomTooltip>
              <h1>{translate('operations.params.APP.addApplication')}</h1>
            </header>
        }
        <Card>
          <CardContent>
            <h4>{translate('operations.params.APP.applicationParameters')}</h4>
            <div className={styles.row_item}>
              <span className={styles.row_item__label}>{translate('operations.params.APP.applicationName')}<span style={{ color: 'red' }}>*</span></span>
              <CustomInput placeholder={translate('operations.params.APP.enterName')} mode={'root'} value={name} changeInput={onChangeName} errorMessage={''} focus={false} />
            </div>
            <div className={styles.row_item}>
              <span className={styles.row_item__label}>{translate('operations.params.APP.appDescription')}</span>
              <CustomTextArea placeholder={translate('operations.params.APP.enterText')} mode={'root'} value={description} changeInput={onChangeDescription} focus={false} limit={250} />
            </div>
            <div className={styles.block_photo}>
              <span className={styles.block_photo__label}>{translate('operations.params.APP.appCover')}</span>
              <div className={styles.block_photo__buttons}>
                <div className={styles.block_photo__buttons__avatar}>
                  <img src={avatarSrc ? typeof avatarSrc === 'string' ? avatarSrc : URL.createObjectURL(avatarSrc) : AvatarIcon} className={avatarSrc ? styles.photo : styles.icon} alt="" />
                </div>
                <input
                  type="file"
                  name="photo"
                  id="upload_person_photo"
                  accept="image/*"
                  ref={fileInput}
                  onChange={onSelectPhoto}
                  className={styles.block_photo__buttons__input}
                />
                {
                  avatarSrc ?
                    <div className={styles.block_photo__buttons__load} onClick={removePhoto}>{translate('ra.action.delete')}</div>
                    : null
                }
                <div className={styles.block_photo__buttons__load} onClick={changePhoto}>{translate('ra.action.download')}</div>
              </div>
              {
                fileError ?
                  <span className={styles.block_photo__description_error}>{messageFile}</span> :
                  <span className={styles.block_photo__description}>{translate('operations.params.APP.fileExtension')}</span>
              }
            </div>
            <div className={styles.row_item}>
              <span className={styles.row_item__label}>{translate('operations.params.APP.appAddress')}<span style={{ color: 'red' }}>*</span></span>
              <CustomInput placeholder={translate('operations.params.APP.enterAddress')} mode={validAddress ? 'root' : 'error'} value={address} changeInput={onChangeAddress} errorMessage={addressErrorMessage} focus={false} />
              <span className={styles.block_photo__description}>{translate('operations.params.APP.appAddressProtocol')}</span>
            </div>
            {
              (app && !isCreate && user?.role === 1) || isCreate ?
                <div>
                  <h4>{translate('operations.params.APP.connectingGroupOfAdministrators')}</h4>
                  <div className={styles.row_item}>
                    <span className={styles.row_item__label}>{translate('operations.params.APP.membersWillManageTheApplication')}</span>
                  </div>
                  <div className={styles.row_item}>
                    <CustomInputGroup selected={selectedGroup} onChange={setSelectedGroup} />
                  </div>
                </div> : null
            }
            <div className={styles.row_item}>
              <div className={styles.checkbox}>
                <CustomCheckbox checked={isActive} change={handleChangeActive} indeterminate={false} around={false} />
                <span className={styles.row_item__label}>{translate('operations.params.APP.appPublicity')}</span>  
              </div>
              <span className={styles.block_photo__description}>{translate('operations.params.APP.displayingPublicity')}</span>
            </div>
            <div className={styles.buttons}>
              <CancelButton text={translate('ra.action.abort')} onClick={onComeBack} />
              <CustomButton text={translate('ra.action.save')} onClick={onCreateApp} iconSrc={SaveIcon} disable={!name.length || !address.length || (!selectedGroup && (isCreate || app) && user?.role === 1)} />
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

export default CreateAppPage;