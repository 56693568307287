import React from "react";
import {FormControl, ListItemText, MenuItem, Select} from "@mui/material";
import './CustomSelect.css';

type CustomSelectProps = {
  defaultValue: string,
  changeValue: (v: string) => void,
  menuElements: string[],
  mode: string,
  disabled?: boolean,
}

const CustomSelect = (props: CustomSelectProps) => {
  const {defaultValue, changeValue, menuElements, mode, disabled} = props;
  const [value, setValue] = React.useState(defaultValue);
  const [openSelect, setOpenSelect] = React.useState(false);
  const customSelectRef = React.useRef(null);
  const handleOpen = () => {
    setOpenSelect(true);
  };
  const handleClose = () => {
    setTimeout(() => {
      setOpenSelect(false);
    }, 200)
  }
  const handleChange = (e: any) => {
    setValue(e.target.value);
    changeValue(e.target.value);
  };
  const styledClassName: string = {
    'root': 'custom_select',
    'error': 'custom_select error_state',
    'success': 'custom_select success_state',
  }[mode] || 'custom_select';

  React.useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <div className={styledClassName} ref={customSelectRef} onClick={handleOpen}>
      <FormControl fullWidth disabled={disabled}>
        <Select
          variant="outlined"
          value={value}
          open={openSelect && !disabled}
          onClose={handleClose}
          onChange={handleChange}
          renderValue={(selected => selected)}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            anchorEl: customSelectRef.current,
            style: {
              marginTop: -8,
            },
            elevation: 5,
          }}
        >
          {menuElements.map(item => (
            <MenuItem value={item} key={item} sx={{width: '100%'}}>
              <ListItemText>
                <div className={'custom_select_device_type__item_list'}>{item}</div>
              </ListItemText>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default CustomSelect;