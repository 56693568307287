import React from "react";
import {
	useDataProvider, useListController, useNotify,
	useRefresh, useTranslate,
	useUnselectAll,
} from "react-admin";
import styles from './RemoveBulkRoleAccessModal.module.css';
import CloseIcon from "../../../assets/close.png";
import CancelButton from "../../buttons/cancelButton/CancelButton";
import CustomButton from "../../buttons/customButton/CustomButton";
import {IAccessRoles, IProfile, IRole} from "../../../utils/types";
import CustomTextArea from '../../inputs/customTextArea/CustomTextArea';
import {transformRoleName} from "../../../utils/utils";

type RemoveBulkRoleAccessModalProps = {
	closeModal: () => void,
	user: IProfile,
}

const RemoveBulkRoleAccessModal = (props: RemoveBulkRoleAccessModalProps) => {
	const { closeModal, user } = props;
	const dataProvider = useDataProvider();
	const refresh = useRefresh();
	const notify = useNotify();
	const translate = useTranslate();
	const unselectAll = useUnselectAll('role');
	const { selectedIds } = useListController();
	const [selected, setSelected] = React.useState<IRole[]>([]);
	const steps = ['choose', 'confirm', 'comment'];
	const [step, setStep] = React.useState<string>(steps[0])
	const [rolesDepsList, setRolesDepsList] = React.useState<IAccessRoles[]>();
	const [comment, setComment] = React.useState('');

	const onChangeCause = (v: string) => {
		setComment(v);
	}

	React.useEffect(() => {
		dataProvider.getMany('role', { ids: selectedIds }).then(({ data }) => setSelected(data))
	}, [selectedIds, dataProvider])

	const onRemoveAccess = async () => {
		await Promise.all(
			selected.map((item: IRole) => {
				return dataProvider.update('group', {
					id: item.userGroup, data: {
						member: {
							delete: user.id
						},
						comment: comment
					}, previousData: {}
				}).catch(() => notify('ra.notification.canceled', { type: 'warning' }))
			}))
		refresh();
		unselectAll();
		notify('ra.notification.revoked', { type: 'success', messageArgs: { smart_count: 1 }, undoable: true });
		closeModal();
	}

	const checkGroup = async () => {
		const accessList: any = await Promise.all(
			selected.map((role: IRole) => new Promise(async (resolve) => {
				const roleDeps = await dataProvider.getOne('depsRole', {id: role.id});
				resolve({
					role,
					appAdminsList: roleDeps.data.appAdmins,
					roleAdminsList: roleDeps.data.roleAdmins,
					roleUsersList: roleDeps.data.roles,
				});
			}))
		)
		setRolesDepsList(accessList);
		if (accessList.reduce((result: boolean, item: IAccessRoles) => result || item.roleUsersList.length || item.appAdminsList.length || item.roleAdminsList.length, false)) setStep(steps[1]);
		else setStep(steps[2]);
	}

	const onGoToComment = () => {
		setStep(steps[2]);
	}

	return (
		step === steps[0] ?
			<div className={styles.root}>
				<div className={styles.close_button} onClick={closeModal}>
					<img src={CloseIcon} alt="" />
				</div>
				<h1 className={styles.header}>{translate('messages.revokeAccess')}</h1>
				<div className={styles.text}>{translate('messages.revokeAccessManyTo', { user: `${translate('messages.fromUser')} ${user.displayName || user.givenName || user.id}` })}</div>
				<ul className={styles.list}>
					{
						selected.map((item: IRole) => <li className={styles.list_item} key={item.id}>{item.name}</li>)
					}
				</ul>
				<div className={styles.buttons}>
					<CancelButton text={translate('ra.action.cancel')} onClick={closeModal} />
					<CustomButton text={translate('ra.action.confirm')} onClick={checkGroup} />
				</div>
			</div> :
			step === steps[1] ?
			<div className={styles.root}>
				<div className={styles.close_button} onClick={closeModal}>
					<img src={CloseIcon} alt="" />
				</div>
				<span className={styles.title}>{translate('messages.revokeAccess')}</span>
				<div className={styles.content}>
					{
						rolesDepsList!.map((roleItem: IAccessRoles) => (
							roleItem.roleUsersList.length || roleItem.appAdminsList.length || roleItem.roleAdminsList.length ?
								<div className={styles.roles_wrapper}>
									<div className={styles.text}>{translate('messages.revokeAccessRoleMessage', { role: transformRoleName(roleItem.role.name, 44) })}</div>
									<ul className={styles.list}>
										{roleItem.roleUsersList.length ? roleItem.roleUsersList.map((item: string) => <li className={styles.list_item} key={item}>{transformRoleName(item, 40)}</li>) : null}
										{roleItem.appAdminsList.length ? roleItem.appAdminsList.map((item: string) => <li className={styles.list_item} key={item}>{`${translate('messages.appAdministration')} ${transformRoleName(item, 38)}`}</li>) : null}
										{roleItem.roleAdminsList.length ? roleItem.roleAdminsList.map((item: string) => <li className={styles.list_item} key={item}>{`${translate('messages.roleAdministration')} ${transformRoleName(item, 38)}`}</li>) : null}
									</ul>
								</div> : null
						))
					}
				</div>
				<div className={styles.buttons}>
					<CancelButton text={translate('ra.action.cancel')} onClick={closeModal} />
					<CustomButton text={translate('ra.action.confirm')} onClick={onGoToComment} />
				</div>
			</div> :
			<div className={styles.root}>
				<div className={styles.close_button} onClick={closeModal}>
					<img src={CloseIcon} alt="" />
				</div>
				<h1 className={styles.header_textarea}>{translate('messages.inputRevokeReason')}</h1>
				<CustomTextArea placeholder={translate('messages.commentText')} mode={'root'} value={comment} changeInput={onChangeCause} limit={255} focus={false} />
				<div className={styles.buttons}>
					<CancelButton text={translate('ra.action.cancel')} onClick={closeModal} />
					<CustomButton text={translate('ra.action.confirm')} onClick={onRemoveAccess} />
				</div>
			</div>
	)
}

export default RemoveBulkRoleAccessModal;