import ru from 'ra-language-russian';

ru
    .ra
    .saved_queries = {
    label: 'Сохраненые фильтры',
    query_name: 'Имя фильтра',
    new_label: 'Сохранить текущий фильтр...',
    new_dialog_title: 'Сохранить текущий фильтр как',
    remove_label: 'Удалить сохраненный запрос',
    remove_label_with_name: 'Удалить фильтр "%{name}"',
    remove_dialog_title: 'Удалить сохраненный фильтр?',
    remove_message:
        'Вы уверены, что хотите удалить этот элемент из списка сохраненных фильтров?',
    help: 'Отфильтруйте список и сохраните этот фильтр на потом'
};

ru
    .ra
    .notification
    .approvePhoto = 'Фото будет заменено после подтверждения ответственным лицом!'

ru
    .ra
    .notification
    .approvePhotoErrorResolution = 'Разрешение изображения не должно превышать 640х480 пикселей'

ru
    .ra
    .notification
    .approvePhotoErrorFormat = 'Недопустимый формат изображения'

ru
    .ra
    .notification
    .approvePhotoErrorSize = 'Размер изображения не должен превышать 100 КБ'

ru
    .ra
    .notification
    .codeError = 'Неверный код'

ru
    .ra
    .notification
    .pwdError = 'Неверный текущий пароль'

ru
    .ra
    .notification
    .pwdNewError = 'Новый пароль не соответствует парольным политикам'

ru
    .ra
    .notification
    .approved = 'Одобрено'

ru
    .ra
    .notification
    .refused = 'Отказано'

ru
    .ra
    .notification
    .rolesAdded = 'Роли добавлены'

ru
    .ra
    .notification
    .added = 'Добавлено'

ru
    .ra
    .notification
    .revoked = 'Отозвано'

ru
    .operations = {
    actions: {
        ADD: 'Добавление',
        POST: 'Создание',
        PUT: 'Изменение',
        DELETE: 'Удаление',
        LOGIN: 'Вход',
        LOGOUT: 'Выход',
        HIDE: 'Cкрыть',
        SHOW: 'Показать',
        MOVE: 'Перемещение'
    },
    entities: {
        AUTH: 'Авторизация',
        USER: 'Пользователи',
        GROUP: 'Группы',
        APP: 'Приложения',
        ROLE: 'Роли',
        APPROVAL: 'Запросы',
        OPERATION: 'Действие',
        ADMINS: 'Администрирование',
        MEMBERS: 'Доступы',
        CONFIRMEMAIL: 'Подтверждение почты',
		EVENTS: 'События',
        MYPROFILE: 'Мой профиль',
        PROFILE: 'Профиль',
        ADMINISTRATORS: 'Администраторы',
        MOVEAPP: 'Перемещение приложения'
    },
    params: {
        USER: {
            sAMAccountName: 'Логин',
            givenName: 'Имя',
            sn: 'Фамилия',
            dateOfBirth: 'Дата рождения',
            initials: 'Пол',
            resident: 'Резидент',
            residentYes: 'Да',
            residentNo: 'Нет',
            userAccountControl_true: 'Разблокировка',
            userAccountControl_false: 'Блокировка',
            mail: 'Электронная почта',
            unicodePwdReset: 'Сброс пароля',
            postalCode: 'СНИЛС',
            photo: 'Фото',
            accountExpires: 'Срок действия',
            userInformation: 'Информация о пользователе',
            userName: 'Пользователь',
            type: 'Тип',
            department: 'Отдел',
            position: 'Должность',
            employment: 'Трудоустройство',
            typeOfEmployment: 'Вид занятости',
            dateOfAdmission: 'Дата приема',
            dateOfDismissal: 'Дата увольнения',
            affilation: 'Принадлежность к ППС',
            groups: 'Группы',
            group: 'Группа',
            basicInfo: 'Основная информация',
            residentialAddress: 'Адрес проживания',
            userNotFound: 'Пользователь не найден',
            makeCounterparty: 'Сделать контрагентом',
            addCounterparty: 'Добавить контрагента',
            counterparty: 'Контрагента',
            assigningRoles: 'Назначение ролей',
            selectRoles: 'Выберите одну или несколько ролей для контрагента',
            notSpecified: 'Не указан',
            male: 'Мужской',
            maleM: 'М',
            female: 'Женский',
            femaleF: 'Ж',
            none: 'Н',
            enterName: 'Введите имя',
            enterLastName: 'Введите фамилию',
            enterLogin: 'Введите логин',
            userPhoto: 'Фото пользователя',
            fileSize: 'Файл с расширением .jpg, .jpeg, .png, .svg. Максимальный размер - 100 КБ.',
            campusNumber: 'Кампусный номер',
            enterNumber: 'Введите номер',
            enterPostalCode: 'Введите СНИЛС',
            generate: 'Сгенерировать',
            userProfile: 'Профиль пользователя',
            editProfile: 'Редактировать профиль',
        },
        EMAIL: {
            confirmEmail:'Подтвердить почту',
            addEmailAccess: 'Для получения доступа к личному кабинету добавьте к своему профилю электронную почту',
            enterEmailAddress:'Укажите адрес электронной почты',
            enterTheSentCode: 'Введите код, отправленный на указанный адрес',
            enterYourEmailAddress: 'Введите электронную почту',
            invalidFormat: 'Неверный формат',
            enterTheCode: 'Введите код',
            confirmationCode: 'На этот адрес будет отправлен код подтверждения',
            changeEmail: 'Изменить электронную почту',
            addEmail: 'Добавить электронную почту',
        },
        PHONE: {
            changePhoneNumber: 'Изменить номер телефона',
            addPhoneNumber: 'Добавить номер телефона',
            phoneNumber: 'Номер телефона',
            phone: 'Телефон',
            enterYourPhoneNumber: 'Введите номер телефона',
            enterThePhoneNumber: 'Укажите номер телефона',
        },
        PWD: {
            pwdLength: "Используйте не менее %{value} символов",
            pwdSpecialCharacters: "Используйте не менее %{value} специального символа",
            pwdForbiddenCharacters: "Пароль содержит запрещённые символы",
            pwdUppercase: "Используйте не менее %{value} буквы в верхнем регистре",
            pwdNumber: "Используйте не менее %{value} цифры",
            pwdMatches: "Новый пароль совпадает с текущим паролем",
            changePassword: "Изменить пароль",
            pwdContent: "Новый пароль должен содержать буквы латинского алфавита, не менее %{length} знаков, в том числе не менее %{number} цифры, не менее %{uppercase} буквы в верхнем регистре и не менее %{specchar} специального символа.",
            unicodePwdCur: "Текущий пароль",
            enterThePassword: "Введите пароль",
            newPassword: "Новый пароль",
            repeatTheNewPassword: "Повторите новый пароль",
            pwdNotMatch: "Пароли не совпадают",
            pwdPolicyRequirements: "Пароль не отвечает требованиям политики",
            unicodePwd: 'Пароль',
            unicodePwdReset: 'Сброс пароля',
            pwdMatch: 'Пароли не совпадают',
        },
        APP: {
            move: 'Переместить',
            paste: 'Вставить',
            name: 'Наименование',
            description: 'Oписание',
            logo: 'Логотип',
            adminGroup: 'Группа администраторов',
            url: 'Адрес',
            owner: 'Владелец',
            createdAt: 'Дата создания',
            info: 'Информация',
            appAddress: 'Адрес приложения',
            settings: 'Настройки',
            dateFormat: 'дд.мм.гг',
            rangeDateFormat: 'дд.мм.гггг - дд.мм.гггг',
            symbols: 'символов',
            title:'Название',
            addApplication: 'Добавить приложение',
            cancelTheMove: 'Отменить перемещение',
            application: 'Приложение',
            imageSize:'Размер изображения не должен превышать 1 МБ',
            appAddressLimit: 'Адрес приложения не может превышать 255 символов',
            invalidLinkFormat: 'Неверный формат ссылки',
            applicationParameters: 'Параметры приложения',
            applicationName: 'Название приложения ',
            enterName: 'Введите название',
            appDescription: 'Описание приложения',
            enterText: 'Введите текст',
            enterAddress: 'Введите адрес',
            appCover: 'Обложка приложения',
            fileExtension: 'Файл с расширением .jpg, .jpeg, .png, .svg. Максимальный размер - 1 МБ.',
            appAddressProtocol: 'Адрес приложения в формате «протокол://доменное имя:порт»',
            connectingGroupOfAdministrators: 'Подключение группы администраторов',
            membersWillManageTheApplication: 'Укажите группу безопасности Active Directory, участники которой будут управлять приложением.',
            connectingUserGroup: 'Подключение группы пользователей',
            membersWillUseTheRole: 'Укажите группу безопасности Active Directory, участники которой будут использовать роль.',
            applicationProfile: 'Профиль приложения',
            applicationNotFound: 'Приложение не найдено',
            displayingColumns: 'Отображение столбцов',
            cantMoveAppIntoItself: 'Нельзя переместить приложение в перемещаемое приложение и в Роли перемещаемого приложения.',
            appNotFound: 'Приложение не найдено',
            moveTheApplication: 'Переместить приложение',
            confirmMovingApp: 'Вы действительно хотите переместить приложение %{app} в %{role}?',
            movingAppInfo: 'После перемещения в настройках роли необходимо подключить группу пользователей, участники которой будут использовать роль.',
            active: 'Публичность',
            appPublicity: 'Публичность приложения',
            displayingPublicity: 'Приложение отображается в общем списке приложений',
        },
        MONTH: {
            january: 'Январь',
            february: 'Февраль',
            march: 'Март',
            april :'Апрель',
            may: 'Май',
            june: 'Июнь',
            july: 'Июль',
            august: 'Август',
            september: 'Сентябрь',
            october: 'Октябрь',
            november: 'Ноябрь',
            december: 'Декабрь',
        },
        DAYS: {
            sunday: 'Вс',
            monday: 'Пн',
            tuesday: 'Вт',
            wednesday: 'Ср',
            thursday: 'Чт',
            friday: 'Пт',
            saturday: 'Сб',
            ok: 'Применить',
            today: 'Сегодня',
            yesterday: 'Завтра',
            last7Days: 'Последние 7 дней',
            day: 'День',
            year: 'Год',
        },
        ROLE: {
            name: 'Наименование',
            description: 'Oписание',
            adminGroup: 'Группа администраторов',
            userGroup: 'Группа пользователей',
            owner: 'Владелец',
            roleName: 'Название роли',
            roleParameters: 'Параметры роли',
            nameLimit: 'Название не может превышать 50 символов',
            roleDescription: 'Описание роли',
            roleProfile: 'Профиль роли',
            assignRole: 'Назначить роль',
        },
        APPROVAL: {
            approvalPhoto: 'Смена фото',
            photo: 'Смена фото',
            access: 'Запрос доступа',
            comment: 'Комментарий',
            status: 'Статус',
            status_approved: 'Одобрено',
            status_canceled: 'Отказано',
            approveAccess: 'Запросить доступ',
            approvalSend: 'Запрос отправлен!',
            inputComment: 'Укажите комментарий к запросу',
            approvalStatus: 'Статус запроса доступен в разделе «Запросы».',
            info: 'Информация о запросе',
            aboutTheRequest: 'О запросе',
            changePhoto: 'Запрос на изменение фото',
            forAccess: 'Запрос на доступ',
            role: 'Запрашиваемая роль',
            createdAt: 'Дата создания запроса',
            statusReq: 'Статус запроса',
            pending: 'На рассмотрении',
            approved: 'Одобрено',
            canceled: 'Отказано',
            author: 'Автор',
            reqAuthor: 'Автор запроса',
            authorComment: 'Комментарий автора',
            prevPhoto: 'Прежнее фото профиля',
            newPhoto: 'Новое фото профиля',
            executor: 'Исполнитель',
            reason: 'Причина',
            updatedAt : 'Дата изменения',
            updatedAtReq : 'Дата изменения запроса',
            type : 'Тип',
            notFound: 'Не найден',
            whenCreated: 'Создан',
            requestObject:'Объект запроса',
            requestToChangeMail: 'Запрос на изменение почты',
        },
        POPOVER: {
            editSettings: 'Редактировать настройки',
            resetPassword: 'Сбросить пароль',
        },
        STATUS: {
            any: 'Любой',
            success: 'Успешно',
            fail: 'Неуспешно',
            active: 'Активный',
            disabled: 'Заблокированный'
        }
    },
};

ru
    .approvals = {
    TABS: {
        toApproval: 'Требуют согласования',
        myApprovals: 'Мои запросы',
        allApprovals: 'Все запросы'
    },
    FILTERS: {
        pending: 'На рассмотрении',
        approved: 'Одобрено',
        canceled: 'Отказано',
        photo: 'Изменение фото',
        access: 'Доступ'
    }
}

ru
    .organisation = {
    language: 'Ru',
    name: '© 2022, Цифровые технологии',
    lkVersion: 'Версия ЛК',
    apiVersion: 'Версия API',
}

ru
    .ra
    .action
    .choose = 'Выбрать'

ru
    .ra
    .action
    .search = 'Поиск'

ru
    .ra
    .action
    .revoke = 'Отозвать'

ru
    .ra
    .action
    .create = 'Cоздать'

ru
    .ra
    .action
    .change = 'Изменить'

ru
    .ra
    .action
    .add = 'Добавить'

ru
    .ra
    .action
    .edit = 'Редактировать'

ru
    .ra
    .action
    .confirm = 'Подтвердить'

ru
    .ra
    .action
    .cancel = 'Отмена'

ru
    .ra
    .action
    .delete = 'Удалить'

ru
    .ra
    .action
    .download = 'Загрузить'

ru
    .ra
    .action
    .block = 'Заблокировать'

ru
    .ra
    .action
    .save = 'Cохранить'

ru
    .ra
    .action
    .abort = 'Oтменить'

ru
    .ra
    .action
    .unblock = 'Разблокировать'

ru
    .ra
    .action
    .comeback = 'Назад'

ru
    .ra
    .action
    .approve = 'Одобрить'

ru
    .ra
    .action
    .deny = 'Отказать'

ru
    .ra
    .action
    .skip = 'Пропустить'

ru
	.ra
	.action
	.display = 'Отображение';

ru
	.ra
	.action
	.exit = 'Выйти';

ru
	.ra
	.action
	.continue = 'Продолжить';

ru
	.ra
	.action
  .actions = 'Действия';


ru
    .ra
    .action
    .abort = 'Oтменить'


ru
  .ra
  .action
  .screenshot = 'Сделать снимок'

ru
  .ra
  .action
  .clearScreenshot = 'Очистить'

ru
    .messages = {
    expirationDate: 'Срок действия полномочий',
    expirationDateTo: 'Полномочия действуют до',
    limitExpiration: 'Ограничить срок действия полномочий',
    notLimitExpiration: 'Не ограничивать срок действия полномочий',
    addUser: 'Добавить пользователя',
    addAdmin: 'Добавить администратора',
    addRole: 'Добавить роль',
    revokeAccess: 'Отозвать доступ',
    revokeAccessTo: 'Отозвать доступ у %{user} к %{name}?',
    revokeAccessManyTo: 'Отозвать доступ у %{user} к нескольким ролям?',
    revokeAccessMessage: 'При отзыве доступа %{users} доступ к',
    revokeAccessRoleMessage: 'При отзыве доступа к %{role} пользователь теряет доступ к',
    removeAppTitle: 'Удалить приложение',
    removeManyAppTitle: 'Удалить приложения',
    removeApp: 'Вы действительно хотите удалить приложение %{name}?',
    removeApp_: 'Вы действительно хотите удалить приложение?',
    removeManyApps_: 'Вы действительно хотите удалить приложения?',
    removeManyApps: 'Вы действительно хотите удалить несколько приложений (%{count})',
    activeDirectoryGroupsRemain: 'Группы Active Directory и участники групп остаются.',
    cantRemoveApp: 'Вы не можете удалить приложение %{name}, пока его роли назначены пользователям.',
    cantRemoveManyApps: 'Вы не можете удалить приложение, пока его роли назначены пользователям.',
    applicationRolesAreAssignedToUsers: 'Роли приложения назначены пользователям.',
    applicationRolesAreAssignedToUsers_: 'Роли приложения %{name} назначены пользователям.',
    applicationManyRolesAreAssignedToUsers: 'Роли приложений назначены пользователям.',
    removeRoleTitle: 'Удалить роль',
    removeManyRoleTitle: 'Удалить роли',
    removeRole: 'Вы действительно хотите удалить роль?',
    removeRole_: 'Вы действительно хотите удалить роль',
    removeManyRoles_: 'Вы действительно хотите удалить роли?',
    removeManyRoles: 'Вы действительно хотите удалить несколько ролей (%{count})',
    cantRemoveRole: 'Вы не можете удалить роль %{name}, пока она назначена пользователям.',
    theRoleIsAssignedToUsers: 'Роль назначена пользователям.',
    theRoleIsAssignedToUsers_: 'Роль %{name} назначена пользователям.',
    theRoleIsAssignedToManyUsers: 'Роли назначены пользователям.',
    cantRemoveManyRoles: 'Вы не можете удалить роль, пока она назначена пользователям.',
    thenGiveAccessMessage: 'При предоставлении доступа к',
    userWillTakeAccessMessage: ' %{role} пользователь (%{countUser}) получит доступ к',
    fromUser: 'пользователя',
    fromUsers: 'пользователей',
    giveAccess: 'Предоставить доступ',
    accessGranted: 'доступ предоставлен',
    accessRequested: 'доступ запрошен',
    inputRevokeReason: 'Укажите причину отзыва ролей',
    chooseGroup: 'Выбрать группу',
    inputReason: 'Укажите причину',
    inputRefusalReason: 'Укажите причину отказа',
    commentText: 'Текст комментария',
    administration: 'администрированию',
    appAdministration: 'Администрированию приложения',
    roleAdministration: 'Администрированию роли',
    usersWillReceive: 'пользователи получат',
    userWillReceive: 'пользователь получит',
    accessTo: 'доступ к',
    assigningRole: 'При назначении роли',
    assigningAccessTo: 'При назначении доступа к',
    selectedUsersWillHaveAccessTo: 'выбранные пользователи получат доступ к',
    userWillHaveAccessTo: 'пользователь получит доступ к',
    willHaveAccessTo: 'получит доступ к',
    blockUser: 'Заблокировать пользователя',
    reqBlockUser: 'Вы действительно хотите заблокировать',
    multipleUsers: 'несколько пользователей',
    accessDeny: 'Отказать в доступе',
    willLost: 'потеряет',
    willLose: 'потеряют',
    deleteUsers: 'Удалить пользователей',
    removeManyUsers: 'Вы действительно хотите удалить несколько пользователей',
    notFound: 'ничего не найдено',
    alreadyConnectedTo: 'уже подключена к',
    limitAccountExpiration: 'Ограничить срок действия учетной записи',
    notLimitAccountExpiration: 'Не ограничить срок действия учетной записи',
    unblockUser: 'Разблокировать пользователя',
    unblock: 'Вы действительно хотите разблокировать',
    accountIsValidUntil: 'Учетная запись действует до',
    remove: 'Вы действительно хотите удалить',
    deleteUser: 'Удалить пользователя',
    emptyList: 'Список пуст',
    accountExpires: 'Срок действия записи',
    still: 'еще',
    uploadPhoto: 'Загрузить фото',
    devicesNotFound: 'Устройства не найдены',
    fromFileSystem: 'С компьютера',
    fromWebCam: 'С веб-камеры',
    error: 'Ошибка',
};

const customRuMessages: any = {
    ...ru,
    resources: {
        user: {
            name: 'Пользователь |||| Пользователи',
            fields: {
                givenName: 'Имя',
                whenCreated: 'Создан',
                description: 'Описание',
                userAccountControl: 'Статус',
                memberOfTeacherGroup: 'Принадлежность к ППС',
                memberOfGroup: 'Группы AD',
                roles: 'Роли',
                role: 'Тип пользователя',
                accountExpires: 'Срок действия записи',
                limited: 'Ограничен',
                unlimited: 'Не ограничен',
                type: 'Тип',

            },
        },
        operation: {
            name: 'Событие |||| События',
            fields: {
                type: 'Операция',
                entry: 'Сущность',
                identity: 'Идентификатор',
                params: 'Параметр',
                owner: 'Автор',
                createdAt: 'Дата создания',
                success: 'Статус',
                comment: 'Причина',
            }
        },
        app: {
            name: 'Приложение |||| Приложения'
        },
        approval: {
            name: 'записей',
            fields: {
                createdAt: 'Дата создания',
                updatedAt: 'Дата изменения',
            }
        },
        role: {
            name: 'записей'
        },
        adminApp: {
            name: 'записей'
        },
        reports: {
            name: 'Отчеты',
            types: {
                1: 'Права доступа',
                2: 'Изменения прав',
                3: 'События'
            }
        }

    }
};

export default customRuMessages;
