import React, { Fragment, ReactElement } from "react";
import {
  List,
  Datagrid,
  SearchInput,
  FilterForm,
  RowClickFunction,
  TextField,
  FunctionField,
  useDataProvider, ReferenceField, useGetList, useGetIdentity, useTranslate,
} from "react-admin";
import { Stack } from "@mui/material";
import CustomBulkDeleteButton from "../../buttons/customBulkDeleteButton/CustomBulkDeleteButton";
import CustomColumns from "../../buttons/customColumns/CustomColumns";
import RowActionsButton from "../../buttons/rowActionsButton/RowActionsButton";
import { IApp, IProfile, IRole } from "../../../utils/types";
import UserRolesMenu from "../../popovers/userRolesMenu/UserRolesMenu";
import RemoveBulkRoleAccessModal from "../../modals/removeBulkRoleAccessModal/RemoveBulkRoleAccessModal";
import styles from "./UserRolesPage.module.css";
import CustomModal from "../../modals/customModal/CustomModal";
import AppIcon from "../../../assets/appIcon.png";
import AddRoleModal from "../../modals/addRoleModal/AddRoleModal";
import { dateFormat } from "../../../utils/utils";

type UserRolesPageProps = {
  roles: any[],
  user: IProfile,
}


const UserRolesPage = (props: UserRolesPageProps) => {
  const { roles, user } = props;
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const [roleColumns, setRoleColumns] = React.useState([
    { source: 'name', label: translate('operations.params.APP.title'), active: true },
    { source: 'appId', label:  translate('operations.params.APP.application'), active: true },
    { source: 'description', label: translate('resources.user.fields.description'), active: true },
    { source: 'expiresAt', label: translate('operations.params.USER.accountExpires'), active: true },
  ]);
  const [showAddRoleModal, setShowAddRoleModal] = React.useState(false);
  const { identity } = useGetIdentity();
  const { data } = useGetList('role', { filter: { id: roles.map((item: any) => item.id) } });

  const onCloseApprovalModal = () => {
  }

  const onShowAddRoleModal = () => {
    setShowAddRoleModal(true);
  }

  const onCloseAddRoleModal = () => {
    setShowAddRoleModal(false);
  }

  const PostBulkActionButtons: ReactElement<any, string | React.JSXElementConstructor<any>> = (
    <Fragment>
      <CustomBulkDeleteButton Modal={RemoveBulkRoleAccessModal} text={translate('ra.action.revoke')} modalProps={{ user: user }} />
    </Fragment>
  );

  const roleFilters = [
    <SearchInput name={'q'} source={'id'} alwaysOn />
  ]

  const CustomListToolBar = () => (
    <Stack direction="row" justifyContent="space-between" sx={{ width: "100%" }}>
      <FilterForm filters={roleFilters} />
    </Stack>
  )

  const onChangeRolesColumns = (id: string) => {
    setRoleColumns(oldState => oldState.map(item => {
      if (item.source === id) item.active = !item.active;
      return item;
    }));
  }

  const onRowClick: RowClickFunction = (id) => {
    return dataProvider.getOne('role', { id })
      .then(({ data }) => {
        return `/app/${data.appId}/show/role/${id}`
      });
  }

  const EmptyState = () => (
    <div className={styles.empty}>
      <span>{translate('messages.emptyList')}</span>
      <button onClick={onShowAddRoleModal}>{translate('operations.params.ROLE.assignRole')}</button>
    </div>
  )

  return (
    <div>
      <List
        actions={<CustomListToolBar />}
        disableSyncWithLocation
        resource={'role'}
        sort={{ field: 'appId', order: 'ASC' }}
        filter={{ id: roles.map((item: any) => item.id) }}
        exporter={false}
        empty={<EmptyState />}
        sx={{
					minWidth: "-webkit-fill-available",
					width: "-moz-available",
					overflow: "auto"
				}}
      >
        <Datagrid
          rowClick={onRowClick}
          isRowExpandable={row => data && (data.find((item: IRole) => item.parentId === row.id))}
          bulkActionButtons={identity?.role === 1 ? PostBulkActionButtons : false}
          sx={{
            '& .RaDatagrid-headerCell': {
              borderColor: "#e0e0e0",
              backgroundColor: "#fff0",
            },
            '& .RaDatagrid-rowCell': {
              maxWidth: 100,
              boxSizing: "content-box",
              '&.column-undefined': {
                width: 40,
                '& span': {
                  display: "flex",
                  alignItems: "center"
                },
              },
            },
            '& .RaDatagrid-expandedPanel': {
              '& .MuiTableCell-root': {
                paddingRight: 0,
                '& .MuiTableCell-root': {
                  borderBottom: "none",
                  '&:last-child': {
                    '&.column-undefined': {
                      paddingRight: '16px',
                    }
                  }
                }
              }
            }
          }}
        >
          {
            roleColumns.filter(item => item.active).map(item => {
              switch (item.source) {
                case 'appId':
                  return <ReferenceField label={translate('operations.params.APP.application')} reference={'app'} source={'appId'} link={(record: any) => `/app/${record.appId}/show`} emptyText={translate('operations.params.APP.applicationNotFound')} key={item.source}>
                    <FunctionField render={(record: IApp) => <div className={styles.user_field}><img className={styles.icon} src={record.logo && record.logo.length ? record.logo : AppIcon} alt="" /><span>{record.name}</span></div>} />
                  </ReferenceField>;
                case 'expiresAt':
                  return <FunctionField render={(record: any) => dateFormat(roles.filter(item => item.id === record.id)[0]?.expiresAt, { showTime: true }) || ''} source={item.source} label={item.label} key={item.source} />
                default: return <TextField source={item.source} label={item.label} key={item.source} />
              }
            }
            )
          }
          <FunctionField
            label={<CustomColumns columns={roleColumns} onChange={onChangeRolesColumns} />}
            render={(record: IRole) => identity?.role === 1 ? <RowActionsButton menuProps={{ record: record, user: user }} Menu={UserRolesMenu} /> : null}
            sx={{ maxWidth: 40 }}
          />
        </Datagrid>
      </List>
      <CustomModal
        open={showAddRoleModal}
        close={onCloseApprovalModal}
      >
        <AddRoleModal
          close={onCloseAddRoleModal}
          roles={roles}
          user={user}
        />
      </CustomModal>
    </div>
  )
}

export default UserRolesPage;