import React from "react";
import styles from './DotsMenuButton.module.css';
import dotsIcon from '../../../assets/more.png';
import CustomPopover from "../../popovers/customPopover/CustomPopover";
import ChangePhoneMenu from "../../popovers/changePhoneMenu/ChangePhoneMenu";
import {dispatchOpenPopoverEvent} from "../../../utils/utils";

type DotsMenuButtonProps = {
  onChange: () => void,
}

const DotsMenuButton = (props: DotsMenuButtonProps) => {
  const {onChange} = props;
  const [anchorElCollapseIcon, setAnchorElCollapseIcon] = React.useState(null);
  const collapseIconRef = React.useRef(null);
  const open = Boolean(anchorElCollapseIcon);

  const handleClose = (e: any) => {
    e.stopPropagation();
    setAnchorElCollapseIcon(null);
  };

  const showPopover = (e: any) => {
    e.stopPropagation();
    if (!open) {
      dispatchOpenPopoverEvent();
      setAnchorElCollapseIcon(collapseIconRef.current);
    } else setAnchorElCollapseIcon(null);
  };

  const closePopover = () => {
    setAnchorElCollapseIcon(null);
  }

  return (
    <div className={styles.root} onClick={showPopover} ref={collapseIconRef}>
      <img src={dotsIcon} alt={''}/>
      <CustomPopover
        open={open}
        anchorEl={anchorElCollapseIcon}
        handleClose={handleClose}
        ChildComponent={() => <ChangePhoneMenu closePopover={closePopover} onChange={onChange}/>}
      />
    </div>
  )
}

export default DotsMenuButton;