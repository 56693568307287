import * as React from "react";
import { Card, CardContent } from '@mui/material';
import { useDataProvider, useTranslate } from 'react-admin';
import styles from './EmploymentCard.module.css';
import { IProfile } from "../../../utils/types";

type EmploymentCardProps = {
  profile: IProfile
}

const EmploymentCard = (props: EmploymentCardProps) => {
  const { profile } = props;
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const [teachGroupName, setTeachGroupName] = React.useState('');

  React.useEffect(() => {
    dataProvider.getOne('settings', { id: 'role' })
      .then(({ data }) => {
        setTeachGroupName(data.settings['LDAP_TEACHER_GROUP']);
      })
  },[dataProvider])

  return (
    <Card>
      <CardContent>
        <div className={styles.root}>
          <h1 className={styles.header}>{translate('operations.params.USER.employment')}</h1>
          <div className={styles.item}>
            <span className={styles.item_name}>{translate('operations.params.USER.typeOfEmployment')}</span>
            <span className={styles.item_value}>{profile.company}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.item_name}>{translate('operations.params.USER.department')}</span>
            <span className={styles.item_value}>{profile.department}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.item_name}>{translate('operations.params.USER.position')}</span>
            <span className={styles.item_value}>{profile.title}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.item_name}>{translate('operations.params.USER.dateOfAdmission')}</span>
            <span className={styles.item_value}>{profile.whenCreated}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.item_name}>{translate('operations.params.USER.dateOfDismissal')}</span>
            <span className={styles.item_value}>{profile.accountExpires}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.item_name}>{translate('operations.params.USER.affilation')}</span>
            <span className={styles.item_value}>{profile?.memberOf?.includes(teachGroupName) ? translate('operations.params.USER.residentYes') : translate('operations.params.USER.residentNo')}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.item_name}>{translate('operations.params.USER.groups')}</span>
            <span className={styles.item_value}>{Array.isArray(profile.memberOf) ? profile.memberOf.join(", ") : profile.memberOf}</span>
          </div>
        </div>
      </CardContent>
    </Card>
  )
};

export default EmploymentCard;