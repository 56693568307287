import React from "react";
import {TextField} from "@mui/material";
import './CustomInput.css';

type CustomInputProps = {
  placeholder: string,
  mode: string,
  value: string,
  changeInput: (v: string) => void,
  errorMessage: string,
  focus: boolean,
}

const CustomInput = (props: CustomInputProps) => {
  const { placeholder, mode, value, changeInput, errorMessage, focus } = props;
  const changeValue = (e:any) => {
    changeInput(e.target.value);
  };
  const styledClassName:string = {
    'root': 'custom_input',
    'error': 'custom_input error_state',
    'success': 'custom_input success_state',
  }[mode] || 'custom_input';
  return (
    <TextField
      className={styledClassName}
      variant="outlined"
      autoComplete="off"
      value={value ? value : ''}
      placeholder={placeholder}
      onChange={changeValue}
      helperText={mode === 'error' ? errorMessage : ''}
      autoFocus={focus}
    />
  );
}

export default CustomInput;