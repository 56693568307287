import React from "react";
import { useNotify, useUpdate, useDelete, useGetIdentity, useRedirect, useTranslate } from "react-admin";
import styles from './ActionsProfileMenu.module.css';
import CustomPopoverMenuItem from "../customPopoverMenuItem/CustomPopoverMenuItem";
import CustomModal from "../../modals/customModal/CustomModal";
import BlockUserModal from "../../modals/blockUserModal/BlockUserModal";
import RemoveUserModal from "../../modals/removeUserModal/RemoveUserModal";
import UnlockUserModal from "../../modals/unlockUserModal/UnlockUserModal";
import AddRoleModal from "../../modals/addRoleModal/AddRoleModal";

type ActionsProfileMenuProps = {
	closePopover: () => void
	record?: any,
	deletable: boolean
}

type changeActiveUserProps = {
	limit: boolean,
	date: Date | null,
	comment: string
}

const ActionsProfileMenu = (props: ActionsProfileMenuProps) => {
	const { closePopover, record: profile, deletable } = props;
	const { identity: user } = useGetIdentity();
	const redirect = useRedirect();
	const [showBlockUserModal, setShowBlockUserModal] = React.useState(false);
	const [showRemoveUserModal, setShowRemoveUserModal] = React.useState(false);
	const [showUnlockUserModal, setShowUnlockUserModal] = React.useState(false);
	const [showAddRoleModal, setShowAddRoleModal] = React.useState(false);
	const active: boolean = profile?.userAccountControl;
	const translate = useTranslate();
	const onClickWrapper = (e: any) => {
		e.stopPropagation();
	}

	const notify = useNotify();

	const [updateActiveUser] = useUpdate();

	const [updatePassword] = useUpdate(
		'user',
		{ id: profile.id, data: { unicodePwd: '' } },
		{
			mutationMode: 'undoable',
			onSuccess: () => {
				// closePopover()
				notify('ra.notification.updated', { type: 'success', messageArgs: { smart_count: 1 }, undoable: true });
			},
			onError: () => notify('ra.notification.canceled', { type: 'warning' })
		}
	);

	const [deleteUser] = useDelete(
		'user', { id: profile.id, previousData: profile }
	)

	const resetPassword = () => {
		updatePassword()
	}

	const changeActiveUser = (v: changeActiveUserProps) => {
		let accountExpires: string = '';
		if (v.limit) {
			const date = new Date(String(v.date));
			date.setHours(Math.abs(new Date().getTimezoneOffset() / 60));
			date.setMinutes(0);
			accountExpires = date.toISOString();
		}
		updateActiveUser('user',
			{ id: profile.id, data: { userAccountControl: !active, accountExpires: v.limit ? accountExpires : undefined, comment: v.comment } },
			{
				mutationMode: 'undoable',
				onSuccess: () => {
					closePopover()
					notify('ra.notification.updated', { type: 'success', messageArgs: { smart_count: 1 }, undoable: true });
				},
				onError: () => notify('ra.notification.canceled', { type: 'warning' })
			})
	}

	const onBlockUser = (v: string) => {
		updateActiveUser('user',
			{ id: profile.id, data: { userAccountControl: !active, comment: v } },
			{
				mutationMode: 'undoable',
				onSuccess: () => {
					closePopover()
					notify('ra.notification.updated', { type: 'success', messageArgs: { smart_count: 1 }, undoable: true });
				},
				onError: () => notify('ra.notification.canceled', { type: 'warning' })
			})
	}

	const removeUser = () => {
		deleteUser();
		closePopover();
	}

	const createContragent = () => {
		redirect(`/create_contragent?user=${profile.id}`);
	}

	const onShowBlockUserModal = () => {
		setShowBlockUserModal(true);
	}

	const onCloseBlockUserModal = () => {
		setShowBlockUserModal(false);
		closePopover();
	}

	const onShowAddRoleModal = () => {
		setShowAddRoleModal(true);
	}

	const onCloseAddRoleModal = () => {
		setShowAddRoleModal(false);
		closePopover();
	}

	const onShowRemoveUserModal = () => {
		setShowRemoveUserModal(true);
	}

	const onCloseRemoveUserModal = () => {
		setShowRemoveUserModal(false);
		closePopover();
	}

	const onShowUnlockUserModal = () => {
		setShowUnlockUserModal(true);
	}

	const onCloseUnlockUserModal = () => {
		setShowUnlockUserModal(false);
		closePopover();
	}

	return (
		<div className={styles.root} onClick={onClickWrapper}>
			<div className={styles.middle}>
				{
					user?.role === 1 || profile.editable ? <CustomPopoverMenuItem text={translate('operations.params.ROLE.assignRole')} onClick={onShowAddRoleModal} /> : null
				}
				{
					user?.role === 1 && profile?.mail && profile?.mail.length ? <CustomPopoverMenuItem text={translate('operations.params.POPOVER.resetPassword')} onClick={resetPassword} /> : null
				}
				{
					user?.role === 1 ? <CustomPopoverMenuItem text={active ? translate('ra.action.block') : translate('ra.action.unblock')}
						onClick={active ? onShowBlockUserModal : onShowUnlockUserModal} /> : null
				}
				{
					user?.role === 1 && !active && profile?.role !== 4 && profile?.role !== 1 ? <CustomPopoverMenuItem text={translate('operations.params.USER.makeCounterparty')} onClick={createContragent} /> : null
				}
			</div>
			{
				profile.role && profile.role === 4 && deletable ?
					<div className={styles.bottom}>
						<CustomPopoverMenuItem text={translate('ra.action.delete')} onClick={onShowRemoveUserModal} />
					</div> : null
			}
			<CustomModal
				open={showBlockUserModal}
				close={onCloseBlockUserModal}
			>
				<BlockUserModal
					closeModal={onCloseBlockUserModal}
					onBlockUser={onBlockUser}
					names={profile.givenName && profile.givenName.length ? profile.givenName : profile.id}
				/>
			</CustomModal>
			<CustomModal
				open={showRemoveUserModal}
				close={onCloseRemoveUserModal}
			>
				<RemoveUserModal
					closeModal={onCloseRemoveUserModal}
					onRemoveUser={removeUser}
					names={profile.givenName && profile.givenName.length ? profile.givenName : profile.id}
				/>
			</CustomModal>
			<CustomModal
				open={showUnlockUserModal}
				close={onCloseUnlockUserModal}
			>
				<UnlockUserModal
					closeModal={onCloseUnlockUserModal}
					onUnlockUser={changeActiveUser}
					names={profile.givenName && profile.givenName.length ? profile.givenName : profile.id}
				/>
			</CustomModal>
			<CustomModal
				open={showAddRoleModal}
				close={onCloseAddRoleModal}
			>
				<AddRoleModal close={onCloseAddRoleModal} roles={profile.roles || []} user={profile} />
			</CustomModal>
		</div>
	)
}

export default ActionsProfileMenu;