import React from 'react';
import './CustomDateRangePicker.css';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { useTranslate } from 'react-admin';

type CustomDateRangePickerProps = {
  startDate: Date | null,
  endDate: Date | null,
  onChangeDate: (d1: Date, d2: Date) => void,
}

const locale = {
  sunday: 'Вс',
  monday: 'Пн',
  tuesday: 'Вт',
  wednesday: 'Ср',
  thursday: 'Чт',
  friday: 'Пт',
  saturday: 'Сб',
  ok: 'Применить',
  today: 'Сегодня',
  yesterday: 'Завтра',
  last7Days: 'Последние 7 дней',
};

const pickerStyle = {
  width: 225,
  height: 30,
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '1rem',
  lineHeight: '1.4375rem',
  color: '#000',
};

const CustomDateRangePicker = (props: CustomDateRangePickerProps) => {
  const { startDate, endDate, onChangeDate } = props;
//@ts-ignore
  const changeDates = ([start, end]) => {
    onChangeDate(start, end);
  };
	const translate = useTranslate();

  // const locale = {
  //   sunday: translate('operations.params.DAYS.sunday'),
  //   monday: translate('operations.params.DAYS.monday'),
  //   tuesday: translate('operations.params.DAYS.tuesday'),
  //   wednesday: translate('operations.params.DAYS.wednesday'),
  //   thursday: translate('operations.params.DAYS.thursday'),
  //   friday: translate('operations.params.DAYS.friday'),
  //   saturday: translate('operations.params.DAYS.saturday'),
  //   ok: translate('operations.params.DAYS.ok'),
  //   today: translate('operations.params.DAYS.today'),
  //   yesterday: translate('operations.params.DAYS.yesterday'),
  //   last7Days: translate('operations.params.DAYS.last7Days'),
  // };

  const translateMonth = (date: Date) => {
    const months = [
      translate('operations.params.MONTH.january'),
      translate('operations.params.MONTH.february'),
      translate('operations.params.MONTH.march'),
      translate('operations.params.MONTH.april'),
      translate('operations.params.MONTH.may'),
      translate('operations.params.MONTH.june'),
      translate('operations.params.MONTH.july'),
      translate('operations.params.MONTH.august'),
      translate('operations.params.MONTH.september'),
      translate('operations.params.MONTH.october'),
      translate('operations.params.MONTH.november'),
      translate('operations.params.MONTH.december'),
    ];
    return `${months[date.getMonth()]} ${date.getFullYear()}`;
  }

  // const onChangeStartDateValue = (e: any) => {
  //   const value = e.target.value.replace(/\./g, '');
  //   if (value.length < 9 && /\d+$/.test(value)) {
  //     if (value.length && value.length < 3) {
  //       setStartDateValue(Number(value) > 31 ? '31' : value);
  //     } else if (value.length && value.length === 3) {
  //       setStartDateValue(
  //         `${
  //           Number(value.slice(0, 2)) > 31 ? '31' : value.slice(0, 2)
  //         }.${value.slice(2, 3)}`
  //       );
  //     } else if (value.length && value.length === 4) {
  //       setStartDateValue(
  //         `${
  //           Number(value.slice(0, 2)) > 31 &&
  //           [1, 3, 5, 7, 8, 10, 12].includes(Number(value.slice(2, 4)))
  //             ? '31'
  //             : Number(value.slice(0, 2)) > 30 &&
  //             [4, 6, 9, 11].includes(Number(value.slice(2, 4)))
  //               ? '30'
  //               : Number(value.slice(0, 2)) > 28 &&
  //               [2].includes(Number(value.slice(2, 4)))
  //                 ? '28'
  //                 : value.slice(0, 2)
  //         }.${Number(value.slice(2, 4)) > 12 ? '12' : value.slice(2, 4)}`
  //       );
  //     } else if (value.length && value.length < 9) {
  //       setStartDateValue(
  //         `${
  //           Number(value.slice(0, 2)) > 31 &&
  //           [1, 3, 5, 7, 8, 10, 12].includes(Number(value.slice(2, 4)))
  //             ? '31'
  //             : Number(value.slice(0, 2)) > 30 &&
  //             [4, 6, 9, 11].includes(Number(value.slice(2, 4)))
  //               ? '30'
  //               : Number(value.slice(0, 2)) > 28 &&
  //               [2].includes(Number(value.slice(2, 4)))
  //                 ? '28'
  //                 : value.slice(0, 2)
  //         }.${
  //           Number(value.slice(2, 4)) > 12 ? '12' : value.slice(2, 4)
  //         }.${value.slice(4, 9)}`
  //       );
  //     }
  //     if (value.length && value.length === 8) {
  //       const val = `${
  //         Number(value.slice(2, 4)) > 12 ? '12' : value.slice(2, 4)
  //       }.${
  //         Number(value.slice(0, 2)) > 31 &&
  //         [1, 3, 5, 7, 8, 10, 12].includes(Number(value.slice(2, 4)))
  //           ? '31'
  //           : Number(value.slice(0, 2)) > 30 &&
  //           [4, 6, 9, 11].includes(Number(value.slice(2, 4)))
  //             ? '30'
  //             : Number(value.slice(0, 2)) > 28 &&
  //             [2].includes(Number(value.slice(2, 4)))
  //               ? '28'
  //               : value.slice(0, 2)
  //       }.${value.slice(4, 9)}`;
  //       onChangeDate(new Date(val), endDate);
  //     }
  //   } else if (!value.length) {
  //     setStartDateValue('');
  //   }
  // };
  //
  // const onChangeEndDateValue = (e: any) => {
  //   const value = e.target.value.replace(/\./g, '');
  //   if (value.length < 9 && /\d+$/.test(value)) {
  //     if (value.length && value.length < 3) {
  //       setEndDateValue(Number(value) > 31 ? '31' : value);
  //     } else if (value.length && value.length === 3) {
  //       setEndDateValue(
  //         `${
  //           Number(value.slice(0, 2)) > 31 ? '31' : value.slice(0, 2)
  //         }.${value.slice(2, 3)}`
  //       );
  //     } else if (value.length && value.length === 4) {
  //       setEndDateValue(
  //         `${
  //           Number(value.slice(0, 2)) > 31 &&
  //           [1, 3, 5, 7, 8, 10, 12].includes(Number(value.slice(2, 4)))
  //             ? '31'
  //             : Number(value.slice(0, 2)) > 30 &&
  //             [4, 6, 9, 11].includes(Number(value.slice(2, 4)))
  //               ? '30'
  //               : Number(value.slice(0, 2)) > 28 &&
  //               [2].includes(Number(value.slice(2, 4)))
  //                 ? '28'
  //                 : value.slice(0, 2)
  //         }.${Number(value.slice(2, 4)) > 12 ? '12' : value.slice(2, 4)}`
  //       );
  //     } else if (value.length && value.length < 9) {
  //       setEndDateValue(
  //         `${
  //           Number(value.slice(0, 2)) > 31 &&
  //           [1, 3, 5, 7, 8, 10, 12].includes(Number(value.slice(2, 4)))
  //             ? '31'
  //             : Number(value.slice(0, 2)) > 30 &&
  //             [4, 6, 9, 11].includes(Number(value.slice(2, 4)))
  //               ? '30'
  //               : Number(value.slice(0, 2)) > 28 &&
  //               [2].includes(Number(value.slice(2, 4)))
  //                 ? '28'
  //                 : value.slice(0, 2)
  //         }.${
  //           Number(value.slice(2, 4)) > 12 ? '12' : value.slice(2, 4)
  //         }.${value.slice(4, 9)}`
  //       );
  //     }
  //     if (value.length && value.length === 8) {
  //       const val = `${
  //         Number(value.slice(2, 4)) > 12 ? '12' : value.slice(2, 4)
  //       }.${
  //         Number(value.slice(0, 2)) > 31 &&
  //         [1, 3, 5, 7, 8, 10, 12].includes(Number(value.slice(2, 4)))
  //           ? '31'
  //           : Number(value.slice(0, 2)) > 30 &&
  //           [4, 6, 9, 11].includes(Number(value.slice(2, 4)))
  //             ? '30'
  //             : Number(value.slice(0, 2)) > 28 &&
  //             [2].includes(Number(value.slice(2, 4)))
  //               ? '28'
  //               : value.slice(0, 2)
  //       }.${value.slice(4, 9)}`;
  //       onChangeDate(startDate, new Date(val));
  //     }
  //   } else if (!value.length) {
  //     setEndDateValue('');
  //   }
  // };

  const select = (e: any) => {
    // console.log(e);
  };

  return (
    <div className="date_root">
      {/*<div className="date_block">*/}
      {/*  <input*/}
      {/*    type="text"*/}
      {/*    name="date"*/}
      {/*    className="date_input"*/}
      {/*    placeholder="гггг.мм.дд"*/}
      {/*    value={startDateValue}*/}
      {/*    onChange={onChangeStartDateValue}*/}
      {/*  />*/}
      {/*  <span>-</span>*/}
      {/*  <input*/}
      {/*    type="text"*/}
      {/*    name="date"*/}
      {/*    className="date_input"*/}
      {/*    placeholder="гггг.мм.дд"*/}
      {/*    value={endDateValue}*/}
      {/*    onChange={onChangeEndDateValue}*/}
      {/*  />*/}
      {/*</div>*/}
      <DateRangePicker
        style={pickerStyle}
        showOneCalendar={true}
        format="dd.MM.yyyy"
        locale={locale}
        ranges={[]}
        //@ts-ignore
        value={[startDate, endDate]}
        cleanable={false}
        //@ts-ignore
        onChange={changeDates}
        toggleComponentClass={'div'}
        onSelect={select}
        placeholder={translate('operations.params.APP.rangeDateFormat')}
        character={' - '}
        isoWeek={true}
        renderTitle={(date: Date) => <span>{translateMonth(date)}</span>}
      />
    </div>
  );
};

export default CustomDateRangePicker;
