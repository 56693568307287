import React from 'react';
import { Tooltip } from '@material-ui/core';
import './CustomTooltip.css';

type CustomTooltipProps = {
  title: string,
  children: JSX.Element
}

const CustomTooltip = (props: CustomTooltipProps) => {
  return (
    <Tooltip
      {...props}
    />
  );
};

export default CustomTooltip;
