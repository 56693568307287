import React from "react";
import styles from './ActionsButton.module.css';
import CustomPopover from "../../popovers/customPopover/CustomPopover";
import CustomTooltip from "../../tooltips/customTooltip/CustomTooltip";
import {dispatchOpenPopoverEvent} from "../../../utils/utils";
import { useTranslate } from "react-admin";

type ActionsButtonProps = {
  icon: string,
  MenuElement: (props: any) => JSX.Element,
  menuElementProps?: Object
}

const ActionsButton = (props: ActionsButtonProps) => {
  const {icon, MenuElement, menuElementProps} = props;
  const [anchorElCollapseIcon, setAnchorElCollapseIcon] = React.useState(null);
  const collapseIconRef = React.useRef(null);
  const open = Boolean(anchorElCollapseIcon);
	const translate = useTranslate();

  const handleClose = (e: any) => {
    e.stopPropagation();
    setAnchorElCollapseIcon(null);
  };

  const showPopover = (e: any) => {
    e.stopPropagation();
    if (!open) {
      dispatchOpenPopoverEvent();
      setAnchorElCollapseIcon(collapseIconRef.current);
    }
    else setAnchorElCollapseIcon(null);
  };

  const closePopover = () => {
    setAnchorElCollapseIcon(null);
  }

  return (
    <div className={styles.root} onClick={showPopover} ref={collapseIconRef}>
      <CustomTooltip title={translate('ra.action.actions')} >
        <img src={icon} alt="icon" />
      </CustomTooltip>
      <CustomPopover
        open={open}
        anchorEl={anchorElCollapseIcon}
        handleClose={handleClose}
        ChildComponent={() => <MenuElement closePopover={closePopover} {...menuElementProps}/>}
      />
    </div>
  )
}

export default ActionsButton;