import * as React from 'react';
import { useLogout, useRedirect } from 'react-admin';

const DashboardPage = () => {
    const username = localStorage.getItem('username');
    const logout = useLogout();
    const redirect = useRedirect();

    React.useEffect(() => {
        if (username)
            return redirect(`/profile/${username}/show`);
        logout()
    }, [logout, redirect, username]);

    return null
};

export default DashboardPage;