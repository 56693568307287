import React from "react";
import {
	List,
	Datagrid,
	TextField,
	FunctionField,
	useDataProvider,
	BooleanField,
	SearchInput,
	FilterForm,
	NullableBooleanInput,
	SelectArrayInput,
	AutocompleteArrayInput,
	ReferenceArrayInput,
	RowClickFunction,
	useListContext,
	useTranslate
} from "react-admin";
import { Stack } from "@mui/material";
import styles from './UsersAppPage.module.css';
import CustomColumns from "../../buttons/customColumns/CustomColumns";
import RowActionsButton from "../../buttons/rowActionsButton/RowActionsButton";
import AdminAppMenu from "../../popovers/adminAppMenu/AdminAppMenu";
import { IApp, IProfile, IRole } from "../../../utils/types";
import AvatarIcon from "../../../assets/avatar.png";
import { USER_ROLES } from "../../../utils/constants";
import CustomFilterButton from "../../buttons/customFilterButton/CustomFilterButton";
import { downloadURI } from "../../../utils/utils";
import ReportsMenu from "../../popovers/reportsMenu/reportsMenu";

type UsersAppPageProps = {
	app: IApp,
}

const userFilters = [
	<SearchInput name={'q'} source={'id'} alwaysOn />,
	<NullableBooleanInput source='userAccountControl' margin='none' fullWidth={true} sx={{ minWidth: 100 }}
		label="resources.operation.fields.success"
		nullLabel="operations.params.STATUS.any"
		falseLabel="operations.params.STATUS.disabled"
		trueLabel="operations.params.STATUS.active"
	/>,
	<NullableBooleanInput source='accountExpired' margin='none' fullWidth={true} sx={{ minWidth: 200 }}
		label="resources.user.fields.accountExpires"
		nullLabel="operations.params.STATUS.any"
		falseLabel="resources.user.fields.unlimited"
		trueLabel="resources.user.fields.limited"
	/>,
	<NullableBooleanInput source='memberOfTeacherGroup' margin='none' fullWidth={true} sx={{ minWidth: 220 }}
		label="resources.user.fields.memberOfTeacherGroup"
		nullLabel="operations.params.STATUS.any"
	/>,
	<SelectArrayInput source="role" fullWidth={true} sx={{ minWidth: 180 }} choices={USER_ROLES.filter(Boolean).map(name => ({ id: USER_ROLES.indexOf(name), name }))} />,
	<ReferenceArrayInput
		source="roles"
		reference="role"
		sort={{ field: 'appId', order: 'ASC' }}
		perPage={100}
	>
		<AutocompleteArrayInput
			fullWidth={true}
			sx={{ minWidth: 240 }}
			noOptionsText={'ra.navigation.no_results'}
			debounce={1000}
			optionText="fullName"
			format={(role) => {
				console.log('role filter', role)
				return role
			}}
		/>
	</ReferenceArrayInput>
]

const CustomListToolBar = (props: UsersAppPageProps) => {
	const { app } = props;
	const { filterValues, filter } = useListContext();

	const uploadReport = (reportId: string) => {
		const reportFilter = JSON.stringify({ ...filter, ...filterValues });
		downloadURI(`/api/report/${reportId}?filter=${reportFilter}&appId=${app.id}&_t=${new Date().getTime()}`)
	}

	return (
		<Stack direction="row" justifyContent="space-between" sx={{ width: "100%", alignItems: "center" }}>
			<FilterForm filters={userFilters} />
			<Stack direction="row" sx={{ alignItems: "center" }}>
				<CustomFilterButton filters={userFilters} />
				<ReportsMenu closePopover={uploadReport} />
			</Stack>
		</Stack>
	)
}

const UsersAppPage = (props: UsersAppPageProps) => {
	const { app } = props;
	const translate = useTranslate();
	const dataProvider = useDataProvider();
	const [groups, setGroups] = React.useState<string[]>();
	const [userColumns, setUserColumns] = React.useState([
		{ source: 'displayName', label: translate('operations.params.USER.givenName'), active: true },
		{ source: 'userAccountControl', label: translate('operations.params.APPROVAL.status'), active: true },
		{ source: 'role', label: translate('operations.params.APPROVAL.type'), active: true },
		{ source: 'mail', label: translate('operations.params.USER.mail'), active: true },
		{ source: 'description', label: translate('operations.params.USER.dateOfBirth'), active: true },
		{ source: 'roles', label: translate('operations.entities.ROLE'), active: true },
		{ source: 'accountExpires', label: translate('messages.accountExpires'), active: true },
		{ source: 'whenCreated', label: translate('operations.params.APPROVAL.whenCreated'), active: true },
	]);

	const EmptyState = () => (
		<div className={styles.empty}>
			<span>{translate('messages.emptyList')}</span>
		</div>
	)

	const onChangeUserColumns = (id: string) => {
		setUserColumns(oldState => oldState.map(item => {
			if (item.source === id) item.active = !item.active;
			return item;
		}));
	}

	const onRowClick: RowClickFunction = (id) => `/app/${app.id}/show/admin/${id}`;

	React.useEffect(() => {
		if (app) {
			dataProvider.getList('role', { filter: { appId: app.id }, sort: { field: "name", order: "ASC" }, pagination: { page: 1, perPage: 100 } })
				.then(({ data }) => {
					const groups = data.map((item: IRole) => item.userGroup);
					setGroups(groups);
				})
		}

	}, [app, dataProvider])

	return (
		<div>
			<List
				actions={<CustomListToolBar app={app} />}
				disableSyncWithLocation
				resource={'user'}
				sort={{ field: 'displayName', order: 'ASC' }}
				filter={{ memberOfGroup: groups, type: 0 }}
				exporter={false}
				empty={<EmptyState />}
				sx={{
					minWidth: "-webkit-fill-available",
					width: "-moz-available",
					overflow: "auto"
				}}
			>
				<Datagrid
					rowClick={onRowClick}
					bulkActionButtons={false}
					sx={{
						'& .RaDatagrid-headerCell': {
							borderColor: "#e0e0e0",
							backgroundColor: "#fff0",
						},
						'& .RaDatagrid-rowCell': {
							maxWidth: 300,
						},
					}}
				>
					{
						userColumns.filter(item => item.active).map(item => {
							switch (item.source) {
								case 'displayName': return <FunctionField source={'displayName'} label={translate('operations.params.USER.givenName')} render={(record: any) => <div className={styles.user_field}><div className={styles.icon} style={{ backgroundImage: `url(${record.photo && record.photo.length ? record.photo : AvatarIcon})`, backgroundPosition: 'center center', backgroundSize: 'cover' }} /><span>{record.displayName || `${record.sn ? record.sn : ''} ${record.givenName}`}</span></div>} key={item.source} />;
								case 'userAccountControl': return <BooleanField source={item.source} label={item.label} key={item.source} />;
								case 'role': return <FunctionField source={'role'} label={translate('operations.params.APPROVAL.type')} render={(record: IProfile) => <span>{USER_ROLES[record.role]}</span>} key={item.source} />;
								case 'roles': return <FunctionField source={'roles'} label={translate('operations.entities.ROLE')} render={(record: any) => record.roles && record.roles.length ? <div className={styles.chips}>{[record.roles[0].name, record.roles.length < 2 ? null : `${translate('messages.still')} ${record.roles.length - 1}`].filter(Boolean).map((item: any, i) => <div className={i > 0 ? styles.more_chip : styles.chip} key={item}>{item}</div>)}</div> : null} key={item.source} />;
								default:
									return <TextField source={item.source} label={item.label} key={item.source} />
							}
						})
					}
					<FunctionField
						label={<CustomColumns columns={userColumns} onChange={onChangeUserColumns} />}
						render={(record: any) => !record.type && record.editable ? <RowActionsButton menuProps={{ record: record, context: 'user', isAdmin: record.editable }} Menu={AdminAppMenu} /> : null}
						sx={{ maxWidth: 40 }}
					/>
				</Datagrid>
			</List>
		</div>
	)
}

export default UsersAppPage;