import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardContent } from '@mui/material';
import { useDataProvider, useGetIdentity, useNotify, useRedirect, useTranslate } from "react-admin";
import styles from './CreateContragentPage.module.css';
import CustomTooltip from "../../tooltips/customTooltip/CustomTooltip";
import backIcon from "../../../assets/Back.png";
import CancelButton from "../../buttons/cancelButton/CancelButton";
import CustomButton from "../../buttons/customButton/CustomButton";
import SaveIcon from "../../../assets/Save.png";
import { IProfile, IRole } from "../../../utils/types";
import AvatarIcon from '../../../assets/avatar.png';
import CustomInputItems from "../../inputs/customInputItems/CustomInputItems";
import SelectRolesModal from "../../modals/selectRolesModal/SelectRolesModal";
import CustomTextArea from '../../inputs/customTextArea/CustomTextArea';

const CreateContragentPage = () => {
	const navigate = useNavigate();
	const notify = useNotify();
	const redirect = useRedirect();
	const { search } = useLocation();
	const userId = new URLSearchParams(search).get('user');
	const dataProvider = useDataProvider();
	const [data, setData] = React.useState<any>([]);
	const [profile, setProfile] = React.useState<IProfile>();
	const [selectedRoles, setSelectedRoles] = React.useState<IRole[]>([]);
	const [date, setDate] = React.useState<Date | null>();
	const [comment, setComment] = React.useState('');
	const translate = useTranslate();
	const [roleGroupName, setRoleGroupName] = React.useState('');
	const [contragentsGroupName, setContragentsGroupName] = React.useState('');

	const { identity: user } = useGetIdentity();

	const onComeBack = () => {
		navigate(-1);
	}

	const onChangeCause = (v: string) => {
		setComment(v);
	}

	const getGroupByRoleId = (id: number): string => {
		return id ? data.filter((item: IRole) => item.id === id).map((item: IRole) => item.userGroup) : '';
	}

	const getLinkToUser = (): string => {
		if (user?.role === 1) {
			return `/user/${profile!.id}/show`;
		} else {
			return `/app/${selectedRoles[0].appId}/show/admin/${profile!.id}`;
		}
	}

	const create = () => {
		Promise.all([
			...profile?.roles ? profile?.roles.map((role: { id: number, name: string }) => (
				dataProvider.update('group', {
					id: getGroupByRoleId(role.id), data: {
						member: {
							delete: {
								id: profile?.id
							},
						},
						comment: comment
					}, previousData: {}
				})
			)) : [],
			dataProvider.update('group', {
				id: roleGroupName, data: {
					member: {
						delete: {
							id: profile?.id,
						},
					},
					comment: comment
				}, previousData: {}
			}),
			...selectedRoles.map((role: IRole) => {
				const data: any = {
					id: profile?.id
				}
				if (date) {
					date?.setHours(0);
					date?.setMinutes(0);
					data.expiresAt = date?.toISOString();
				}
				return dataProvider.update('group', {
					id: getGroupByRoleId(role.id), data: {
						member: {
							add: data
						},
						comment: comment
					}, previousData: {}
				})
			}),
			dataProvider.update('group', {
				id: contragentsGroupName, data: {
					member: {
						add: {
							id: profile?.id,
						},
					},
					comment: comment
				}, previousData: {}
			}),
			dataProvider.update('user', {
				id: profile ? profile.id : '',
				data: {
					userAccountControl: true,
					comment: comment
				},
				previousData: profile
			})
		])
			.then(() => {
				notify('ra.notification.created', { type: 'success', messageArgs: { smart_count: 1 } });
				redirect(getLinkToUser());
			})
			.catch(() => {
				notify('ra.notification.canceled', { type: 'warning' })
			})
	}

	const onChangeDate = (v: Date | null) => setDate(v);

	React.useEffect(() => {
		if (userId) {
			dataProvider.getOne('user', { id: userId })
				.then(({ data: user }) => {
					setProfile(user);
					dataProvider.getOne('settings', { id: 'role' })
						.then(({ data }) => {
							setRoleGroupName(data.settings[`ROLE_${user.role}`]);
							setContragentsGroupName(data.settings['ROLE_4']);
						})
				})
		}
	}, [userId, dataProvider])

	React.useEffect(() => {
		dataProvider.getList('role', { sort: { field: "name", order: "ASC" }, pagination: { page: 1, perPage: 1000 }, filter: {} })
			.then(res => {
				setData(res.data);
			})
	}, [dataProvider])

	return (
		<div className={styles.wrapper}>
			<div className={styles.root}>
				<header>
					<CustomTooltip title={translate('ra.action.comeback')}>
						<div className={styles.back_icon} onClick={onComeBack}>
							<img src={backIcon} alt="icon" />
						</div>
					</CustomTooltip>
					<h1>{translate('operations.params.USER.makeCounterparty')}</h1>
				</header>
				<Card>
					<CardContent>
						<h4>{translate('operations.params.USER.assigningRoles')}</h4>
						<div className={styles.user}>
							<img src={profile?.photo && profile?.photo.length ? profile?.photo : AvatarIcon} alt="" className={styles.photo} />
							<span className={styles.name}>{`${profile?.sn && profile?.sn.length ? profile?.sn : ''} ${profile?.givenName}`}</span>
						</div>
						<div className={styles.row_item}>
							<span className={styles.row_item__label}>{translate('operations.params.USER.selectRoles')}</span>
							<div className={styles.roles}>
								<CustomInputItems selected={selectedRoles} modalProps={{ onSelect: setSelectedRoles, roles: selectedRoles, date: date, changeDate: onChangeDate }} Modal={SelectRolesModal} />
							</div>
						</div>
						<h1 className={styles.header_comment}>{translate('messages.inputReason')}</h1>
						<CustomTextArea placeholder={translate('messages.commentText')} mode={'root'} value={comment} changeInput={onChangeCause} focus={false} limit={255} />
						<div className={styles.buttons}>
							<CancelButton text={translate('ra.action.abort')} onClick={onComeBack} />
							<CustomButton text={translate('ra.action.save')} onClick={create} iconSrc={SaveIcon} disable={!selectedRoles.length} />
						</div>
					</CardContent>
				</Card>
			</div>
		</div>
	)
}

export default CreateContragentPage;