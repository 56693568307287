import * as React from 'react';
import { useRedirect } from 'react-admin';

const LoginPage = () => {
    const redirect = useRedirect();

    React.useEffect(() => {
        setTimeout(() => {
            redirect(`${window.location.origin}/auth`);
        }, 3000)
    }, [redirect]);

    return null
};

export default LoginPage;