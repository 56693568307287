import React from "react";
import { useDataProvider, useNotify, useRefresh, useTranslate } from "react-admin";
import styles from './AddAdminsModal.module.css';
import CustomInput from "../../inputs/customInput/CustomInput";
import CancelButton from "../../buttons/cancelButton/CancelButton";
import CustomButton from "../../buttons/customButton/CustomButton";
import CloseIcon from "../../../assets/close.png";
import UserItem from "../../listItem/userItem/UserItem";
import { IApp, IProfile, IRole } from "../../../utils/types";
import CustomRadioButton from "../../inputs/customRadioButton/CustomRadioButton";
import CustomDatePicker from "../../inputs/customDatePicker/CustomDatePicker";
import CustomTextArea from '../../inputs/customTextArea/CustomTextArea';
import { transformRoleName } from "../../../utils/utils";

type AddAdminsModalProps = {
	close: () => void,
	group: string,
	record: IApp | IRole;
	context?: any,
}

const UseDebounce = (value: string, delay: number) => {
	const [debounceValue, setDebounceValue] = React.useState(value);

	React.useEffect(() => {
		const handler = setTimeout(() => {
			setDebounceValue(value);
		}, delay);
		return () => clearTimeout(handler);
	}, [value, delay]);

	return debounceValue;
}

const AddAdminsModal = (props: AddAdminsModalProps) => {
	const { close, group, context, record } = props;
	const dataProvider = useDataProvider();
	const refresh = useRefresh();
	const notify = useNotify();
	const translate = useTranslate();
	const steps = ['choose', 'confirm'];
	const [search, setSearch] = React.useState('');
	const [data, setData] = React.useState<any>([]);
	const [selected, setSelected]: any[] = React.useState([]);
	const [initSelected, setInitSelected]: any[] = React.useState([]);
	const [limit, setLimit] = React.useState(false);
	const [limitDate, setLimitDate] = React.useState<Date | null>(null);
	const [step, setStep] = React.useState<string>(steps[0]);
	const [adminsApp, setAdminsApp] = React.useState<string[]>([]);
	const [adminsRole, setAdminsRole] = React.useState<string[]>([]);
	const [usersRole, setUsersRole] = React.useState<string[]>([]);
	const [comment, setComment] = React.useState('');
	const [loading, setLoading] = React.useState(false);

	const onChangeCause = (v: string) => {
		setComment(v);
	}

	const onChangeLimit = (v: string) => {
		setLimit(v === translate('messages.limitExpiration'));
	}

	const onChangeLimitDate = (v: Date | null) => {
		setLimitDate(v);
	}

	const onChangeSearch = (v: string) => {
		setSearch(v);
	}

	const onChangeSelectedAdmins = (v: boolean, name: string | number) => {
		if (!v && !initSelected.includes(name)) setSelected((prev: any[]) => prev.filter((item) => item !== name));
		else setSelected((prev: any[]) => [...prev, String(name)]);
	}

	const checkNewSelected = () => {
		return selected.length > initSelected.length;
	}

	const checkRoles = async () => {
		let appAdminsList: IApp[], roleAdminsList: IRole[], roleUsersList: IRole[];
		if (context && !context.role && context.context === 'admin') {
			const appData = await dataProvider.getOne('depsAppAdmin', { id: record.id })
			setUsersRole(appData.data.roles);
			setAdminsRole(appData.data.roleAdmins);
			setAdminsApp(appData.data.appAdmins);
			appAdminsList = appData.data.appAdmins;
			roleAdminsList = appData.data.roleAdmins;
			roleUsersList = appData.data.roles;

		} else if (context && context.role && context.context === 'admin') {
			const roleData = await dataProvider.getOne('depsRoleAdmin', { id: record.id })
			setUsersRole(roleData.data.roles);
			setAdminsRole(roleData.data.roleAdmins);
			setAdminsApp(roleData.data.appAdmins);
			appAdminsList = roleData.data.appAdmins;
			roleAdminsList = roleData.data.roleAdmins;
			roleUsersList = roleData.data.roles;

		} else {
			const role = await dataProvider.getOne('depsRole', { id: record.id })
			setUsersRole(role.data.roles);
			setAdminsRole(role.data.roleAdmins);
			setAdminsApp(role.data.appAdmins);
			appAdminsList = role.data.appAdmins;
			roleAdminsList = role.data.roleAdmins;
			roleUsersList = role.data.roles;
		}

		if (appAdminsList.length || roleAdminsList.length || roleUsersList.length)
			setStep(steps[1]);
		else onAddAdmins();
	}

	const onComeBack = () => {
		setStep(steps[0]);
	}

	const onAddAdmins = () => {
		if (selected.length) {
			selected.filter((item: string) => !initSelected.includes(item)).map((item: string) => {
				const data: any = {
					id: item
				}
				if (limit) {
					limitDate?.setHours(Math.abs(new Date().getTimezoneOffset() / 60));
					limitDate?.setMinutes(0);
					data.expiresAt = limitDate?.toISOString();
				}
				dataProvider.update('group', {
					id: group, data: {
						member: {
							add: data
						},
						comment: comment
					}, previousData: {}
				})
					.then(() => {
						notify('ra.notification.added', { type: 'success', messageArgs: { smart_count: 1 }, undoable: true });
						refresh()
					})
					.catch(() => notify('ra.notification.canceled', { type: 'warning' }))
				return false;
			})
		}
		close();
	}

	const debounceSearch = UseDebounce(search, 1000);

	React.useEffect(() => {
		setLoading(true);
		dataProvider.getList('adminApp', { sort: { field: "parentId", order: "ASC" }, pagination: { page: 1, perPage: 1000 }, filter: { type: 0, q: debounceSearch } })
			.then(res => {
				setData(res.data);
			})
			.finally(() => setLoading(false));
		// eslint-disable-next-line
	}, [debounceSearch, dataProvider])

	React.useEffect(() => {
		if (data && !selected.length) {
			const arr = data
				.filter((item: IProfile) => item.memberOf && item.memberOf.includes(group))
				.map((item: IProfile) => item.id);
			setSelected(arr);
			setInitSelected(arr);
		}
	}, [data, group, selected]);

	return (
		step === steps[0] ?
			<div className={styles.root}>
				<div className={styles.close_button} onClick={close}>
					<img src={CloseIcon} alt="" />
				</div>
				<span className={styles.title}>{context && context.context === 'user' ? translate('messages.addUser') : translate('messages.addAdmin')}</span>
				<CustomInput placeholder={translate('ra.action.search')} mode={'root'} value={search} changeInput={onChangeSearch} errorMessage={''} focus={false} />
				<div className={styles.table}>
					<div className={styles.table_header}>
						<span>{translate('operations.params.USER.givenName')}</span>
					</div>
					<div className={styles.table_body}>
						{
							!loading && data.length ? data.map((item: any) => (
								<UserItem selected={selected.includes(item.id)} onChange={onChangeSelectedAdmins} text={item.parentId.split(',').reverse().concat(item.givenName && item.givenName.length ? `${item.sn ?? ''} ${item.givenName}` : item.id).join(' / ').split('')} id={item.id} key={item.id} />
							)) :
								loading ? <span className={styles.not_found}>{translate('ra.message.loading')}</span> : <span className={styles.not_found}>{translate('ra.navigation.no_results')}</span>
						}
					</div>
				</div>
				<div className={styles.limits}>
					<span>{translate('messages.expirationDate')}</span>
					<CustomRadioButton
						list={[translate('messages.notLimitExpiration'), translate('messages.limitExpiration')]}
						value={limit ? translate('messages.limitExpiration') : translate('messages.notLimitExpiration')}
						changeValue={onChangeLimit}
						directionHorizontal={false}
					/>
				</div>
				{
					limit ?
						<div className={styles.input_date}>
							<span>{translate('messages.expirationDateTo')}</span>
							<CustomDatePicker onChangeDate={onChangeLimitDate} date={limitDate ?? new Date()} />
						</div> : null
				}
				<h1 className={styles.header}>{translate('messages.inputReason')}</h1>
				<CustomTextArea placeholder={translate('messages.commentText')} mode={'root'} value={comment} changeInput={onChangeCause} focus={false} limit={255} />
				<div className={styles.buttons}>
					<CancelButton text={translate('ra.action.cancel')} onClick={close} />
					<CustomButton text={translate('ra.action.choose')} onClick={checkRoles} disable={!checkNewSelected()} />
				</div>
			</div> :
			<div className={styles.root}>
				<div className={styles.close_button} onClick={close}>
					<img src={CloseIcon} alt="" />
				</div>
				<span className={styles.title}>{translate('messages.giveAccess')}</span>
				<span className={styles.text}>{`${translate('messages.thenGiveAccessMessage')} ${context && context.context === 'user' ? '' : translate('messages.administration')} ${record.name} ${selected.length > 1 ? translate('messages.usersWillReceive') : translate('messages.userWillReceive')} ${translate('messages.accessTo')}`}</span>
				<ul className={styles.list}>
					{usersRole.length ? usersRole.map((item: string) => <li className={styles.list_item} key={item.toString()}>{transformRoleName(item, 54)}</li>) : null}
					{adminsApp.length ? adminsApp.map((item: string) => <li className={styles.list_item} key={item.toString()}>{`${translate('messages.appAdministration')} ${transformRoleName(item, 54)}`}</li>) : null}
					{adminsRole.length ? adminsRole.map((item: string) => <li className={styles.list_item} key={item.toString()}>{`${translate('messages.roleAdministration')} ${transformRoleName(item, 54)}`}</li>) : null}
				</ul>
				<div className={styles.buttons_wrapper}>
					<CancelButton text={translate('ra.action.comeback')} onClick={onComeBack} />
					<div className={styles.buttons}>
						<CancelButton text={translate('ra.action.cancel')} onClick={close} />
						<CustomButton text={translate('ra.action.confirm')} onClick={() => onAddAdmins()} />
					</div>
				</div>
			</div>
	)
}

export default AddAdminsModal;