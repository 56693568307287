import React from "react";
import {useDataProvider, useTranslate} from "react-admin";
import styles from './SelectGroupModal.module.css';
import CustomInput from "../../inputs/customInput/CustomInput";
import CancelButton from "../../buttons/cancelButton/CancelButton";
import CustomButton from "../../buttons/customButton/CustomButton";
import CloseIcon from "../../../assets/close.png";
import GroupItem from "../../listItem/groupItem/GroupItem";
import {IApp, IRole} from "../../../utils/types";

type SelectGroupModalProps = {
  close: () => void,
  onSelect: (v: string) => void,
  selectedItem?: string,
}

const UseDebounce = (value: string, delay: number) => {
  const [debounceValue, setDebounceValue] = React.useState(value);

  React.useEffect(()  => {
    const handler = setTimeout(() => {
      setDebounceValue(value);
    }, delay);
    return () => clearTimeout(handler);
  }, [value, delay]);

  return debounceValue;
}

const SelectGroupModal = (props: SelectGroupModalProps) => {
  const {close, onSelect, selectedItem} = props;
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const steps = ['choose', 'confirm'];
  const [search, setSearch] = React.useState('');
  const [data, setData] = React.useState<any>([]);
  const [selected, setSelected] = React.useState(selectedItem || '');
  const [step, setStep] = React.useState<string>(steps[0])
  const [adminsApp, setAdminsApp] = React.useState<IApp[]>([]);
  const [adminsRole, setAdminsRole] = React.useState<IRole[]>([]);
  const [usersRole, setUsersRole] = React.useState<IRole[]>([]);

  const onChangeSearch = (v: string) => {
    setSearch(v);
  }

  const onChangeSelectedGroup = (v:boolean, name: string) => {
    if (v) setSelected(name);
    else setSelected('');
  }

  const onSelectGroup = () => {
    onSelect(selected);
    close();
  }

  const debounceSearch = UseDebounce(search, 1000);

  const checkGroup = async () => {
    let appAdminsList: IApp[] = [], roleAdminsList: IRole[] = [], roleUsersList: IRole[] = [];
    await dataProvider.getList('app', {filter: {adminGroup: selected}, pagination: {page: 1, perPage: 1000}, sort: {field: 'name', order: 'ASC'}})
      .then(({data}) => {
        setAdminsApp(data);
        appAdminsList = data;
      });
    await dataProvider.getList('role', {filter: {adminGroup: selected}, pagination: {page: 1, perPage: 1000}, sort: {field: 'name', order: 'ASC'}})
      .then(({data}) => {
        setAdminsRole(data);
        roleAdminsList = data;
      });
    await dataProvider.getList('role', {filter: {userGroup: selected}, pagination: {page: 1, perPage: 1000}, sort: {field: 'name', order: 'ASC'}})
      .then(({data}) => {
        setUsersRole(data);
        roleUsersList = data;
      });
    if (appAdminsList.length || roleAdminsList.length || roleUsersList.length) setStep(steps[1]);
    else onSelectGroup();
  }

  const onComeBack = () => {
    setStep(steps[0]);
  }

  React.useEffect(() => {
    dataProvider.getList('group', {sort: {field: "id", order: "ASC"}, pagination: {page: 1, perPage: 1000}, filter: {q: debounceSearch}})
      .then(res => {
        setData(res.data);
      })
  },[debounceSearch, dataProvider])

  return (
    step === steps[0] ?
    <div className={styles.root}>
      <div className={styles.close_button} onClick={close}>
        <img src={CloseIcon} alt=""/>
      </div>
      <span className={styles.title}>{translate('messages.chooseGroup')}</span>
      <CustomInput placeholder={translate('ra.action.search')} mode={'root'} value={search} changeInput={onChangeSearch} errorMessage={''} focus={false} />
      <div className={styles.table}>
        <div className={styles.table_header}>
          <span>{translate('operations.params.USER.givenName')}</span>
        </div>
        <div className={styles.table_body}>
          {
            data.length ? data.map((item: any) => (
              <GroupItem selected={selected === item.name} onChange={onChangeSelectedGroup} text={item.name} key={item.name}/>
            )) : <span className={styles.not_found}>{translate('messages.notFound')}</span>
          }
        </div>
      </div>
      <div className={styles.buttons}>
        <CancelButton text={translate('ra.action.cancel')} onClick={close} />
        <CustomButton text={translate('ra.action.choose')} onClick={checkGroup} disable={!selected?.length}/>
      </div>
    </div> :
      <div className={styles.root}>
        <div className={styles.close_button} onClick={close}>
          <img src={CloseIcon} alt="" />
        </div>
        <span className={styles.title}>{translate('messages.chooseGroup')}</span>
        <span className={styles.text}>{`${translate('operations.params.USER.group')} ${selected} ${translate('messages.alreadyConnectedTo')}`}</span>
        <ul className={styles.list}>
          {usersRole.length ? usersRole.map((item: IRole) => <li className={styles.list_item} key={item.id}>{item.fullName}</li>) : null}
          {adminsApp.length ? adminsApp.map((item: IApp) => <li className={styles.list_item} key={item.id}>{`${translate('messages.appAdministration')} ${item.name}`}</li>) : null}
          {adminsRole.length ? adminsRole.map((item: IRole) => <li className={styles.list_item} key={item.id}>{`${translate('messages.roleAdministration')} ${item.name}`}</li>) : null}
        </ul>
        <div className={styles.buttons_wrapper}>
          <CancelButton text={translate('ra.action.comeback')} onClick={onComeBack} />
          <div className={styles.buttons}>
            <CancelButton text={translate('ra.action.cancel')} onClick={close} />
            <CustomButton text={translate('ra.action.choose')} onClick={onSelectGroup}/>
          </div>
        </div>
      </div>
  )
}

export default SelectGroupModal;