import React from "react";
import {FormControlLabel, Radio, FormControl, RadioGroup} from "@mui/material";
import './CustomRadioButton.css';

type CustomRadioButtonProps = {
  list: string[],
  value: string,
  changeValue: (v: string) => void,
  directionHorizontal?: boolean,
}

const CustomRadioButton = (props: CustomRadioButtonProps) => {
  const {value, changeValue, list, directionHorizontal = true} = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeValue(event.target.value);
  };

  return (
    <div className={'radio_select'}>
      <FormControl>
        <RadioGroup
          value={value}
          onChange={handleChange}
          className={directionHorizontal ? 'horizontal' : 'vertical'}
        >
          {
            list.map(item => (
              <FormControlLabel value={item} control={<Radio />} label={item} key={item}/>
            ))
          }
        </RadioGroup>
      </FormControl>
    </div>
  )
}

export default CustomRadioButton;