import React, { Fragment, ReactElement } from "react";
import {
	FilterForm,
	List,
	Datagrid,
	FunctionField,
	TextField,
	ReferenceField,
	useListController,
	useNotify,
	useUnselectAll,
	useUnselect,
	useRefresh,
	useGetIdentity,
	AutocompleteArrayInput,
	ReferenceArrayInput,
	SelectArrayInput,
	useTranslate,
	useInput,
	DateInputProps,
	Labeled,
	Loading
} from "react-admin";
import { Stack, Tab, Tabs } from "@mui/material";
import styles from './ApprovalsPage.module.css';
import CustomColumns from "../../buttons/customColumns/CustomColumns";
import RowActionsButton from "../../buttons/rowActionsButton/RowActionsButton";
import ActionsAppMenu from "../../popovers/actionsAppMenu/ActionsAppMenu";
import { IApp, IApproval, IProfile, IRole } from "../../../utils/types";
import AvatarIcon from "../../../assets/avatar.png";
import dataProvider from "../../../provider/dataProvider";
import CustomFilterButton from "../../buttons/customFilterButton/CustomFilterButton";
import CustomModal from "../../modals/customModal/CustomModal";
import ConfirmManyApprovalsModal from "../../modals/confirmManyApprovalsModal/ConfirmManyApprovalsModal";
import CustomDateRangePicker from "../../inputs/customDateRangePicker/CustomDateRangePicker";
import { dateFormat } from "../../../utils/utils";

type columnType = {
	source: string,
	label: string,
	active: boolean,
}

const ApprovalsPage = () => {
	const tabs = ['pend', 'self', 'all'];
	const [tab, setTab] = React.useState<string>(tabs[0]);
	const [roles, setRoles] = React.useState<IRole[]>([]);
	const [apps, setApps] = React.useState<IApp[]>([]);
	const [showConfirmModal, setShowConfirmModal] = React.useState(false);
	const [listConfirm, setListConfirm] = React.useState<any>([]);
	const { identity: user, isLoading: isLoadingUser } = useGetIdentity();
	const { selectedIds, showFilter, setSort, sort, data, filterValues, setFilters, displayedFilters, isLoading } = useListController();
	const notify = useNotify();
	const unselectAll = useUnselectAll('approval');
	const unselect = useUnselect('approval');
	const refresh = useRefresh();
	const translate = useTranslate();
	
	const [approvalColumns, setApprovalColumns] = React.useState([
		{ source: 'type', label: translate('operations.params.APPROVAL.type'), active: true },
		{ source: 'createdAt', label: translate('operations.params.APPROVAL.createdAt'), active: true },
		{ source: 'status', label: translate('operations.params.APPROVAL.status'), active: true },
		{ source: 'target', label: translate('operations.params.APPROVAL.requestObject'), active: true },
		{ source: 'owner', label: translate('operations.params.APPROVAL.author'), active: true },
		{ source: 'comment', label: translate('operations.params.APPROVAL.reason'), active: true },
		{ source: 'description', label: translate('operations.params.APPROVAL.authorComment'), active: true },
		{ source: 'decider', label: translate('operations.params.APPROVAL.executor'), active: false },
		{ source: 'updatedAt', label: translate('operations.params.APPROVAL.updatedAt'), active: false },
	]);
	
	const statusListValue = [
		{ id: 'pending', name: 'approvals.FILTERS.pending' },
		{ id: 'approved', name: 'approvals.FILTERS.approved' },
		{ id: 'canceled', name: 'approvals.FILTERS.canceled' }
	];

	const typeListValue = [
		{ id: 'photo', name: 'approvals.FILTERS.photo' },
		{ id: 'access', name: 'approvals.FILTERS.access' }
	]

	const labeledStyle = {
		backgroundColor: 'rgba(0, 0, 0, 0.04)',
		borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
		'& p span': {
			fontFamily: 'Roboto, sans-serif',
			fontStyle: 'normal',
			fontWeight: 400,
			fontSize: '.8rem',
			marginLeft: '10px',
			marginTop: '5px',
		}
	}

	const CustomDateInput = (props: DateInputProps) => {
		const { field } = useInput(props);
		return (
			<Labeled label={translate(`resources.approval.fields.${field.name}`)} sx={labeledStyle}>
				<CustomDateRangePicker
					startDate={Array.isArray(field.value) ? new Date(field.value[0]) : null}
					endDate={Array.isArray(field.value) ? new Date(field.value[1]) : null}
					onChangeDate={(d1, d2) => {
						const start = new Date(d1);
						const end = new Date(d2);
						start.setHours(0);
						start.setMinutes(0);
						start.setSeconds(0);
						start.setMilliseconds(0);
						end.setHours(23);
						end.setMinutes(59);
						end.setSeconds(59);
						setFilters({ ...filterValues, [field.name]: [start, end] }, displayedFilters);
					}} />
			</Labeled>
		)
	}

	const approvalFilters = [
		<SelectArrayInput label={translate('operations.params.APPROVAL.status')} source="status" fullWidth={true} sx={{ minWidth: 180 }} choices={statusListValue} translateChoice={true} />,
		<SelectArrayInput label={translate('operations.params.APPROVAL.type')} source="type" fullWidth={true} sx={{ minWidth: 180 }} choices={typeListValue} translateChoice={true} />,
		<CustomDateInput source='createdAt'
			margin='none'
		/>,
		<ReferenceArrayInput
			source="target"
			label={translate('operations.entities.ROLE')}
			reference="role"
			sort={{ field: 'appId', order: 'ASC' }}
			perPage={1000}
			sx={{
				maxWidth: '200px'
			}}
		>
			<AutocompleteArrayInput
				fullWidth={true} sx={{ minWidth: 240 }}
				noOptionsText={'ra.navigation.no_results'}
				label={translate('operations.entities.ROLE')}
				optionText="fullName"
			/>
		</ReferenceArrayInput>,
		<CustomDateInput source='updatedAt'
			margin='none'
		/>,
	]

	if (user && user?.role === 1) {
		approvalFilters.push(
			<ReferenceArrayInput
				source="owner"
				label={translate('operations.params.APPROVAL.author')}
				reference="user"
				sort={{ field: 'displayName', order: 'ASC' }}
				filter={{ type: 0 }}
				enableGetChoices={({ q }) => q?.length >= 2}
				perPage={1000}
				sx={{
					maxWidth: '200px'
				}}
			>
				<AutocompleteArrayInput
					fullWidth={true} sx={{ minWidth: 240 }}
					noOptionsText={'ra.navigation.no_results'}
					label={translate('operations.params.APPROVAL.author')}
					debounce={1000}
					optionText="displayName"
				/>
			</ReferenceArrayInput>);
		approvalFilters.push(
			<ReferenceArrayInput
				source="decider"
				label={translate('operations.params.APPROVAL.executor')}
				reference="user"
				sort={{ field: 'displayName', order: 'ASC' }}
				filter={{ type: 0 }}
				enableGetChoices={({ q }) => q?.length >= 2}
				perPage={1000}
				sx={{
					maxWidth: '200px'
				}}
			>
				<AutocompleteArrayInput
					fullWidth={true} sx={{ minWidth: 240 }}
					noOptionsText={'ra.navigation.no_results'}
					label={translate('operations.params.APPROVAL.executor')}
					debounce={1000}
					optionText="displayName"
				/>
			</ReferenceArrayInput>);
	}

	const onChangeTab = (e: any, newTab: string) => {
		setTab(newTab);
	}

	const onCloseConfirmModal = () => {
		setShowConfirmModal(false);
		unselectAll();
		refresh();
	}

	const CustomListToolBar = () => (
		<div className={styles.toolbar}>
			<Stack direction="row" justifyContent="space-between" sx={{ width: "100%", alignItems: "center" }}>
				<FilterForm filters={approvalFilters} />
				<CustomFilterButton filters={approvalFilters} />
			</Stack>
			<Tabs onChange={onChangeTab} value={tab} sx={{
				'& .MuiTabs-indicator': {
					backgroundColor: '#262626'
				}
			}}>
				{
					user?.role === 1 || getPermissionAdminApp() || getPermissionAdminRole() ? <Tab label={translate('approvals.TABS.toApproval')} value={tabs[0]} /> : null
				}
				{
					user?.role !== 1 ? <Tab label={translate('approvals.TABS.myApprovals')} value={tabs[1]} /> : null
				}
				{
					user?.role !== 1 && (getPermissionAdminApp() || getPermissionAdminRole()) ? <Tab label={translate('approvals.TABS.allApprovals')} value={tabs[2]} /> : null
				}
			</Tabs>
		</div>
	)

	const onChangeApprovalColumns = (id: string) => {
		setApprovalColumns(oldState => oldState.map(item => {
			if (item.source === id) item.active = !item.active;
			return item;
		}));
	}

	const onConfirmAll = async () => {
		const selectedApprovals = data.filter((item: IApproval) => selectedIds.includes(item.id) && item.type === 'access');
		const selectedApprovalsPhoto = data.filter((item: IApproval) => selectedIds.includes(item.id) && item.type === 'photo');
		const selectedUsers: IProfile[] = await dataProvider.getMany('user', { ids: selectedApprovals.map((item: IApproval) => item.owner) }).then(({ data }) => data);
		const selectedRoles: IRole[] = await dataProvider.getMany('role', { ids: selectedApprovals.map((item: IApproval) => item.target) }).then(({ data }) => data);
		const list = await Promise.all(
			selectedApprovals.map((approval: IApproval) =>
				new Promise(async (resolve) => {
					const access: any = await dataProvider.getOne('depsRole', { id: approval.target });
					const checkedRoles = {
						approval: approval,
						roleName: selectedRoles?.find((item: IRole) => String(item.id) === approval.target)?.name,
						userName: `${selectedUsers?.find((item: IProfile) => item.id === approval.owner)?.sn} ${selectedUsers?.find((item: IProfile) => item.id === approval.owner)?.givenName}`,
						appAdminsList: access.data.appAdmins,
						roleAdminsList: access.data.roleAdmins,
						roleUsersList: access.data.roles,
					}
					resolve(checkedRoles);
				}))
		)
		setListConfirm(list);
		if (selectedApprovalsPhoto.length) selectedApprovalsPhoto.map((item: IApproval) => onConfirmOne(item.id, undefined))
		if (list.length) setShowConfirmModal(true);
	}

	const onConfirmOne = (id: number, v: string | undefined) => {
		dataProvider.update('approval', { id: id, data: { status: 'approved', comment: v }, previousData: {} })
			.then(() => {
				notify('ra.notification.approved', { type: 'success', messageArgs: { smart_count: 1 }, undoable: true });
				unselect([id]);
				refresh();
			})
			.catch(() => notify('ra.notification.canceled', { type: 'warning' }))
	}

	const PostBulkActionButtons: ReactElement<any, string | React.JSXElementConstructor<any>> = (
		<Fragment>
			<span className={styles.button} onClick={onConfirmAll}>{translate('ra.action.approve')}</span>
		</Fragment>
	);

	const getPermissionAdminApp = (): boolean => {
		return apps ? apps.reduce((isAdmin, item) => isAdmin || (user?.memberOf && user?.memberOf.includes(item.adminGroup)), false) : false;
	}

	const getPermissionAdminRole = (): boolean => {
		return roles ? roles.reduce((isAdmin, item) => isAdmin || (user?.memberOf && user?.memberOf.includes(item.adminGroup)), false) : false;
	}

	const getFilter = () => {
		if (tab === tabs[0]) {
			return displayedFilters.owner && filterValues.owner ? {} : { owner: { "$ne": user?.id } }
		} else if (tab === tabs[1]) {
			return { owner: user?.id }
		} else if (tab === tabs[2]) {
			return {}
		}
	}

	const getLinkToUser = (approval: any): string => {
		if (approval.type === 'access') {
			const findRole = roles.find((role: IRole) => role.id === Number(approval.target));
			return `/app/${findRole?.appId}/show/admin/${approval.owner}`;
		} else {
			return `/user/${approval.owner}/show`;
		}
	}

	const EmptyState = () => (
		<div className={styles.empty}>
			<CustomListToolBar />
			<span>{translate('messages.emptyLssist')}</span>
		</div>
	)

	React.useEffect(() => {
		dataProvider.getList('role', { sort: { field: "name", order: "ASC" }, pagination: { page: 1, perPage: 1000 }, filter: {} })
			.then(res => {
				setRoles(res.data);
			})
		dataProvider.getList('app', { sort: { field: "name", order: "ASC" }, pagination: { page: 1, perPage: 1000 }, filter: {} })
			.then(res => {
				setApps(res.data);
			})
	}, [])

	React.useEffect(() => {
		showFilter('status', ['pending']);
		if (sort.field === 'id') {
			setSort({ field: 'createdAt', order: 'DESC' })
		}
		// eslint-disable-next-line
	}, [])

	React.useEffect(() => {
		if (apps?.length && roles?.length) {
			if (user?.role === 1 || getPermissionAdminApp() || getPermissionAdminRole()) setTab(tabs[0])
			else setTab(tabs[1])
		}
		// eslint-disable-next-line
	}, [apps, roles])

	if (isLoadingUser || isLoading) return <Loading />

	return (
		<div>
			<h1>{translate('operations.entities.APPROVAL')}</h1>
			{
				<List
					actions={<CustomListToolBar />}
					sort={{ field: 'createdAt', order: 'DESC' }}
					filter={getFilter()}
					empty={<EmptyState />}
				>
					<Datagrid
						rowClick={'show'}
						isRowSelectable={row => row.status === 'pending' && row.owner !== user?.id}
						bulkActionButtons={user?.role === 1 || getPermissionAdminApp() || getPermissionAdminRole() ? PostBulkActionButtons : false}
						sx={{
							'& .RaDatagrid-headerCell': {
								borderColor: "#e0e0e0",
								backgroundColor: "#fff0",
							},
							'& .RaDatagrid-rowCell': {
								maxWidth: 200,
								'&.column-undefined': {
									width: 40,
									'& span': {
										display: "flex",
										alignItems: "center",
									},
								},
								'&.column-comment': {
									'& span': {
										display: "block",
										whiteSpace: "nowrap",
										textOverflow: "ellipsis",
										overflow: "hidden",
									},
								},
								'&.column-createdAt': {
									maxWidth: 100,
								},
							},
						}}
					>
						{
							approvalColumns.filter((item: columnType) => item.active).map((item: columnType) => {
								switch (item.source) {
									case 'owner': return <ReferenceField label={translate('operations.params.APPROVAL.author')} reference={'user'} source={'owner'} link={(record: any) => getLinkToUser(record)} emptyText={translate('operations.params.USER.userNotFound')} key={item.source}><FunctionField render={(record: IProfile) => <div className={styles.user_field}><div className={styles.icon} style={{ backgroundImage: `url(${record.photo && record.photo.length ? record.photo : AvatarIcon})`, backgroundPosition: 'center center', backgroundSize: 'cover' }} /><span>{`${record.sn ? record.sn : ''} ${record.givenName}`}</span></div>} /></ReferenceField>;
									case 'decider': return <ReferenceField label={translate('operations.params.APPROVAL.executor')} reference={'user'} source={'decider'} link={(record: any) => user?.role === 1 ? `/user/${record.decider}/show` : ''} emptyText={translate('operations.params.USER.userNotFound')} key={item.source}><FunctionField render={(record: IProfile) => <div className={styles.user_field}><div className={styles.icon} style={{ backgroundImage: `url(${record.photo && record.photo.length ? record.photo : AvatarIcon})`, backgroundPosition: 'center center', backgroundSize: 'cover' }} /><span>{`${record.sn ? record.sn : ''} ${record.givenName}`}</span></div>} /></ReferenceField>;
									case 'target': return <FunctionField label={translate('operations.params.APPROVAL.requestObject')} render={(record: IApproval) => record.type === 'access' ? <ReferenceField reference={'role'} source={'target'} link={'show'}><TextField source={'name'} /></ReferenceField> : record.type === 'photo' ? <div className={styles.icon} style={{ backgroundImage: `url(${record.target && record.target.length ? record.target : AvatarIcon})`, backgroundPosition: 'center center', backgroundSize: 'cover' }} /> : null} key={item.source} />;
									case 'type': return <FunctionField label={translate('operations.params.APPROVAL.type')} render={(record: IApproval) => <span className={styles.type}>{{ 'photo': translate('operations.params.APPROVAL.changePhoto'), 'access': translate('operations.params.APPROVAL.forAccess') }[record.type]}</span>} key={item.source} />;
									case 'status': return <FunctionField label={translate('operations.params.APPROVAL.status')} render={(record: IApproval) => ({ 'pending': <div className={styles.status_pending}><div /><span>{translate('operations.params.APPROVAL.pending')}</span></div>, 'approved': <div className={styles.status_approved}><div /><span>{translate('operations.params.APPROVAL.approved')}</span></div>, 'canceled': <div className={styles.status_canceled}><div /><span>{translate('operations.params.APPROVAL.canceled')}</span></div> }[record.status])} key={item.source} />;
									case 'createdAt': return <FunctionField source={'createdAt'} label={translate('operations.params.APPROVAL.createdAt')} sx={{ maxWidth: 200, }} key={item.source} render={(record: IApproval) => dateFormat(record.createdAt, { showTime: true, showMilliseconds: true })} />;
									case 'updatedAt': return <FunctionField source={'updatedAt'} label={translate('operations.params.APPROVAL.updatedAt')} sx={{ maxWidth: 200, }} key={item.source} render={(record: IApproval) => record.updatedAt !== record.createdAt ? dateFormat(record.updatedAt, { showTime: true, showMilliseconds: true }) : null} />;
									default: return <TextField source={item.source} label={item.label} key={item.source} />
								}
							})
						}
						<FunctionField
							label={<CustomColumns columns={approvalColumns} onChange={onChangeApprovalColumns} />}
							render={(record: any) => !record.type ? <RowActionsButton menuProps={{ record: record }} Menu={ActionsAppMenu} /> : null}
							sx={{
								maxWidth: 40
							}}
						/>
					</Datagrid>
				</List>
			}
			<CustomModal
				open={showConfirmModal}
				close={onCloseConfirmModal}
			>
				<ConfirmManyApprovalsModal
					closeModal={onCloseConfirmModal}
					onApprove={onConfirmOne}
					list={listConfirm}
				/>
			</CustomModal>
		</div>
	)
}

export default ApprovalsPage;