import React from "react";
import styles from './GroupItem.module.css';
import CustomCheckbox from "../../inputs/customCheckbox/CustomCheckbox";
import FolderIcon from '../../../assets/folder.png';

type GroupItemProps = {
  selected: boolean,
  onChange: (v: boolean, name: string) => void,
  text: string,
}

const GroupItem = (props: GroupItemProps) => {
  const {selected, onChange, text} = props;

  const onChangeSelected = (e: any) => {
    onChange(e.target.checked, text);
  }

  return (
    <div className={selected ? `${styles.selected} ${styles.root}` : styles.root}>
      <div className={styles.checkbox}>
        <CustomCheckbox checked={selected} change={onChangeSelected} indeterminate={false} around={true} />
      </div>
      <div className={styles.icon}>
        <img src={FolderIcon} alt={''}/>
      </div>
      <span className={styles.name}>{text}</span>
    </div>
  )
}

export default GroupItem;