import React from "react";
import {useNavigate} from "react-router-dom";
import styles from "./AddProfileMenu.module.css";
import CustomPopoverMenuItem from "../customPopoverMenuItem/CustomPopoverMenuItem";
import CounterpartyIcon from '../../../assets/user1.png';
import AppIcon from '../../../assets/Widgets.png';
import { useTranslate } from "react-admin";

type AddProfileMenuProps = {
  closePopover: () => void,
  isAdmin: boolean,
}

const AddProfileMenu = (props: AddProfileMenuProps) => {
  const {closePopover, isAdmin} = props;
  const navigate = useNavigate();
	const translate = useTranslate();
  const onClickWrapper = (e: any) => {
    e.stopPropagation();
  }

  const createCounterparty = () => {
    navigate('/user/create');
    closePopover();
  }

  const createApp = () => {
    navigate('/app/create');
    closePopover();
  }

  return (
    <div className={styles.root} onClick={onClickWrapper}>
      <div className={styles.middle}>
        <CustomPopoverMenuItem text={translate('operations.params.USER.counterparty')} onClick={createCounterparty} icon={<img src={CounterpartyIcon} alt={''}/>}/>
        {
          isAdmin ? <CustomPopoverMenuItem text={translate('operations.params.APP.application')} onClick={createApp} icon={<img src={AppIcon} alt={''}/>}/> : null
        }
      </div>
    </div>
  )
}

export default AddProfileMenu;