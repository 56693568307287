import React from "react";
import { useRecordContext, useTranslate } from "react-admin";
import { useNavigate } from "react-router-dom";
import styles from './AppCard.module.css';
import SettingsIcon from '../../../assets/settings.png';
import AppIcon from '../../../assets/appIcon.png';
import ActionsAppMenu from "../../popovers/actionsAppMenu/ActionsAppMenu";
import RowActionsButton from "../../buttons/rowActionsButton/RowActionsButton";

type AppCardProps = {
  user: any
}

const AppCard = (props: AppCardProps) => {
  const { user } = props;
  const navigate = useNavigate();
  const record = useRecordContext();
  const translate = useTranslate();

  const getPermissionAdminApp = (): boolean => {
    return user?.memberOf && user?.memberOf.includes(record.adminGroup);
  }

  const editApp = () => {
    navigate(`/app/${record.id}/edit`)
  }

  return (
    <div className={styles.root}>
      <div className={styles.row_direction}>
        <div className={styles.avatar}>
          <img src={(record && record.logo) || AppIcon} alt="" />
        </div>
        <span className={styles.name}>{record.name}</span>
      </div>
      {user.role === 1 || getPermissionAdminApp() ?
        <div className={styles.row_direction}>
          <div className={styles.settings} onClick={editApp}>
            <img src={SettingsIcon} alt="" />
            <span className={styles.settings_text}>{translate('operations.params.APP.settings')}</span>
          </div>
          <div className={styles.actions}>
            <RowActionsButton menuProps={{ record: record }} Menu={ActionsAppMenu} />
          </div>
        </div>
        :
        null
      }
    </div>
  )
}

export default AppCard;