import React from 'react';
import styles from './AddButton.module.css';
import Icon from '../../../assets/Add.png';

type AddButtonProps = {
  text: string,
  onClick: () => void,
}

const AddButton = (props: AddButtonProps) => {
  const {text, onClick} = props;
  return (
    <button className={styles.button} onClick={onClick}>
      <div className={styles.icon}>
        <img src={Icon} alt="icon"/>
      </div>
      <span className={styles.text}>{text}</span>
    </button>
  )
}

export default AddButton;