import React from "react";
import { useRecordContext, useTranslate } from "react-admin";
import styles from './AppInfoCard.module.css';
import { dateFormat } from "../../../utils/utils";

const AppInfoCard = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  return (
    <div className={styles.root}>
      <span className={styles.title}>{translate('operations.params.APP.info')}</span>
      <div className={styles.row}>
        <span className={styles.row_prop}>{translate('operations.params.APP.appAddress')}</span>
        <a className={`${styles.row_value} ${styles.link}`} href={record.url}>{record.url}</a>
      </div>
      <div className={styles.row}>
        <span className={styles.row_prop}>{translate('operations.params.APP.description')}</span>
        <span className={styles.row_value}>{record.description}</span>
      </div>
      <div className={styles.row}>
        <span className={styles.row_prop}>{translate('operations.params.APP.createdAt')}</span>
        <span className={styles.row_value}>{dateFormat(record.createdAt)}</span>
      </div>
    </div>
  )
}

export default AppInfoCard;