import React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import styles from './RoleAppMenu.module.css';
import CustomPopoverMenuItem from "../customPopoverMenuItem/CustomPopoverMenuItem";
import CustomModal from "../../modals/customModal/CustomModal";
import RemoveRoleModal from "../../modals/removeRoleModal/RemoveRoleModal";
import AddAdminsModal from "../../modals/addAdminsModal/AddAdminsModal";
import {useDataProvider, useGetIdentity, useRefresh, useTranslate} from "react-admin";
import {IApp, IRole} from "../../../utils/types";

type RoleAppMenuProps = {
  closePopover: () => void
  record?: any,
  onPasteRole: (pasteRole: IRole) => void,
  showModal: (text: string) => void,
}

const RoleAppMenu = (props: RoleAppMenuProps) => {
  const { closePopover, record, onPasteRole, showModal } = props;
  const navigate = useNavigate();
	const translate = useTranslate();
  const refresh = useRefresh();
  const { pathname } = useLocation();
  const { identity: user } = useGetIdentity();
  const dataProvider = useDataProvider();
  const [showAdminsModal, setShowAdminsModal] = React.useState(false);
  const [showRemoveModal, setShowRemoveModal] = React.useState(false);
  const [app, setApp] = React.useState<IApp>();
	const step = JSON.parse(localStorage.getItem('moving')!);
  const onClickWrapper = (e: any) => {
    e.stopPropagation();
  }

  const getPermissionAdminApp = (): boolean => {
    return app ? user?.memberOf && user?.memberOf.includes(app.adminGroup) : false;
  }

  const editRole = () => {
    navigate(`/app/${record.appId}/show/role/${record.id}/settings`);
  }

  const addRole = () => {
    navigate(`/app/${record.appId}/show/role/${record.id}/create`);
  }

  const onShowAdminsModal = () => {
    setShowAdminsModal(true);
  }

  const onCloseAdminsModal = () => {
    setShowAdminsModal(false);
    closePopover();
  }

  const onShowRemoveModal = () => {
    setShowRemoveModal(true);
  }

  const onCloseRemoveModal = () => {
    setShowRemoveModal(false);
    closePopover();
  }

  const setPaste = (record:any) => {
    if(step.id === record.appId) {
			return
		} else {
      onPasteRole(record);
      refresh();
      showModal('pasteApp');
    }
	}

  React.useEffect(() => {
    if (record) {
      dataProvider.getOne('app', {id: record.appId})
        .then(({data}) => {
          setApp(data);
        })
    }
  },[record, dataProvider])

  return (
    <div className={styles.root} onClick={onClickWrapper}>
      <div className={styles.middle}>
        {
          getPermissionAdminApp() || user?.role === 1 ? <CustomPopoverMenuItem text={translate('messages.addAdmin')} onClick={onShowAdminsModal}/> : null
        }
        <CustomPopoverMenuItem text={translate('operations.params.POPOVER.editSettings')} onClick={editRole} />
        {
          user?.role === 1
            ? 
            <>
              <CustomPopoverMenuItem text={translate('messages.addRole')} onClick={addRole}/>
              {
                pathname.includes('show')
                  ? null
                  : step?.step === 'move'
                    ? <CustomPopoverMenuItem
                      disable={step.id === record.appId}
                      text={translate('operations.params.APP.paste')}
                      onClick={() => setPaste(record)}
                    />
                    : null
              }
            </>
            : null
        }
      </div>
      {
        user?.role === 1 ? <div className={styles.bottom}>
          <CustomPopoverMenuItem text={translate('ra.action.delete')} onClick={onShowRemoveModal}/>
        </div> : null
      }
      <CustomModal
        open={showAdminsModal}
        close={onCloseAdminsModal}
      >
        <AddAdminsModal
          close={onCloseAdminsModal}
          group={record.adminGroup}
          record={record}
          context={{role: true, context: 'admin'}}
        />
      </CustomModal>
      <CustomModal
        open={showRemoveModal}
        close={onCloseRemoveModal}
      >
        <RemoveRoleModal
          closeModal={onCloseRemoveModal}
          record={record}
        />
      </CustomModal>
    </div>
  )
}

export default RoleAppMenu;