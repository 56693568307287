import React from "react";
import {useDataProvider, useGetIdentity, useLogout, useNotify, useRedirect, useTranslate} from "react-admin";
import { Card, CardContent } from '@mui/material';
import styles from './ConfirmEmailPage.module.css';
import CustomInput from "../../inputs/customInput/CustomInput";
import CancelButton from "../../buttons/cancelButton/CancelButton";
import CustomButton from "../../buttons/customButton/CustomButton";
import CustomHeader from "../../customHeader/CustomHeader";

const ConfirmEmailPage = () => {
  const { identity: profile } = useGetIdentity();
  const logout = useLogout();
  const providerData = useDataProvider();
  const initEmail = profile ? profile?.mail : '';
  const [email, setEmail] = React.useState(profile ? profile?.mail : '');
  const [code, setCode] = React.useState('');
  const [step, setStep] = React.useState('input');
	const translate = useTranslate();

  const onLogout = () => {
    logout();
  }

  const onChangeEmail = (v: string) => {
    setEmail(v);
  }

  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = (res: any) => {
    if (!res?.data?.mail)
      return setStep('confirm');
    else redirect('show', 'user', profile?.id);

    notify('ra.notification.updated', { type: 'success', messageArgs: { smart_count: 1 }, undoable: true });
  };

  const nextStep = () => {
    providerData.update('user', { id: profile!.id, data: { mail: email }, previousData: profile })
      .then(onSuccess)
      .catch(() => notify('ra.notification.canceled', { type: 'warning' }))
  }

  const confirmEmail = () => {
    providerData.create('confirmEmail', { data: { token: code }})
      .then(() => {
        redirect('show', 'user', profile?.id);
        notify('ra.notification.updated', {type: 'success', messageArgs: {smart_count: 1}, undoable: false})
      })
      .catch((error) => {
        notify(error.body.error === 'Token is invalid' ? 'ra.notification.codeError' : 'ra.notification.canceled', {type: 'warning'})
      });
  }

  const onChangeCode = (v: string) => {
    setCode(v);
  }
  const validateEmail = () => {
    return !Boolean(email?.length && email
      .toLowerCase()
      .match(
        // eslint-disable-next-line
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*){2,}|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]{2,}\.)+[a-zA-Z]{2,}))$/
      ));
  };

  return (
    <div>
      <CustomHeader sx={{
        '&.MuiPaper-root': {
          background: 'none',
          borderBottom: '1px solid #e5e5e5',
          boxShadow: 'none'
        }
      }}/>
      <div className={styles.root}>
        <header>
          {
            step === 'input' ? <h1>{translate('operations.params.EMAIL.addEmailAccess')}</h1> : <h1>{`${translate('operations.params.EMAIL.confirmEmail')} ${email}`}</h1>
          }
        </header>
        <Card>
          <CardContent>
            <div className={styles.row_item}>
              <span className={styles.row_item__label}>{step === 'input' ? translate('operations.params.EMAIL.enterEmailAddress') : translate('operations.params.EMAIL.enterTheSentCode')}</span>
              {
                step === 'input' ?
                  <CustomInput placeholder={translate('operations.params.EMAIL.enterYourEmailAddress')} mode={!validateEmail() || !email?.length ? 'root' : 'error'} value={email} changeInput={onChangeEmail} errorMessage={translate('operations.params.EMAIL.invalidFormat')} focus={false} /> :
                  <CustomInput placeholder={translate('operations.params.EMAIL.enterTheCode')} mode={'root'} value={code} changeInput={onChangeCode} errorMessage={''} focus={false} />
              }
              {
                step === 'input' && profile?.role !== 1 ? <span className={styles.row_item__label}>{translate('operations.params.EMAIL.confirmationCode')}</span> : null
              }
            </div>
            <div className={styles.buttons}>
              <CancelButton text={translate('ra.action.exit')} onClick={onLogout} />
              {
                step === 'input' ?
                  <CustomButton text={translate('ra.action.continue')} onClick={nextStep} disable={validateEmail() || initEmail === email}/> :
                  <CustomButton text={translate('ra.action.confirm')} onClick={confirmEmail} disable={!code.length}/>
              }
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

export default ConfirmEmailPage;