import React, { ReactElement } from "react";
import {
	List,
	Datagrid,
	BooleanField,
	FunctionField,
	TextField,
	RowClickFunction,
	SearchInput,
	FilterForm, useGetIdentity, NullableBooleanInput, SelectArrayInput, useListContext, useTranslate
} from "react-admin";
import styles from './AppAdminsPage.module.css';
import AvatarIcon from "../../../assets/avatar.png";
import CustomColumns from "../../buttons/customColumns/CustomColumns";
import RowActionsButton from "../../buttons/rowActionsButton/RowActionsButton";
import AdminAppMenu from '../../popovers/adminAppMenu/AdminAppMenu';
import { IApp, IProfile, IRole } from "../../../utils/types";
import { Stack } from "@mui/material";
import AddAdminButton from "../../buttons/addAdminButton/AddAdminButton";
import CustomModal from "../../modals/customModal/CustomModal";
import AddAdminsModal from "../../modals/addAdminsModal/AddAdminsModal";
import CustomBulkDeleteButton from "../../buttons/customBulkDeleteButton/CustomBulkDeleteButton";
import RemoveAccessModal from "../../modals/removeAccessModal/RemoveAccessModal";
import { USER_ROLES } from "../../../utils/constants";
import CustomFilterButton from "../../buttons/customFilterButton/CustomFilterButton";
import { downloadURI } from "../../../utils/utils";
import ReportsMenu from "../../popovers/reportsMenu/reportsMenu";
import customRuMessages from "../../../i18n/ru";

type AppAdminsPageProps = {
	app: IApp,
	role?: IRole | undefined,
	context: string,
}

type CustomListToolBarProps = {
	app: IApp,
	role?: IRole | undefined,
	onClickButton: () => void,
	context: string,
	showAddButton: boolean,
}

const userFilters = [
	<SearchInput name={'q'} source={'id'} alwaysOn />,
	<NullableBooleanInput source='userAccountControl' margin='none' fullWidth={true} sx={{ minWidth: 100 }}
		label="resources.operation.fields.success"
		nullLabel="operations.params.STATUS.any"
		falseLabel="operations.params.STATUS.disabled"
		trueLabel="operations.params.STATUS.active"
	/>,
	<NullableBooleanInput source='memberOfTeacherGroup' margin='none' fullWidth={true} sx={{ minWidth: 220 }}
		label="resources.user.fields.memberOfTeacherGroup"
		nullLabel="operations.params.STATUS.any"
	/>,
	<SelectArrayInput source="role" label={customRuMessages.resources.user.fields.role} fullWidth={true} sx={{ minWidth: 180 }} choices={USER_ROLES.filter(Boolean).map(name => ({ id: USER_ROLES.indexOf(name), name }))} />,
	<NullableBooleanInput source='accountExpired' margin='none' fullWidth={true} sx={{ minWidth: 200 }}
		label="resources.user.fields.accountExpires"
		nullLabel="operations.params.STATUS.any"
		falseLabel="resources.user.fields.unlimited"
		trueLabel="resources.user.fields.limited"
	/>,
]

const CustomListToolBar = (props: CustomListToolBarProps) => {
	const { app, role } = props;
	const { filterValues, filter } = useListContext();

	const uploadReport = (reportId: string) => {
		const reportFilter = JSON.stringify({ ...filter, ...filterValues });
		const condition = role?.id ? `&roleId=${role?.id}` : `&appId=${app?.id}`;

		downloadURI(`/api/report/${reportId}?filter=${reportFilter}${condition}&context=${context}&_t=${new Date().getTime()}`)
	}

	const { onClickButton, context, showAddButton } = props;
	const translate = useTranslate();
	return (
		<Stack direction="row" justifyContent="space-between" sx={{ width: "100%", alignItems: "center" }}>
			<FilterForm filters={userFilters} />
			<Stack direction="row" sx={{ alignItems: "center" }}>
				<div className={styles.toolbar}>
					<CustomFilterButton filters={userFilters} />
					{
						showAddButton ? <AddAdminButton onClick={onClickButton}
							text={context === 'admin' ? translate('messages.addAdmin') : translate('messages.addUser')} /> : null
					}
				</div>
				<ReportsMenu closePopover={uploadReport} />
			</Stack>
		</Stack>
	)
}

const AppAdminsPage = (props: AppAdminsPageProps) => {
	const translate = useTranslate();
	const { app, role, context } = props;
	const [userColumns, setUserColumns] = React.useState([
		{ source: 'displayName', label: translate('operations.params.USER.givenName'), active: true },
		{ source: 'userAccountControl', label: translate('operations.params.APPROVAL.status'), active: true },
		{ source: 'role', label: translate('operations.params.APPROVAL.type'), active: true },
		{ source: 'mail', label: translate('operations.params.USER.mail'), active: true },
		{ source: 'description', label: translate('operations.params.USER.dateOfBirth'), active: true },
		{ source: 'memberOf', label: translate('operations.params.USER.groups'), active: true },
		{ source: 'roles', label: translate('operations.entities.ROLE'), active: true },
		{ source: 'accountExpires', label: translate('messages.accountExpires'), active: false },
		{ source: 'whenCreated', label: translate('operations.params.APPROVAL.whenCreated'), active: true },
	])
	const [showAdminsModal, setShowAdminsModal] = React.useState(false);
	const { identity: user } = useGetIdentity();

	const onShowAdminsModal = () => {
		setShowAdminsModal(true);
	}

	const onCloseAdminsModal = () => {
		setShowAdminsModal(false);
	}

	const onChangeUserColumns = (id: string) => {
		setUserColumns(oldState => oldState.map(item => {
			if (item.source === id) item.active = !item.active;
			return item;
		}));
	}

	const onRowClick: RowClickFunction = (id) => {
		if (!role) return `/app/${app.id}/show/admin/${id}`;
		else if (role) return `/app/${app.id}/show/admin/${id}`;
		else return '/app';
	}
	
	const PostBulkActionButtons: ReactElement<any, string | React.JSXElementConstructor<any>> = (
		<Stack direction="row" justifyContent="space-between" sx={{ width: "100%", alignItems: "center" }}>
			<CustomBulkDeleteButton Modal={RemoveAccessModal} text={translate('messages.revokeAccess')} modalProps={{ context: { role: Boolean(role), context } }} />
		</Stack>
	);

	const EmptyState = () => (
		<div className={styles.empty}>
			<span>{translate('messages.emptyList')}</span>
			{
				getPermissionAdminApp() || (context === 'user' && getPermissionAdminRole()) || user?.role === 1 ?
				<AddAdminButton
					onClick={onShowAdminsModal}
					text={context === 'admin' ? translate('messages.addAdmin') : translate('messages.addUser')}
				/> : null
			}
		</div>
	)

	const getPermissionAdminApp = (): boolean => {
		return user?.memberOf && user?.memberOf.includes(app.adminGroup);
	}

	const getPermissionAdminRole = (): boolean => {
		return role ? user?.memberOf && user?.memberOf.includes(role.adminGroup) : false;
	}

	return (
		<div>
			<List
				actions={<CustomListToolBar app={app} role={role} onClickButton={onShowAdminsModal} context={context} showAddButton={getPermissionAdminApp() || (context === 'user' && getPermissionAdminRole()) || user?.role === 1} />}
				disableSyncWithLocation
				resource={role && context === 'user' ? 'userRole' : role && context === 'admin' ? 'adminRole' : 'adminApp'}
				sort={{ field: 'displayName', order: 'ASC' }}
				filter={{ memberOf: role && context === 'user' ? role.userGroup : role && context === 'admin' ? role.adminGroup : app.adminGroup }}
				exporter={false}
				empty={<EmptyState />}
				sx={{
					minWidth: "-webkit-fill-available",
					width: "-moz-available",
					overflow: "auto"
				}}
			>
				<Datagrid
					rowClick={onRowClick}
					bulkActionButtons={(role && getPermissionAdminApp()) || (context === 'user' && getPermissionAdminRole()) || user?.role === 1 ? PostBulkActionButtons : false}
					sx={{
						'& .RaDatagrid-headerCell': {
							borderColor: "#e0e0e0",
							backgroundColor: "#fff0",
						},
						'& .RaDatagrid-rowCell': {
							maxWidth: 200,
							'&.column-undefined': {
								width: 40,
								'& span': {
									display: "flex",
									alignItems: "center",
								},
							},
							'&.column-photo': {
								width: 20,
								paddingRight: 0,
							}
						},
					}}>
					{
						userColumns.filter(item => item.active).map(item => {
							switch (item.source) {
								case 'userAccountControl': return <BooleanField source={item.source} label={item.label} key={item.source} />;
								case 'role': return <FunctionField source={'role'} label={translate('operations.params.APPROVAL.type')} render={(record: IProfile) => <span>{USER_ROLES[record.role]}</span>} key={item.source} />;
								case 'memberOf': return <FunctionField source={'memberOf'} label={translate('operations.params.USER.groups')} render={(record: any) => record.memberOf && record.memberOf.length ? <div className={styles.chips}>{[record.memberOf[0], record.memberOf.length < 2 ? null : `${translate('messages.still')} ${record.memberOf.length - 1}`].filter(Boolean).map((item: any, i) => <div className={i > 0 ? styles.more_chip : styles.chip} key={item}>{item}</div>)}</div> : null} key={item.source} />;
								case 'roles': return <FunctionField source={'roles'} label={translate('operations.entities.ROLE')} render={(record: any) => record.roles && record.roles.length ? <div className={styles.chips}>{[record.roles[0].name, record.roles.length < 2 ? null : `${translate('messages.still')} ${record.roles.length - 1}`].filter(Boolean).map((item: any, i) => <div className={i > 0 ? styles.more_chip : styles.chip} key={item}>{item}</div>)}</div> : null} key={item.source} />;
								case 'displayName': return <FunctionField source={'displayName'} label={translate('operations.params.USER.givenName')} render={(record: any) => <div className={styles.name}><img className={styles.avatar} src={record.photo && record.photo.length ? record.photo : AvatarIcon} alt="" /><span>{record?.displayName || `${record.sn ?? ''} ${record.givenName}` || record.id}</span></div>} key={item.source} />;
								default: return <TextField source={item.source} label={item.label} key={item.source} />
							}
						}
						)
					}
					<FunctionField
						label={<CustomColumns columns={userColumns} onChange={onChangeUserColumns} />}
						render={(record: any) => !record.type && ((role && getPermissionAdminApp()) || (context === 'user' && getPermissionAdminRole()) || user?.role === 1) ? <RowActionsButton menuProps={{ record: record, context: { role: Boolean(role), context }, isAdmin: role && getPermissionAdminApp() }} Menu={AdminAppMenu} /> : null}
						sx={{ maxWidth: 40 }}
					/>
				</Datagrid>
			</List>
			<CustomModal
				open={showAdminsModal}
				close={onCloseAdminsModal}
			>
				<AddAdminsModal
					close={onCloseAdminsModal}
					group={role && context === 'user' ? role.userGroup : role && context === 'admin' ? role.adminGroup : app.adminGroup}
					context={{ role: Boolean(role), context }}
					record={role || app}
				/>
			</CustomModal>
		</div>
	)
}

export default AppAdminsPage;