import React from "react";
import {
  Menu,
  MenuItemLink,
  useGetIdentity,
  useSidebarState,
  useGetList,
  useGetOne,
  useTranslate
} from 'react-admin';
import ProfileIcon from "../../../assets/user3.png";
import AppsIcon from '../../../assets/Widgets.png';
import AddMenuButton from "../../buttons/addMenuButton/AddMenuButton";
import UsersIcon from '../../../assets/group.png';
import ApprovalIcon from '../../../assets/approval.png';
import TocIcon from '@mui/icons-material/Toc';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import 'devextreme/dist/css/dx.light.css';
// import TreeView from 'devextreme-react/tree-view';
import './CustomMenu.css';
import { APP_VERSION } from "../../../utils/constants";
import { fetchProvider } from "../../../provider/authProvider";

const CustomMenu = () => {
  const [open] = useSidebarState();
  const [approvals, setApprovals] = React.useState<number>();
  const [versionApi, setVersionApi] = React.useState<string>();
  const [copyrightText, setCopyrightText] = React.useState<string>();
  const { identity: user } = useGetIdentity();
  const translate = useTranslate();

  useGetList('approval', { filter: { status: 'pending' }, pagination: { page: 1, perPage: 1000 } }, {
    onSuccess: (data) => {
      setApprovals(data.total);
    },
    onError: () => false
  })

  useGetOne('settings', { id: 'api' }, {
    onSuccess: (data) => {
      setVersionApi(data.version);
    }
  })

  useGetOne('settings', { id: 'app' }, {
    onSuccess: (data) => {
      setCopyrightText(data.settings.APP_COPYRIGHT);
    }
  })

  const MenuItemApprovals = () => (
    <div className={'menu_item'}>
      <span>{translate('operations.entities.APPROVAL')}</span>
      {
        approvals && approvals > 0 ?
          <div className={'menu_item__count'}>
            <span>{approvals}</span>
          </div> : null
      }
    </div>
  )

  const ApprovalsMinIcon = () => (
    <div className={'approvals_icon'}>
      <img src={ApprovalIcon} alt="" />
      <div className={'approvals_icon__count'}>
        <span>{approvals}</span>
      </div>
    </div>
  )

  if (!user) return null;

  // function  createChildren(parent:any) {
  //   const parentId = parent ? parent.itemData.id : '.';
  //   return fetch(`/api/user?filter={"parentId":"${parentId}"}`)
  //     .then((response) => response.json().then(res => {
  //       return res.map((item: any) => {
  //         const newItem = {...item};
  //         newItem.text = item.givenName;
  //         newItem.hasItems = Boolean(item.type);
  //         return newItem;
  //       })}))
  //     .catch(() => { throw new Error('Data Loading Error'); });
  // }

  // const TreeItem = (item: any) => {
  //   return item.id === '.' ? <MenuItemLink to={'/user'} primaryText={open ? 'Пользователи' : ''} leftIcon={<img src={UsersIcon} alt="" />} sx={{ paddingLeft: "12px" }} /> :
  //     <MenuItemLink to={`/user/${item.id}/show`} primaryText={item.text} sx={{ paddingLeft: "24px" }} />
  // }

  return (
    <div className="menu_wrapper">
      <Menu>
        <AddMenuButton isCollapse={!open} user={user} />
        <MenuItemLink to={`/profile/${user?.id}/show`} primaryText={open ? translate('operations.entities.MYPROFILE') : ''} leftIcon={<img src={ProfileIcon} alt="" />} sx={{ paddingLeft: "24px" }} />
        <MenuItemLink to={'/app'} primaryText={open ? translate('operations.entities.APP') : ''} leftIcon={<img src={AppsIcon} alt="" />} sx={{ paddingLeft: "24px" }} />
        <MenuItemLink to={'/approval'} primaryText={open ? <MenuItemApprovals /> : ''} leftIcon={open ? <img src={ApprovalIcon} alt="" /> : <ApprovalsMinIcon />} sx={{ paddingLeft: "24px" }} />
        {user.role === 1 ?
          <>
            <div className={'line'} />
            <MenuItemLink to={'/user'} primaryText={open ? translate('operations.entities.USER') : ''} leftIcon={<img src={UsersIcon} alt="" />} sx={{ paddingLeft: "24px" }} />
            <MenuItemLink to={'/operation'} primaryText={open ? translate('operations.entities.EVENTS') : ''} leftIcon={<TocIcon />} sx={{ paddingLeft: "24px" }} />
          </>
          :
          null
        }
        {/*<TreeView*/}
        {/*  id="custom-treeview"*/}
        {/*  dataStructure="plain"*/}
        {/*  rootValue="."*/}
        {/*  height={500}*/}
        {/*  expandNodesRecursive={false}*/}
        {/*  createChildren={createChildren}*/}
        {/*  itemRender={TreeItem}*/}
        {/*/>*/}
      </Menu>
      {
        open ?
          <div className="versions">
            {user?.role === 1 || user?.isAppAdmin || user?.isRoleAdmin ? <a href="#" onClick={async (e) => { e.preventDefault(); await fetchProvider('/api/ldap/refresh') }}><AutorenewIcon /> </a> : null}
            <span>{translate('organisation.language')}</span>
            <span>{`${translate('organisation.lkVersion')} ${APP_VERSION}`}</span>
            <span>{`${translate('organisation.apiVersion')} ${versionApi}`}</span>
            <span>{copyrightText}</span>
          </div> : null
      }
    </div>
  )
}

export default CustomMenu;