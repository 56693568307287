import React from "react";
import styles from './UnlockUserModal.module.css';
import CloseIcon from '../../../assets/close.png';
import CancelButton from "../../buttons/cancelButton/CancelButton";
import CustomButton from "../../buttons/customButton/CustomButton";
import CustomRadioButton from "../../inputs/customRadioButton/CustomRadioButton";
import CustomDatePicker from "../../inputs/customDatePicker/CustomDatePicker";
import { useTranslate } from 'react-admin';
import CustomTextArea from '../../inputs/customTextArea/CustomTextArea';

type UnlockUserModalProps = {
	closeModal: () => void,
	onUnlockUser: (v: {
		limit: boolean,
		date: Date | null,
		comment: string
	}) => void,
	names: string | string[],
}

const UnlockUserModal = (props: UnlockUserModalProps) => {
	const { onUnlockUser, closeModal, names } = props;
	const [limit, setLimit] = React.useState(false);
	const [limitDate, setLimitDate] = React.useState<Date | null>(null);
	const [step, setStep] = React.useState('creating');
	const [comment, setComment] = React.useState('');
	const translate = useTranslate();

	const onChangeCause = (v: string) => {
		setComment(v);
	}

	const onComeBack = () => {
		setStep('creating');
	}

	const onComeForvard = () => {
		setStep('creating_next');
	}

	const onChangeLimit = (v: string) => {
		setLimit(v === translate('messages.limitAccountExpiration'));
	}

	const onChangeLimitDate = (v: Date | null) => {
		setLimitDate(v);
	}

	return (
		step === 'creating' ?
			<div className={styles.root}>
				<div className={styles.close_button} onClick={closeModal}>
					<img src={CloseIcon} alt="" />
				</div>
				<h1 className={styles.header}>{translate('messages.unblockUser')}</h1>
				<span className={styles.text}>{`${translate('messages.unblock')} ${Array.isArray(names) ? [translate('messages.multipleUsers')] : [translate('messages.fromUser')]} ${Array.isArray(names) ? `(${names.length})` : names}?`}</span>
				<div className={styles.limits}>
					<CustomRadioButton
						list={[translate('messages.notLimitAccountExpiration'), translate('messages.limitAccountExpiration')]}
						value={limit ? translate('messages.limitAccountExpiration') : translate('messages.notLimitAccountExpiration')}
						changeValue={onChangeLimit}
						directionHorizontal={false}
					/>
				</div>
				{
					limit ?
						<div className={styles.input_date}>
							<span>{translate('messages.accountIsValidUntil')}</span>
							<CustomDatePicker onChangeDate={onChangeLimitDate} date={limitDate ?? new Date()} />
						</div> : null
				}
				<div className={styles.buttons}>
					<CancelButton text={translate('ra.action.cancel')} onClick={closeModal} />
					<CustomButton text={translate('ra.action.unblock')} onClick={onComeForvard} />
				</div>
			</div> :
			<div className={styles.root}>
				<h1 className={styles.header}>{translate('messages.inputReason')}</h1>
				<CustomTextArea placeholder={translate('messages.commentText')} mode={'root'} value={comment} changeInput={onChangeCause} focus={false} limit={255} />
				<div className={styles.buttons_wrapper}>
					<CancelButton text={translate('ra.action.comeback')} onClick={onComeBack} />
					<div className={styles.buttons_textarea}>
						<CancelButton text={translate('ra.action.cancel')} onClick={closeModal} />
						<CustomButton text={translate('ra.action.confirm')} onClick={() => onUnlockUser({ limit: limit, date: limit ? limitDate : null, comment: comment })} />
					</div>
				</div>
			</div>
	)
}

export default UnlockUserModal;