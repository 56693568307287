import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useGetIdentity, useTranslate } from "react-admin";
import { Card, CardContent } from '@mui/material';
import styles from './PasswordCard.module.css';
import CustomTooltip from "../../tooltips/customTooltip/CustomTooltip";
import EditIcon from "../../../assets/Edit.png";
import { IProfile } from "../../../utils/types";

type PasswordCardProps = {
  profile: IProfile
}


const PasswordCard = (props: PasswordCardProps) => {
  const { profile } = props;
  const navigate = useNavigate();
  const translate = useTranslate();
  const { identity: user } = useGetIdentity();
  const editPassword = () => {
    navigate(`/user/${profile.id}/edit/pwd`);
  }
  return (
    <Card>
      <CardContent>
        <div className={styles.root}>
          <h1 className={styles.header}>{translate('operations.params.PWD.unicodePwd')}</h1>
          <span className={styles.date}>{profile.pwdLastSet}</span>
          {
            user?.id === profile.id ? <div className={styles.edit_icon} onClick={editPassword}>
              <CustomTooltip title={translate('ra.action.edit')}>
                <img src={EditIcon} alt="icon" />
              </CustomTooltip>
            </div> : null
          }
        </div>
      </CardContent>
    </Card>
  )
};

export default PasswordCard;