import React from "react";
import styles from './UserRolesMenu.module.css';
import CustomPopoverMenuItem from "../customPopoverMenuItem/CustomPopoverMenuItem";
import CustomModal from "../../modals/customModal/CustomModal";
import RemoveRoleAccessModal from "../../modals/removeRoleAccessModal/RemoveRoleAccessModal";
import {IProfile} from "../../../utils/types";
import { useTranslate } from "react-admin";

type UserRolesMenuProps = {
  closePopover: () => void
  record?: any,
  user: IProfile
}

const UserRolesMenu = (props: UserRolesMenuProps) => {
  const { closePopover, record, user } = props;
	const translate = useTranslate();
  const [showAdminsModal, setShowAdminsModal] = React.useState(false);
  const onClickWrapper = (e: any) => {
    e.stopPropagation();
  }

  const onShowAdminsModal = () => {
    setShowAdminsModal(true);
  }

  const onCloseAdminsModal = () => {
    setShowAdminsModal(false);
    closePopover();
  }

  return (
    <div className={styles.root} onClick={onClickWrapper}>
      <div className={styles.middle}>
        <CustomPopoverMenuItem text={translate('messages.revokeAccess')} onClick={onShowAdminsModal} />
      </div>
      <CustomModal
        open={showAdminsModal}
        close={onCloseAdminsModal}
      >
        <RemoveRoleAccessModal closeModal={onCloseAdminsModal} record={record} user={user}/>
      </CustomModal>
    </div>
  )
}

export default UserRolesMenu;