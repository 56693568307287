import React, { useEffect } from "react";
import { useTranslate } from "react-admin";
import styles from './ConfirmApprovalModal.module.css';
import CloseIcon from "../../../assets/close.png";
import CancelButton from "../../buttons/cancelButton/CancelButton";
import CustomButton from "../../buttons/customButton/CustomButton";
import CustomTextArea from '../../inputs/customTextArea/CustomTextArea';
import {transformRoleName} from "../../../utils/utils";

type ConfirmApprovalModalProps = {
	role: string,
	closeModal: () => void,
	onApprove: (v: string) => void,
	adminsApp: string[],
	adminsRole: string[],
	usersRole: string[]
}

const ConfirmApprovalModal = (props: ConfirmApprovalModalProps) => {
	const { role, onApprove, adminsRole, adminsApp, usersRole, closeModal } = props;
	const translate = useTranslate();
	const [step, setStep] = React.useState('creating');
	const [comment, setComment] = React.useState('');

	useEffect(() => {
		if (
			!usersRole.length &&
			!adminsApp.length &&
			!adminsRole.length
		) { setStep('comment') }
		//eslint-disable-next-line
	}, [])

	const onChangeCause = (v: string) => {
		setComment(v);
	}

	const onConfirm = () => {
		onApprove(comment);
		closeModal();
	}

	const onComeBack = () => {
		setStep('creating');
	}

	const onComeForvard = () => {
		setStep('comment');
	}

	return (
		step === 'creating' ?
			<div className={styles.root}>
				<div className={styles.close_button} onClick={closeModal}>
					<img src={CloseIcon} alt="" />
				</div>
				<span className={styles.title}>{translate('messages.giveAccess')}</span>
				<span className={styles.text}>{`${translate('messages.assigningAccessTo')} ${role} ${translate('messages.userWillHaveAccessTo')}`}</span>
				<ul className={styles.list}>
					{usersRole.length ? usersRole.map((item: string) => <li className={styles.list_item} key={item}>{transformRoleName(item, 58)}</li>) : null}
					{adminsApp.length ? adminsApp.map((item: string) => <li className={styles.list_item} key={item}>{`${translate('messages.appAdministration')} ${transformRoleName(item, 58)}`}</li>) : null}
					{adminsRole.length ? adminsRole.map((item: string) => <li className={styles.list_item} key={item}>{`${translate('messages.roleAdministration')} ${transformRoleName(item, 58)}`}</li>) : null}
				</ul>
				<div className={styles.buttons}>
					<CancelButton text={translate('ra.action.cancel')} onClick={closeModal} />
					<CustomButton text={translate('ra.action.confirm')} onClick={onComeForvard} />
				</div>
			</div> : (
				<div className={styles.root}>
					<h1 className={styles.header}>{translate('messages.inputReason')}</h1>
					<CustomTextArea placeholder={translate('messages.commentText')} mode={'root'} value={comment} changeInput={onChangeCause} focus={false} limit={255} />
					<div className={styles.buttons_wrapper}>
						{
							usersRole.length || adminsApp.length || adminsRole.length ? <CancelButton text={translate('ra.action.comeback')} onClick={onComeBack} /> : null
						}
						<div className={styles.buttons}>
							<CancelButton text={translate('ra.action.cancel')} onClick={closeModal} />
							<CustomButton text={translate('ra.action.confirm')} onClick={onConfirm} />
						</div>
					</div>
				</div>
			)
	)
}

export default ConfirmApprovalModal;