import React from "react";
import styles from './CustomButton.module.css';

type CustomButtonProps = {
  iconSrc?: any,
  text: string,
  onClick: () => void,
  disable?: boolean,
}

  const CustomButton = (props: CustomButtonProps) => {
  const {onClick, text, iconSrc, disable} = props;
  return (
    <button className={styles.button} onClick={onClick} disabled={disable}>
      {iconSrc ? <img className={styles.icon} src={iconSrc} alt={''}/> : null}
      <span className={styles.text}>{text}</span>
    </button>
  )
}

export default CustomButton;