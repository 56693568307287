import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardContent } from '@mui/material';
import styles from './EditPhonePage.module.css';
import CustomTooltip from "../../tooltips/customTooltip/CustomTooltip";
import backIcon from "../../../assets/Back.png";
import CustomInput from "../../inputs/customInput/CustomInput";
import CancelButton from "../../buttons/cancelButton/CancelButton";
import CustomButton from "../../buttons/customButton/CustomButton";
import { IProfile } from "../../../utils/types";
import { useNotify, useRefresh, useTranslate, useUpdate } from "react-admin";

type EditPhonePageProps = {
  profile: IProfile
}

const EditPhonePage = (props: EditPhonePageProps) => {
  const { profile } = props;
  const navigate = useNavigate();
  const formatPhone = (v: string) => {
    if (v) {
      const newPhone = v.slice(-10);
      return `+7 (${newPhone.slice(0, 3)}) ${newPhone.slice(3, 6)}-${newPhone.slice(6, 8)}-${newPhone.slice(8, 10)}`
    } else return '+7 (';
  }
  const initPhone = profile ? formatPhone(profile?.mobile!) : '+7 (';
  const [phone, setPhone] = React.useState(profile ? formatPhone(profile?.mobile!) : '+7 (');
  const [validPhone, setValidPhone] = React.useState('root');
  const notify = useNotify();
  const refresh = useRefresh();
	const translate = useTranslate();

  const deformatPhone = (v: string) => {
    return v.replace(/\(/g, '')
      .replace(/ /g, '')
      .replace(/\)/g, '')
      .replace(/-/g, '');
  }

  const [updatePhone] = useUpdate(
    'user',
    {
      id: profile?.id,
      data: { mobile: deformatPhone(phone) }
    },
    {
      mutationMode: 'undoable',
      onSuccess: () => {
        notify('ra.notification.updated', { type: 'success', messageArgs: { smart_count: 1 }, undoable: true });
        onComeBack();
        refresh();
      },
      onError: () => notify('ra.notification.canceled', { type: 'warning' })
    }
  );

  const onComeBack = () => {
    navigate(-1);
  }

  const onChangePhone = (value: string) => {
    let handlePhone = value
      .replace(/\(/g, '')
      .replace(/ /g, '')
      .replace(/\)/g, '')
      .replace(/-/g, '');
    if (handlePhone.length < 13 && /^[+]\d+$/.test(handlePhone)) {
      if (handlePhone.length && handlePhone.length < 3) {
        setPhone(`${handlePhone} `);
        setValidPhone('error');
      } else if (handlePhone.length && handlePhone.length === 3) {
        setPhone(`${handlePhone.slice(0, 2)} (${handlePhone.slice(2, 3)}`);
        setValidPhone('error');
      } else if (handlePhone.length && handlePhone.length < 6) {
        setPhone(`${handlePhone.slice(0, 2)} (${handlePhone.slice(2, 5)}`);
        setValidPhone('error');
      } else if (handlePhone.length && handlePhone.length === 6) {
        setPhone(
          `${handlePhone.slice(0, 2)} (${handlePhone.slice(
            2,
            5
          )}) ${handlePhone.slice(5, 6)}`
        );
        setValidPhone('error');
      } else if (handlePhone.length && handlePhone.length < 9) {
        setPhone(
          `${handlePhone.slice(0, 2)} (${handlePhone.slice(
            2,
            5
          )}) ${handlePhone.slice(5, 8)}`
        );
        setValidPhone('error');
      } else if (handlePhone.length && handlePhone.length === 9) {
        setPhone(
          `${handlePhone.slice(0, 2)} (${handlePhone.slice(
            2,
            5
          )}) ${handlePhone.slice(5, 8)}-${handlePhone.slice(8, 9)}`
        );
        setValidPhone('error');
      } else if (handlePhone.length && handlePhone.length < 11) {
        setPhone(
          `${handlePhone.slice(0, 2)} (${handlePhone.slice(
            2,
            5
          )}) ${handlePhone.slice(5, 8)}-${handlePhone.slice(8, 10)}`
        );
        setValidPhone('error');
      } else if (handlePhone.length && handlePhone.length === 11) {
        setPhone(
          `${handlePhone.slice(0, 2)} (${handlePhone.slice(
            2,
            5
          )}) ${handlePhone.slice(5, 8)}-${handlePhone.slice(
            8,
            10
          )}-${handlePhone.slice(10, 12)}`
        );
        setValidPhone('error');
      } else if (handlePhone.length && handlePhone.length < 13) {
        setPhone(
          `${handlePhone.slice(0, 2)} (${handlePhone.slice(
            2,
            5
          )}) ${handlePhone.slice(5, 8)}-${handlePhone.slice(
            8,
            10
          )}-${handlePhone.slice(10, 12)}`
        );
        setValidPhone('root');
      }
    }
  }

  const savePhone = () => {
    updatePhone()
  }

  const isInvalid = () => !phone?.length || validPhone === 'error' || initPhone === phone;

  return (
    <div className={styles.wrapper}>
      <div className={styles.root}>
        <header>
          <CustomTooltip title={translate('ra.action.comeback')}>
            <div className={styles.back_icon} onClick={onComeBack}>
              <img src={backIcon} alt="icon" />
            </div>
          </CustomTooltip>
          <h1>{profile?.mobile && profile?.mobile.length ? translate('operations.params.PHONE.changePhoneNumber') : translate('operations.params.PHONE.addPhoneNumber')}</h1>
        </header>
        <Card>
          <CardContent>
            <div className={styles.row_item}>
              <span className={styles.row_item__label}>{translate('operations.params.PHONE.enterThePhoneNumber')}</span>
              <CustomInput placeholder={translate('operations.params.PHONE.enterYourPhoneNumber')} mode={validPhone} value={phone} changeInput={onChangePhone} errorMessage={''} focus={false} />
            </div>
            <div className={styles.buttons}>
              <CancelButton text={translate('ra.action.abort')} onClick={onComeBack} />
              <CustomButton text={translate('ra.action.save')} onClick={savePhone} disable={isInvalid()}/>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

export default EditPhonePage;