import React from "react";
import {UserMenu, UserMenuProps, useLogout} from 'react-admin';
import LogoutButton from "../../buttons/logoutButton/LogoutButton";

const ProfileMenu = (props: UserMenuProps) => {
  const logout = useLogout();
  const onLogout = () => {
    logout();
  }
  return (
    <UserMenu {...props}>
      <LogoutButton onClick={onLogout} />
    </UserMenu>
  )
}

export default ProfileMenu;