import React from "react";
import { Dialog } from "@mui/material";
import './CustomModal.css';
import {dispatchCloseDatePickerEvent} from "../../../utils/utils";

type CustomModalProps = {
  open: boolean,
  close: (v: boolean) => void,
  children: JSX.Element,
}

const CustomModal = (props: CustomModalProps) => {
  const {open, close, children} = props;

  const closeModal = () => {
    close(false);
  };

  const onClickInsideModal = (e: any) => {
    if (!e.target.closest('.MuiCalendarPicker-root'))  dispatchCloseDatePickerEvent();}

  return (
    <Dialog
      open={open}
      onClose={closeModal}
    >
      <div onClick={onClickInsideModal}>
        {children}
      </div>
    </Dialog>
  )
}

export default CustomModal;