import React from "react";
import {useNavigate, useLocation, useParams} from 'react-router-dom';
import { Card, CardContent } from '@mui/material';
import {
  useDataProvider,
  useNotify,
  useRedirect,
  useGetOne,
  useUpdate, useRefresh, useGetIdentity, useTranslate
} from "react-admin";
import styles from './CreateRolePage.module.css';
import CustomTooltip from "../../tooltips/customTooltip/CustomTooltip";
import backIcon from '../../../assets/Back.png';
import CustomInput from "../../inputs/customInput/CustomInput";
import CancelButton from "../../buttons/cancelButton/CancelButton";
import CustomButton from "../../buttons/customButton/CustomButton";
import SaveIcon from '../../../assets/Save.png';
import CustomTextArea from "../../inputs/customTextArea/CustomTextArea";
import CustomInputGroup from "../../inputs/customInputGroup/CustomInputGroup";

const CreateRolePage = () => {
  const dataProvider = useDataProvider();
  const isCreate = useLocation().pathname.includes('create');
  let id = useLocation().pathname.split('role/')[1].split('/')[0];
  id = id === 'create' ? '' : id;
  const appID = useParams().id;
  useGetOne('role', {id}, {
    onSuccess: (data) => {
      if (Array.isArray(data)) return false
      else setRole(data)
    },
    onError: () => false,
    retry: false,
  });
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const [role, setRole]:any = React.useState(null);
  const [name, setName] = React.useState(role && role.name ? role.name : '');
  const [description, setDescription] = React.useState(role && role.description ? role.description : '');
  const [appId, setAppId] = React.useState(role && role.appId ? role.appId : '');
  const [parentId, setParentId] = React.useState(role && role.parentId ? role.parentId : 0);
  const [selectedAdminGroup, setSelectedAdminGroup] = React.useState(role && role.adminGroup ? role.adminGroup : '');
  const [selectedUserGroup, setSelectedUserGroup] = React.useState(role && role.userGroup ? role.userGroup : '');
  const navigate = useNavigate();
  const { identity: user } = useGetIdentity();
	const translate = useTranslate();

  const [updateRole] = useUpdate(
    'role',
    {
      id: id,
      data: {
        name,
        description,
        adminGroup: selectedAdminGroup,
        userGroup: selectedUserGroup,
        owner: 'admin',
      }
    },
    {
      mutationMode: 'undoable',
      onSuccess: () => {
        notify('ra.notification.updated', { type: 'success', messageArgs: { smart_count: 1 }, undoable: true });
        redirect('show', 'role', id);
        refresh();
      },
      onError: () => notify('ra.notification.canceled', { type: 'warning' })
    }
  );

  const onChangeName = (v: string) => {
    setName(v);
  }

  const onChangeDescription = (v: string) => {
    setDescription(v);
  }

  const onComeBack = () => {
    navigate(-1);
  }

  const onSuccess = (id: number) => {
    notify('ra.notification.updated', { type: 'success', messageArgs: { smart_count: 1 }, undoable: true });
    redirect('show', 'role', id);
  };

  const onCreateRole = async () => {
    if (role && !isCreate) {
      updateRole()
    } else {
      const data: any = {
        name,
        description,
        adminGroup: selectedAdminGroup,
        userGroup: selectedUserGroup,
        owner: 'admin',
        appId,
        parentId,
      }
      dataProvider.create('role', {data: data})
        .then(({data}) => onSuccess(data.id))
        .catch(() => notify('ra.notification.canceled', { type: 'warning' }))
    }
  }

  React.useEffect(() => {
    if (role && !isCreate) {
      setName(role.name);
      setDescription(role.description);
      setAppId(role.appId);
      setParentId(role.parentId);
      setSelectedAdminGroup(role.adminGroup);
      setSelectedUserGroup(role.userGroup);
      setParentId(role.logo);
    } else if (role) {
      setAppId(role.appId);
      setParentId(role.id);
    }
  }, [role, isCreate])

  React.useEffect(() => {
    if (appID) setAppId(appID);
  },[appID])

  return (
    <div>
      <div className={styles.root}>
        {
          role && !isCreate ?
            null :
            <header>
              <CustomTooltip title={translate('ra.action.comeback')}>
                <div className={styles.back_icon} onClick={onComeBack}>
                  <img src={backIcon} alt="icon" />
                </div>
              </CustomTooltip>
              <h1>{translate('messages.addRole')}</h1>
            </header>
        }
        <Card>
          <CardContent>
            <h4>{translate('operations.params.ROLE.roleParameters')}</h4>
            <div className={styles.row_item}>
              <span className={styles.row_item__label}>{translate('operations.params.ROLE.roleName')}<span style={{ color: 'red' }}>*</span></span>
              <CustomInput placeholder={translate('operations.params.APP.enterName')} mode={name.length <= 50 ? 'root' : 'error'} value={name} changeInput={onChangeName} errorMessage={translate('operations.params.ROLE.nameLimit')} focus={false} />
            </div>
            <div className={styles.row_item}>
              <span className={styles.row_item__label}>{translate('operations.params.ROLE.roleDescription')}</span>
              <CustomTextArea placeholder={translate('operations.params.APP.enterText')} mode={'root'} value={description} changeInput={onChangeDescription} focus={false} limit={255}/>
            </div>
            {
              (role && !isCreate && user?.role === 1) || isCreate ? <div>
                <h4>{translate('operations.params.APP.connectingGroupOfAdministrators')}</h4>
                <div className={styles.row_item}>
                  <span className={styles.row_item__label}>{translate('operations.params.APP.membersWillManageTheApplication')}</span>
                </div>
                <div className={styles.row_item}>
                  <CustomInputGroup selected={selectedAdminGroup} onChange={setSelectedAdminGroup}/>
                </div>
                <h4>{translate('operations.params.APP.connectingUserGroup')}</h4>
                <div className={styles.row_item}>
                  <span className={styles.row_item__label}>{translate('operations.params.APP.membersWillUseTheRole')}</span>
                </div>
                <div className={styles.row_item}>
                  <CustomInputGroup selected={selectedUserGroup} onChange={setSelectedUserGroup}/>
                </div>
              </div> : null
            }
            <div className={styles.buttons}>
              <CancelButton text={translate('ra.action.abort')} onClick={onComeBack}/>
              <CustomButton text={translate('ra.action.save')} onClick={onCreateRole} iconSrc={SaveIcon} disable={(!name.length || name.length > 50) || ((!selectedAdminGroup || !selectedUserGroup) && (isCreate || role) && user?.role === 1)}/>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

export default CreateRolePage;