import React from "react";
import styles from './CancelButton.module.css';

type CancelButtonProps = {
  text: string,
  onClick: () => void,
}

const CancelButton = (props: CancelButtonProps) => {
  const {text, onClick} = props;
  return (
    <button className={styles.button} onClick={onClick}>
      {text}
    </button>
  )
}

export default CancelButton;