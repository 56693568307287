import React from "react";
import { useGetIdentity, useRefresh, useTranslate } from "react-admin";
import styles from './ActionsAppMenu.module.css';
import CustomPopoverMenuItem from "../customPopoverMenuItem/CustomPopoverMenuItem";
import CustomModal from "../../modals/customModal/CustomModal";
import { useLocation, useNavigate } from "react-router-dom";
import RemoveAppModal from "../../modals/removeAppModal/RemoveAppModal";
import AddAdminsModal from "../../modals/addAdminsModal/AddAdminsModal";
import { IApp } from "../../../utils/types";

type ActionsAppMenuProps = {
	closePopover: () => void
	record: IApp,
	onPasteApp: (pasteApp: IApp) => void,
	showModal: (text: string) => void,
	onSavedMoving: (pasteApp: boolean) => void
}

const ActionsAppMenu = (props: ActionsAppMenuProps) => {
	const { record, onPasteApp, showModal, closePopover, onSavedMoving } = props;
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { identity: user } = useGetIdentity();
	const [showAdminsModal, setShowAdminsModal] = React.useState(false);
	const [showRemoveModal, setShowRemoveModal] = React.useState(false);
	const step = JSON.parse(localStorage.getItem('moving')!);
	const refresh = useRefresh();

	const translate = useTranslate();

	const onClickWrapper = (e: any) => {
		e.stopPropagation();
	}

	const editApp = () => {
		navigate(`/app/${record.id}/edit`);
	}

	const addRole = () => {
		navigate(`/app/${record.id}/show/role/create`);
	}

	const onShowAdminsModal = () => {
		setShowAdminsModal(true);
	}

	const onCloseAdminsModal = () => {
		setShowAdminsModal(false);
		closePopover();
	}

	const onShowRemoveModal = () => {
		setShowRemoveModal(true);
	}

	const onCloseRemoveModal = () => {
		setShowRemoveModal(false);
		closePopover();
	}
	const setMove = (record:IApp) => {
		let moving = {
			step: 'move',
			id: record.id,
			name: record.name
		}
		localStorage.setItem('moving', JSON.stringify(moving));
		onSavedMoving(true)
		closePopover();
	}

	const setPaste = (record:IApp) => {
		if(step.id === record.id) {
			return
		} else {
		onPasteApp(record);
		refresh();
		showModal('pasteApp');
	}
	}

	return (
		<div className={styles.root} onClick={onClickWrapper}>
			<div className={styles.middle}>
				<CustomPopoverMenuItem text={translate('messages.addAdmin')} onClick={onShowAdminsModal} />
				<CustomPopoverMenuItem text={translate('operations.params.POPOVER.editSettings')} onClick={editApp} />
				{
					user?.role === 1
						?
							<>
								<CustomPopoverMenuItem text={translate('messages.addRole')} onClick={addRole} />
								{
									pathname.includes('show')
									? null
									: step?.step === 'move'
										?<CustomPopoverMenuItem
											disable={step.id === record.id}
											text={translate('operations.params.APP.paste')}
											onClick={() => setPaste(record)}
										/>
										: <CustomPopoverMenuItem text={translate('operations.params.APP.move')} onClick={() => setMove(record)} />
								}
							</>
						: null
				}
			</div>
			{
				user?.role === 1 ? <div className={styles.bottom}>
					<CustomPopoverMenuItem text={translate('ra.action.delete')} onClick={onShowRemoveModal} />
				</div> : null
			}
			<CustomModal
				open={showAdminsModal}
				close={onCloseAdminsModal}
			>
				<AddAdminsModal
					close={onCloseAdminsModal}
					group={record.adminGroup}
					record={record}
					context={{ role: false, context: 'admin' }}
				/>
			</CustomModal>
			<CustomModal
				open={showRemoveModal}
				close={onCloseRemoveModal}
			>
				<RemoveAppModal
					closeModal={onCloseRemoveModal}
					record={record}
				/>
			</CustomModal>
		</div>
	)
}

export default ActionsAppMenu;