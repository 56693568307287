import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardContent } from '@mui/material';
import { useTranslate } from "react-admin";
import styles from './EmailCard.module.css';
import AddButton from "../../buttons/addButton/AddButton";
import DotsMenuButton from "../../buttons/dotsMenuButton/DotsMenuButton";
import { IProfile } from "../../../utils/types";

type EmailCardProps = {
  profile: IProfile,
  editable: boolean
}

const EmailCard = (props: EmailCardProps) => {
  const { profile, editable } = props;
  const navigate = useNavigate();
  const translate = useTranslate();

  const editEmail = () => {
    navigate(`/user/${profile.id}/edit/email`);
  }

  return (
    <Card>
      <CardContent>
        <div className={styles.root}>
          <h1 className={styles.header}>{translate('operations.params.USER.mail')}</h1>
          <span className={styles.email}>{profile.mail}</span>
          {
            editable && profile.mail && profile.mail.length ? <div className={styles.button}>
              <DotsMenuButton onChange={editEmail} />
            </div> :
              editable && (!profile.mail || !profile.mail.length) ?
                <div className={styles.button}>
                  <AddButton
                    text={translate('ra.action.add')}
                    onClick={editEmail} />
                </div> : null
          }
        </div>
      </CardContent>
    </Card>
  )
};

export default EmailCard;