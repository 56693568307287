import React from "react";
import {
  useRefresh,
  useCreate, useNotify, useTranslate
} from "react-admin";
import styles from './CreateApprovalModal.module.css';
import CloseIcon from "../../../assets/close.png";
import CancelButton from "../../buttons/cancelButton/CancelButton";
import CustomButton from "../../buttons/customButton/CustomButton";
import CustomTextArea from "../../inputs/customTextArea/CustomTextArea";

type CreateApprovalModalProps = {
  closeModal: () => void,
  roleId: number,
}

const CreateApprovalModal = (props: CreateApprovalModalProps) => {
  const { closeModal, roleId } = props;
  const refresh = useRefresh();
  const [create] = useCreate();
  const notify = useNotify();
  const translate = useTranslate();
  const [comment, setComment] = React.useState('');
  const [step, setStep] = React.useState('creating');

  const onChangeCause = (v: string) => {
    setComment(v);
  }

  const onCreate = () => {
    const data = {
      type: 'access',
      target: roleId,
      status: 'pending',
      description: comment,
    }
    create('approval', { data },
      {
        onSuccess: () => setStep('sended'),
        onError: () => notify('ra.notification.canceled', { type: 'warning' })
      })
  }

  const close = () => {
    refresh();
    closeModal();
  }

  return (
    <div className={styles.root}>
      <div className={styles.close_button} onClick={closeModal}>
        <img src={CloseIcon} alt="" />
      </div>
      {
        step === 'creating' ?
          <h1 className={styles.header}>{translate('operations.params.APPROVAL.approveAccess')}</h1> :
          <h1 className={styles.header}>{translate('operations.params.APPROVAL.approvalSend')}</h1>
      }
      {
        step === 'creating' ?
          <span className={styles.text}>{translate('operations.params.APPROVAL.inputComment')}</span> :
          <span className={styles.text}>{translate('operations.params.APPROVAL.approvalStatus')}</span>
      }
      {
        step === 'creating' ? <CustomTextArea placeholder={translate('messages.commentText')} mode={'root'} value={comment} changeInput={onChangeCause} focus={false} limit={255} /> : null
      }
      <div className={styles.buttons}>
        <CancelButton text={translate('ra.action.cancel')} onClick={closeModal} />
        {
          step === 'creating' ?
            <CustomButton text={translate('operations.params.APPROVAL.approveAccess')} onClick={onCreate} disable={!comment.length} /> :
            <CustomButton text={translate('ra.action.close')} onClick={close} />
        }
      </div>
    </div>
  )
}

export default CreateApprovalModal;