import React from "react";
import { useTranslate, Loading, useNotify } from 'react-admin';
import Webcam from "react-webcam";
import styles from './UploadPhotoModal.module.css';
import CloseIcon from '../../../assets/close.png';
import CancelButton from "../../buttons/cancelButton/CancelButton";
import CustomButton from "../../buttons/customButton/CustomButton";
import { ReactComponent as WebCamIcon } from "../../../assets/webcam.svg";
import { ReactComponent as SaveIcon } from "../../../assets/save.svg";
import CustomTooltip from "../../tooltips/customTooltip/CustomTooltip";

type UploadPhotoModalProps = {
  closeModal: () => void,
  openFile: () => void,
  setPhoto: (base64: string) => void,
}

const UploadPhotoModal = (props: UploadPhotoModalProps) => {
  const { closeModal, openFile, setPhoto } = props;
  const steps = ['choose', 'photo'];
  const [step, setStep] = React.useState(steps[0]);
  const [loading, setLoading] = React.useState(true);
  const [screenshot, setScreenshot] = React.useState('');
  const translate = useTranslate();
  const notify = useNotify();
  const webcamRef = React.useRef<Webcam | null>(null);
  const capture = React.useCallback(
    () => {
      const imageSrc = webcamRef && webcamRef.current ? webcamRef.current.getScreenshot() : '';
      setScreenshot(imageSrc || '');
    },
    [webcamRef]
  );

  const videoConstraints = {
    width: 480,
    height: 640,
    facingMode: "user"
  };

  const onStepWebCam = () => {
    setStep(steps[1]);
  }

  const onScreenShot = () => {
    capture();
  }

  const onSetPhoto = () => {
    setPhoto(screenshot);
    closeModal();
  }

  const clearScreenshot = () => {
    if (screenshot.trim()) setScreenshot('');
  }

  const onWebCamError = (error: (string | DOMException)) => {
    notify(error.toString().includes('Requested device not found') ? 'messages.devicesNotFound' : error.toString(), { type: 'warning', messageArgs: { smart_count: 1 } });
    setStep(steps[0]);
  }

  return (
    step === steps[0] ?
      <div className={styles.root}>
        <div className={styles.close_button} onClick={closeModal}>
          <img src={CloseIcon} alt="" />
        </div>
        <h1 className={styles.header}>{translate('messages.uploadPhoto')}</h1>
        <div className={styles.buttons_wrapper}>
          <CustomTooltip title={translate('messages.fromFileSystem')}>
            <div className={styles.action_button} onClick={openFile}>
              <div className={styles.icon}>
                <SaveIcon />
              </div>
            </div>
          </CustomTooltip>
          <CustomTooltip title={translate('messages.fromWebCam')}>
            <div className={styles.action_button} onClick={onStepWebCam}>
              <div className={styles.icon}>
                <WebCamIcon />
              </div>
            </div>
          </CustomTooltip>
        </div>
      </div> :
      <div className={styles.web_cam_container}>
        <div className={styles.close_button} onClick={closeModal}>
          <img src={CloseIcon} alt="" />
        </div>
        <h1 className={styles.header}>{translate('messages.uploadPhoto')}</h1>
        <div className={styles.stream}>
          <Webcam
            width={480}
            height={640}
            audio={false}
            screenshotFormat="image/png"
            videoConstraints={videoConstraints}
            mirrored
            ref={webcamRef}
            onUserMedia={() => setLoading(false)}
            onUserMediaError={onWebCamError}
          />
          {
            screenshot?.trim() ?
              <img src={screenshot} alt="screenshot"/> : null
          }
          {
            loading ? <div className={styles.loading}>
              <Loading />
            </div> : null
          }
        </div>
        <div className={styles.buttons}>
          <CancelButton text={translate('ra.action.clearScreenshot')} onClick={clearScreenshot} />
          <CustomButton text={translate('ra.action.screenshot')} onClick={onScreenShot} disable={Boolean((!webcamRef.current && loading) || screenshot?.trim())}/>
          <CustomButton text={translate('ra.action.download')} onClick={onSetPhoto} disable={Boolean(!screenshot?.trim())}/>
        </div>
      </div>
  )
}

export default UploadPhotoModal;