import React from "react";
import {useLocation} from "react-router-dom";
import EditProfilePage from "../editProfilePage/EditProfilePage";
import {useShowController} from "react-admin";
import EditEmailPage from "../editEmailPage/EditEmailPage";
import EditPhonePage from "../editPhonePage/EditPhonePage";
import EditPasswordPage from "../editPasswordPage/EditPasswordPage";

const CommonEditProfilePages = () => {
  const location = useLocation();
  const {record} = useShowController();
  const page = location.pathname.split('edit/')[1];
  return (
    {
      'main': <EditProfilePage profile={record} />,
      'email': <EditEmailPage profile={record} />,
      'phone': <EditPhonePage profile={record} />,
      'pwd': <EditPasswordPage profile={record} />
    }[page] || <>Error</>
  )
}

export default CommonEditProfilePages;