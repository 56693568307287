import React from "react";
import { useGetOne } from "react-admin";
import { IRole } from "../../../utils/types";
import RoleCard from "../../cards/roleCard/RoleCard";
import RoleInfoCard from "../../cards/roleInfoCard/RoleInfoCard";

type RolePageProps = {
  id: string,
  getRole: (p: IRole) => void,
  userRole: number
}

const RolePage = (props: RolePageProps) => {
  const { getRole, id, userRole } = props;
  const [role, setRole] = React.useState({
    name: '',
    description: '',
    owner: '',
    adminGroup: '',
    userGroup: '',
    parentId: 0,
    appId: 0,
    createdAt: '',
    id: 0
  })

  useGetOne('role', { id }, {
    onSuccess: (data) => {
      setRole(data);
      getRole(data);
    },
    onError: () => false,
    retry: false,
  });

  return (
    <div>
      <RoleCard role={role} userRole={userRole} />
      <RoleInfoCard role={role} />
    </div>
  )
}

export default RolePage;