import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import GetApp from '@mui/icons-material/GetApp';
import { useTranslate } from 'react-admin';
import { ListItemText } from '@mui/material';
import customRuMessages from '../../../i18n/ru';

type ReportsMenuProps = {
  closePopover: (id: string) => void,
  ids?: number[]
}

const ReportsMenu = (props: ReportsMenuProps) => {
  const translate = useTranslate();
  const { closePopover, ids = [1, 2] } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e: any) => {
    setAnchorEl(null);
    if (e.target.id)
      closePopover(e.target.id)
  };

  return (
    <div>
      <Button
        id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        startIcon={<GetApp />}
      >
        {translate('resources.reports.name')}
      </Button>
      <Menu
        MenuListProps={{ 'aria-labelledby': 'fade-button' }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {Object.keys(customRuMessages.resources.reports.types)
          .filter(id => ids.includes(Number(id)))
          .map(id =>
            <MenuItem>
              <ListItemText>{translate(`resources.reports.types.${id}`)}</ListItemText>
              <Button id={`${String(id)}1`} onClick={handleClose}>xlsx</Button>
              <Button id={`${String(id)}2`} onClick={handleClose}>pdf</Button>
            </MenuItem>
          )}
      </Menu>
    </div>
  );
}
export default ReportsMenu;