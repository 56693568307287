import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useGetIdentity, useListController, useRecordContext, useTranslate } from "react-admin";
import styles from './RoleCard.module.css';
import SettingsIcon from '../../../assets/settings.png';
import RoleIcon from '../../../assets/defaultRole.png';
import RoleAppMenu from "../../popovers/roleAppMenu/RoleAppMenu";
import RowActionsButton from "../../buttons/rowActionsButton/RowActionsButton";
import { IApp, IApproval, IRole } from "../../../utils/types";
import CreateApprovalModal from "../../modals/createApprovalModal/CreateApprovalModal";
import CustomModal from "../../modals/customModal/CustomModal";

type RoleCardProps = {
  role: IRole,
  userRole: number
}

const RoleCard = (props: RoleCardProps) => {
  const { role } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { identity: user } = useGetIdentity();
  const myRoles = user?.roles ? user?.roles.map((item: any) => item.id) : [];
  const { data: userApprovals } = useListController({ resource: 'approval', filter: { type: 'access', status: 'pending', owner: user?.id } });
  const [showApprovalModal, setShowApprovalModal] = React.useState(false);
  const record: IApp = useRecordContext();
  const translate = useTranslate();

  // const getPermissionAdminApp = (): boolean => {
  //   return user?.memberOf && user?.memberOf.includes(record.adminGroup);
  // }

  // const getPermissionAdminRole = (): boolean => {
  //   return user?.memberOf && user?.memberOf.includes(role.adminGroup);
  // }

  const onShowApprovalModal = () => {
    setShowApprovalModal(true);
  }

  const onCloseApprovalModal = () => {
    setShowApprovalModal(false);
  }

  const editApp = () => {
    navigate(`${pathname}/settings`);
  }

  return (
    <div className={styles.root}>
      <div className={styles.row_direction}>
        <div className={styles.avatar}>
          <img src={RoleIcon} alt="" />
        </div>
        <span className={styles.name}>{role.name}</span>
      </div>
      <div className={styles.row_direction}>
        {
          role.editable || record.editable ? null : myRoles && myRoles.includes(role.id) ? <span className={styles.access_button_disabled}>{translate('messages.accessGranted')}</span>
            : userApprovals && userApprovals.map((item: IApproval) => item.target).includes(String(role.id)) ?
              <span className={styles.access_button_disabled}>{translate('messages.accessRequested')}</span>
              : <span onClick={(e: any) => { e.stopPropagation(); onShowApprovalModal() }} className={styles.access_button}>{translate('operations.params.APPROVAL.approveAccess')}</span>
        }
        {props.userRole === 1 || role.editable || record.editable ?
          <div className={styles.row_direction}>
            <div className={styles.settings} onClick={editApp}>
              <img src={SettingsIcon} alt="" />
              <span className={styles.settings_text}>{translate('operations.params.APP.settings')}</span>
            </div>
            <div className={styles.actions}>
              <RowActionsButton menuProps={{ record: role }} Menu={RoleAppMenu} />
            </div>
          </div>
          :
          null
        }
      </div>
      <CustomModal
        open={showApprovalModal}
        close={onCloseApprovalModal}
      >
        <CreateApprovalModal
          closeModal={onCloseApprovalModal}
          roleId={role.id}
        />
      </CustomModal>
    </div>
  )
}

export default RoleCard;