import * as React from 'react';
import { useRedirect } from 'react-admin';
import { useParams } from 'react-router-dom';
import { fetchProvider } from "../../../provider/authProvider";

const ShowRolePage = () => {
  const redirect = useRedirect();
  const { id: roleId } = useParams();

  React.useEffect(() => {
    fetchProvider(`/api/role/${roleId}`)
      .then(response => response.json())
      .then(role => {
        if (role) redirect(`/app/${role.appId}/show/role/${role.id}`);
      })
  }, [redirect, roleId]);

  return null
};

export default ShowRolePage;