import React from "react";
import styles from './RoleInfoCard.module.css';
import { IRole } from "../../../utils/types";
import { dateFormat } from "../../../utils/utils";
import { useTranslate } from 'react-admin';


type RoleInfoCardProps = {
  role: IRole,
}

const RoleInfoCard = (props: RoleInfoCardProps) => {
  const { role } = props;
  const translate = useTranslate();

  return (
    <div className={styles.root}>
      <span className={styles.title}>{translate('operations.params.APP.info')}</span>
      <div className={styles.row}>
        <span className={styles.row_prop}>{translate('operations.params.APP.description')}</span>
        <span className={styles.row_value}>{role.description}</span>
      </div>
      <div className={styles.row}>
        <span className={styles.row_prop}>{translate('operations.params.APP.createdAt')}</span>
        <span className={styles.row_value}>{dateFormat(role.createdAt)}</span>
      </div>
    </div>
  )
}

export default RoleInfoCard;