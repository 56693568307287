import React from "react";
import {
  useListController,
  useRefresh,
  useNotify,
  useUnselectAll,
  useDeleteMany,
  useTranslate,
} from "react-admin";
import styles from './RemoveBulkUsersModal.module.css';
import CloseIcon from "../../../assets/close.png";
import CancelButton from "../../buttons/cancelButton/CancelButton";
import CustomButton from "../../buttons/customButton/CustomButton";
import {IProfile} from "../../../utils/types";

type RemoveBulkUsersModalProps = {
  closeModal: () => void,
}

const RemoveBulkUsersModal = (props: RemoveBulkUsersModalProps) => {
	const translate = useTranslate();
  const {closeModal} = props;
  const {selectedIds, data} = useListController();
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll('user');
  const selected = selectedIds.length ? data.filter((item: IProfile) => selectedIds.includes(item.id) && item?.role === 4) : [];
  const [deleteMany] = useDeleteMany(
    'user',
    {ids: selected.map((item: IProfile) => item.id)},
    {
      mutationMode: 'undoable',
      onSuccess: () => {
        notify('ra.notification.deleted', { type: 'success', messageArgs: { smart_count: 1 }, undoable: true });
        unselectAll();
        refresh();
      },
      onError: () => notify('ra.notification.canceled', { type: 'warning' })
    }
  )

  const onRemoveApp = () => {
    deleteMany();
    closeModal();
  }

  return (
    <div className={styles.root}>
      <div className={styles.close_button} onClick={closeModal}>
        <img src={CloseIcon} alt=""/>
      </div>
      <h1 className={styles.header}>{translate('messages.deleteUsers')}</h1>
      <span className={styles.text}>{`${translate('messages.removeManyUsers')} (${selected.length})`}</span>
      <ul className={styles.list}>
        {selected.map((item: IProfile) => <li className={styles.list_item} key={item.id}>{item.givenName && item.givenName.length ? item.givenName : item.id}</li>)}
      </ul>
      <div className={styles.buttons}>
        <CancelButton text={translate('ra.action.cancel')} onClick={closeModal} />
        <CustomButton text={translate('ra.action.delete')} onClick={onRemoveApp} disable={!Boolean(selected.length)}/>
      </div>
    </div>
  )
}

export default RemoveBulkUsersModal;