import React from "react";
import { TextareaAutosize } from "@mui/material";
import './CustomTextArea.css';
import { useTranslate } from 'react-admin';

type CustomTextAreaProps = {
  placeholder: string,
  mode: string,
  value: string,
  changeInput: (v: string) => void,
  focus: boolean,
  limit: number,
}

const CustomTextArea = (props: CustomTextAreaProps) => {
  const { placeholder, mode, value, changeInput, focus, limit } = props;
  const changeValue = (e: any) => {
    changeInput(e.target.value);
  };
  const styledClassName: string = {
    'root': 'custom_input',
    'error': 'custom_input error_state',
    'success': 'custom_input success_state',
  }[mode] || 'custom_input';
  const translate = useTranslate();

  return (
    <div className={'custom_textarea'}>
      <TextareaAutosize
        className={styledClassName}
        autoComplete="off"
        value={value ? value : ''}
        placeholder={placeholder}
        onChange={changeValue}
        autoFocus={focus}
        maxRows={5}
        minRows={5}
        maxLength={limit}
      />
      <span className={'custom_textarea__length'}>{`${value.length}/${limit} ${translate('operations.params.APP.symbols')}`}</span>
    </div>
  );
}

export default CustomTextArea;