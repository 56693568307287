import * as React from 'react';
import {AppBar, AppBarProps, useGetOne} from 'react-admin';
import styles from './CustomHeader.module.css';
import ProfileMenu from "../popovers/profileMenu/ProfileMenu";

const CustomHeader = (props: AppBarProps) => {

  const [logo, setLogo] = React.useState<string>();
  const [systemName, setSystemName] = React.useState<string>();

  useGetOne('settings', { id: 'app' }, {
    onSuccess: (data) => {
      setLogo(data.settings.APP_LOGO);
      setSystemName(data.settings.APP_NAME);
    }
  })

  return (
    <AppBar
      sx={{
        "& .RaAppBar-title": {
          flex: 1,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        },
        "& .app-loader": {
          color: "rgba(0, 0, 0, 0.87)"
        }
      }}
      {...props}
      userMenu={<ProfileMenu/>}


    >
      <div className={styles.root}>
        <img src={logo} alt="logo" className={styles.logo}/>
        <h2 className={styles.name}>{systemName}</h2>
      </div>
    </AppBar>
  )
};

export default CustomHeader;