import React, { RefObject } from "react";
import { useNavigate } from 'react-router-dom';
import { Card, CardContent } from '@mui/material';
import { useDataProvider, useGetIdentity, useNotify, useRedirect, useTranslate } from "react-admin";
import styles from './CreateProfilePage.module.css';
import CustomTooltip from "../../tooltips/customTooltip/CustomTooltip";
import backIcon from '../../../assets/Back.png';
import CustomInput from "../../inputs/customInput/CustomInput";
import CustomSelect from "../../inputs/customSelect/CustomSelect";
import CustomRadioButton from "../../inputs/customRadioButton/CustomRadioButton";
import CancelButton from "../../buttons/cancelButton/CancelButton";
import CustomButton from "../../buttons/customButton/CustomButton";
import SaveIcon from '../../../assets/Save.png';
import AvatarIcon from '../../../assets/person.png';
import { AVATAR_HEIGHT, AVATAR_WIDTH, MAX_SIZE_AVATAR, CAMPUS_DIGITS } from "../../../utils/constants";
import CustomInputItems from "../../inputs/customInputItems/CustomInputItems";
import SelectRolesModal from "../../modals/selectRolesModal/SelectRolesModal";
import { IProfile, IRole } from "../../../utils/types";
import { getBase64 } from "../../../utils/utils";
import CustomTextArea from '../../inputs/customTextArea/CustomTextArea';
import UploadPhotoModal from "../../modals/uploadPhotoModal/UploadPhotoModal";
import CustomModal from "../../modals/customModal/CustomModal";

const CreateProfilePage = () => {
	const translate = useTranslate();
  const dataProvider = useDataProvider();
  const [sex, setSex] = React.useState(translate('operations.params.USER.notSpecified'));
  const [isResident, setIsResident] = React.useState(translate('operations.params.USER.residentYes'));
  const [name, setName] = React.useState('');
  const [surname, setSurname] = React.useState('');
  const [date, setDate] = React.useState('');
  const [month, setMonth] = React.useState(translate('operations.params.MONTH.january'));
  const [year, setYear] = React.useState('');
  const [id, setId] = React.useState('');
  const [validId, setValidId] = React.useState(true);
  const [campus, setCampus] = React.useState('');
  const [phone, setPhone] = React.useState('+7 (');
  const [validPhone, setValidPhone] = React.useState('root');
  const [email, setEmail] = React.useState('');
  const [validEmail, setValidEmail] = React.useState('root');
  const [avatarSrc, setAvatarSrc] = React.useState('');
  const [fileError, setFileError] = React.useState(false);
  const [messageFile, setMessageFile] = React.useState('');
  const [selectedRoles, setSelectedRoles] = React.useState<IRole[]>([]);
  const [step, setStep] = React.useState<string>('creating');
  const [createdUser, setCreatedUser] = React.useState<IProfile>();
  const [limitDate, setLimitDate] = React.useState<Date | null>();
  const [showChangePhotoModal, setShowChangePhotoModal] = React.useState(false);
  const navigate = useNavigate();
  const notify = useNotify();
  const redirect = useRedirect();
  const [comment, setComment] = React.useState('');
  const { identity: user } = useGetIdentity();

  const fileInput: RefObject<any> = React.createRef();

  const monthList = [
    translate('operations.params.MONTH.january'),
    translate('operations.params.MONTH.february'),
    translate('operations.params.MONTH.march'),
    translate('operations.params.MONTH.april'),
    translate('operations.params.MONTH.may'),
    translate('operations.params.MONTH.june'),
    translate('operations.params.MONTH.july'),
    translate('operations.params.MONTH.august'),
    translate('operations.params.MONTH.september'),
    translate('operations.params.MONTH.october'),
    translate('operations.params.MONTH.november'),
    translate('operations.params.MONTH.december'),
  ];

  const onShowAddRoleModal = () => {
    setShowChangePhotoModal(true);
  }

  const onCloseChangePhotoModal = () => {
    setShowChangePhotoModal(false);
  }

  const onGetScreenShot = (base64: string) => {
    setAvatarSrc(base64);
  }

  const onChangeSex = (v: string) => {
    setSex(v);
  }

  const onChangeResident = (v: string) => {
    setIsResident(v);
  }

  const onChangeName = (v: string) => {
    setName(v);
  }

  const onChangeSurname = (v: string) => {
    setSurname(v);
  }

  const onChangeDate = (v: string) => {
    if ((/\d+$/.test(v) && Number(v) <= 31)) setDate(v);
    else if (v === '') setDate('');
  }

  const onChangeMonth = (v: string) => {
    setMonth(v);
  }

  const onChangeYear = (v: string) => {
    if ((/\d+$/.test(v) && Number(v) <= (new Date().getFullYear() - 10))) setYear(v);
    else if (v === '') setYear('');
  }

  const isValidId = (v: string): boolean => {
    const arr = v.slice(0, 9).split('').map((item: string) => Number(item));
    const checkSum = parseInt(v.slice(9), 10);
    const sum = arr[0] * 9 + arr[1] * 8 + arr[2] * 7 + arr[3] * 6 + arr[4] * 5 + arr[5] * 4 + arr[6] * 3 + arr[7] * 2 + arr[8];
    if (sum < 100 && sum === checkSum) {
      return true;
    } else if ((sum === 100 || sum === 101) && checkSum === 0) {
      return true;
    } else if (sum > 101 && (sum % 101 === checkSum || (sum % 101 === 100 && checkSum === 0))) {
      return true;
    } else {
      return false;
    }
  }

  const onChangeId = (v: string) => {
    if (/\d+$/.test(v) || v === '') {
      if (v.length === 11) setValidId(isValidId(v));
      else if (v.length === 0) setValidId(true);
      else setValidId(false);
      setId(v);
    }
  }

  const onChangeCampus = (v: string) => {
    if (/\d+$/.test(v) || v === '') {
      setCampus(v);
    }
  }

  const onChangePhone = (v: string) => {
    let handlePhone = v
      .replace(/\(/g, '')
      .replace(/ /g, '')
      .replace(/\)/g, '')
      .replace(/-/g, '');
    if (handlePhone.length < 13 && /^[+]\d+$/.test(handlePhone)) {
      if (handlePhone.length && handlePhone.length < 3) {
        setPhone(`${handlePhone} `);
        setValidPhone('error');
      } else if (handlePhone.length && handlePhone.length === 3) {
        setPhone(`${handlePhone.slice(0, 2)} (${handlePhone.slice(2, 3)}`);
        setValidPhone('error');
      } else if (handlePhone.length && handlePhone.length < 6) {
        setPhone(`${handlePhone.slice(0, 2)} (${handlePhone.slice(2, 5)}`);
        setValidPhone('error');
      } else if (handlePhone.length && handlePhone.length === 6) {
        setPhone(
          `${handlePhone.slice(0, 2)} (${handlePhone.slice(
            2,
            5
          )}) ${handlePhone.slice(5, 6)}`
        );
        setValidPhone('error');
      } else if (handlePhone.length && handlePhone.length < 9) {
        setPhone(
          `${handlePhone.slice(0, 2)} (${handlePhone.slice(
            2,
            5
          )}) ${handlePhone.slice(5, 8)}`
        );
        setValidPhone('error');
      } else if (handlePhone.length && handlePhone.length === 9) {
        setPhone(
          `${handlePhone.slice(0, 2)} (${handlePhone.slice(
            2,
            5
          )}) ${handlePhone.slice(5, 8)}-${handlePhone.slice(8, 9)}`
        );
        setValidPhone('error');
      } else if (handlePhone.length && handlePhone.length < 11) {
        setPhone(
          `${handlePhone.slice(0, 2)} (${handlePhone.slice(
            2,
            5
          )}) ${handlePhone.slice(5, 8)}-${handlePhone.slice(8, 10)}`
        );
        setValidPhone('error');
      } else if (handlePhone.length && handlePhone.length === 11) {
        setPhone(
          `${handlePhone.slice(0, 2)} (${handlePhone.slice(
            2,
            5
          )}) ${handlePhone.slice(5, 8)}-${handlePhone.slice(
            8,
            10
          )}-${handlePhone.slice(10, 12)}`
        );
        setValidPhone('error');
      } else if (handlePhone.length && handlePhone.length < 13) {
        setPhone(
          `${handlePhone.slice(0, 2)} (${handlePhone.slice(
            2,
            5
          )}) ${handlePhone.slice(5, 8)}-${handlePhone.slice(
            8,
            10
          )}-${handlePhone.slice(10, 12)}`
        );
        setValidPhone('root');
      }
    }
  }

  const onChangeEmail = (v: string) => {
    // eslint-disable-next-line
    if ((/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*){2,}|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]{2,}\.)+[a-zA-Z]{2,}))$/.test(v) && v.length <= 255) || v === '') setValidEmail('root');
    else setValidEmail('error');
    setEmail(v);
  }

  const onComeBack = () => {
    navigate(-1);
  }

  const changePhoto = () => {
    if (fileInput.current) fileInput.current.click();
  }

  const removePhoto = () => {
    if (avatarSrc.length) {
      setAvatarSrc('');
      fileInput.current.value = null;
    }
    setFileError(false);
    setMessageFile('');
  }

  const generateCampusNumber = () => {
    const number = String(Math.round(Math.random() * Math.pow(10, CAMPUS_DIGITS)));
    setCampus(number.length === CAMPUS_DIGITS ? number : `${number}0`);
  }

  const validPhotoSize = (file: any) => {
    return new Promise((resolve) => {
      const img = new Image()
      img.onload = (e: any) => {
        if (!e.path && !e.target) {
          throw new Error("");
        }

        let width = 0;
        let height = 0;
        if (e.path) {
          width = e.path[0].width;
          height = e.path[0].height;
        }

        if (e.target) {
          width = e.target.width;
          height = e.target.height;
        }

        if (width > AVATAR_WIDTH || height > AVATAR_HEIGHT) resolve(false);
        else resolve(true);
      }
      img.src = URL.createObjectURL(file);
    })
  }

  const onSelectPhoto = () => {
    onCloseChangePhotoModal();
    const file = fileInput.current.files && fileInput.current.files[0];
    const res = validPhotoSize(file);
    res.then(async (isValidSize) => {
      if (file && ['jpg', 'jpeg', 'png', 'svg+xml'].includes(file.type.split('/')[1]) && file.size <= MAX_SIZE_AVATAR && isValidSize) {
        setAvatarSrc(await getBase64(file));
        setFileError(false);
        setMessageFile('');
      } else if (!isValidSize) {
        setFileError(true);
        setMessageFile(translate('ra.notification.approvePhotoErrorResolution'));
      } else if (file && !['jpg', 'jpeg', 'png', 'svg+xml'].includes(file.type.split('/')[1])) {
        setFileError(true);
        setMessageFile(translate('ra.notification.approvePhotoErrorFormat'));
      } else if (file && file.size > MAX_SIZE_AVATAR) {
        setFileError(true);
        setMessageFile(translate('ra.notification.approvePhotoErrorSize'));
      }
    });
  }

  const deformatPhone = (v: string) => {
    return v.replace(/\(/g, '')
      .replace(/ /g, '')
      .replace(/\)/g, '')
      .replace(/-/g, '');
  }

  const onSaveUser = () => {
    const newProfile = {
      data: {
        givenName: name,
        sn: surname,
        description: `${date}.0${monthList.indexOf(month) + 1}.${year}`,
        initials: { [translate('operations.params.USER.male')] : [translate('operations.params.USER.maleM')], [translate('operations.params.USER.female')]: [translate('operations.params.USER.femaleF')] }[sex] || translate('operations.params.USER.none'),
        facsimileTelephoneNumber: isResident === translate('operations.params.USER.residentYes') ? '1' : '0',
        postalCode: isResident === translate('operations.params.USER.residentYes') ? id : campus,
        mobile: deformatPhone(phone),
        photo: avatarSrc,
        mail: email,
        userAccountControl: true
      }
    }

    dataProvider
      .create('user', newProfile)
      .then((res) => {
        notify('ra.notification.created', { type: 'success', messageArgs: { smart_count: 1 } });
        setStep('');
        setCreatedUser(res.data);
      })
      .catch(() => notify('ra.notification.canceled', { type: 'warning' }))
  }

  const getLinkToUser = (): string => {
    if (user!.role === 1) {
      return `/user/${createdUser!.id}/show`;
    } else {
      return `/app/${selectedRoles[0].appId}/show/admin/${createdUser!.id}`;
    }
  }

  const onSaveRoles = () => {
    if (selectedRoles.length && createdUser) {
      selectedRoles.map((role: IRole) => {
        const data: any = {
          id: createdUser?.id
        }
        if (limitDate) {
          limitDate?.setHours(0);
          limitDate?.setMinutes(0);
          data.expiresAt = limitDate?.toISOString();
        }
        dataProvider.update('group', {
          id: role.userGroup, data: {
            member: {
              add: data
            },
            comment: comment
          }, previousData: {}
        })
          .then(() => {
            notify('ra.notification.updated', { type: 'success', messageArgs: { smart_count: 1 }, undoable: true });
            redirect(getLinkToUser());
          })
        return false;
      })
    }
  }

  const onChangeCause = (v: string) => {
    setComment(v);
  }

  const onChangeLimitDate = (v: Date | null) => setLimitDate(v);

  const validate = () => !name.length || !surname.length || !date.length || !month.length || !year.length || (isResident === 'Да' ? !validId || !id?.length : campus?.length !== CAMPUS_DIGITS) || (phone === '+7 (' || validPhone === 'error') || !email.length

  return (
    <div className={styles.wrapper}>
      <div className={styles.root}>
        <header>
          <CustomTooltip title={translate('ra.action.comeback')}>
            <div className={styles.back_icon} onClick={onComeBack}>
              <img src={backIcon} alt="icon" />
            </div>
          </CustomTooltip>
          <h1>{translate('operations.params.USER.addCounterparty')}</h1>
        </header>
        <Card>
          {
            step === 'creating' ?
              <CardContent>
                <div className={styles.row_item}>
                  <span className={styles.row_item__label}>{translate('operations.params.USER.givenName')}<span style={{ color: 'red' }}>*</span></span>
                  <CustomInput placeholder={translate('operations.params.USER.enterName')} mode={'root'} value={name} changeInput={onChangeName} errorMessage={''} focus={false} />
                </div>
                <div className={styles.row_item}>
                  <span className={styles.row_item__label}>{translate('operations.params.USER.sn')}<span style={{ color: 'red' }}>*</span></span>
                  <CustomInput placeholder={translate('operations.params.USER.enterLastName')} mode={'root'} value={surname} changeInput={onChangeSurname} errorMessage={''} focus={false} />
                </div>
                <div className={styles.block_photo}>
                  <span className={styles.block_photo__label}>{translate('operations.params.USER.userPhoto')}</span>
                  <div className={styles.block_photo__buttons}>
                    <div className={styles.block_photo__buttons__avatar}>
                      <img src={avatarSrc.length ? avatarSrc : AvatarIcon} className={avatarSrc.length ? styles.photo : styles.icon} alt="" />
                    </div>
                    <input
                      type="file"
                      name="photo"
                      id="upload_person_photo"
                      accept="image/*"
                      ref={fileInput}
                      onChange={onSelectPhoto}
                      className={styles.block_photo__buttons__input}
                    />
                    {
                      avatarSrc ?
                        <div className={styles.block_photo__buttons__load} onClick={removePhoto}>{translate('ra.action.delete')}</div>
                        : null
                    }
                    <div className={styles.block_photo__buttons__load} onClick={onShowAddRoleModal}>{translate('ra.action.download')}</div>
                  </div>
                  {
                    fileError ?
                      <span className={styles.block_photo__description_error}>{messageFile}</span> :
                      <span className={styles.block_photo__description}>{translate('operations.params.USER.fileSize')}</span>
                  }
                </div>
                <div className={styles.row_item}>
                  <span className={styles.row_item__label}>{translate('operations.params.USER.dateOfBirth')}<span style={{ color: 'red' }}>*</span></span>
                  <div className={styles.row_item__group}>
                    <div className={styles.row_item__group_input}>
                      <CustomInput placeholder={translate('operations.params.DAYS.day')} mode={'root'} value={date} changeInput={onChangeDate} errorMessage={''} focus={false} />
                    </div>
                    <div className={styles.row_item__group_input}>
                      <CustomSelect menuElements={monthList} defaultValue={month} changeValue={onChangeMonth} mode={'root'} />
                    </div>
                    <div className={styles.row_item__group_input}>
                      <CustomInput placeholder={translate('operations.params.DAYS.year')} mode={'root'} value={year} changeInput={onChangeYear} errorMessage={''} focus={false} />
                    </div>
                  </div>
                </div>
                <div className={styles.row_item}>
                  <span className={styles.row_item__label}>{translate('operations.params.USER.initials')}</span>
                  <CustomRadioButton value={sex} changeValue={onChangeSex} list={[translate('operations.params.USER.notSpecified'), translate('operations.params.USER.male'), translate('operations.params.USER.female')]} />
                </div>
                <div className={styles.row_item}>
                  <span className={styles.row_item__label}>{translate('operations.params.USER.resident')}</span>
                  <CustomRadioButton value={isResident} changeValue={onChangeResident} list={[translate('operations.params.USER.residentYes'), translate('operations.params.USER.residentNo')]} />
                </div>
                {
                  isResident !== translate('operations.params.USER.residentYes') ?
                    <div className={styles.row_item}>
                      <span className={styles.row_item__label}>{translate('operations.params.USER.campusNumber')}<span style={{ color: 'red' }}>*</span></span>
                      <CustomInput placeholder={translate('operations.params.USER.enterNumber')} mode={campus?.length === CAMPUS_DIGITS || campus?.length === 0 ? 'root' : 'error'} value={campus} changeInput={onChangeCampus} errorMessage={translate('operations.params.EMAIL.invalidFormat')} focus={false} />
                      <span className={styles.generate_button} onClick={generateCampusNumber}>{translate('operations.params.USER.generate')}</span>
                    </div> :
                    <div className={styles.row_item}>
                      <span className={styles.row_item__label}>{translate('operations.params.USER.postalCode')}<span style={{ color: 'red' }}>*</span></span>
                      <CustomInput placeholder={translate('operations.params.USER.enterPostalCode')} mode={validId ? 'root' : 'error'} value={id} changeInput={onChangeId} errorMessage={translate('operations.params.EMAIL.invalidFormat')} focus={false} />
                    </div>
                }
                <div className={styles.row_item}>
                  <span className={styles.row_item__label}>{translate('operations.params.PHONE.phoneNumber')} <span style={{ color: 'red' }}>*</span></span>
                  <CustomInput placeholder={translate('operations.params.PHONE.enterYourPhoneNumber')} mode={validPhone} value={phone} changeInput={onChangePhone} errorMessage={''} focus={false} />
                </div>
                <div className={styles.row_item}>
                  <span className={styles.row_item__label}>{translate('operations.params.EMAIL.enterEmailAddress')} <span style={{ color: 'red' }}>*</span></span>
                  <CustomInput placeholder={translate('operations.params.EMAIL.enterYourEmailAddress')} mode={validEmail} value={email} changeInput={onChangeEmail} errorMessage={translate('operations.params.EMAIL.invalidFormat')} focus={false} />
                </div>
                <div className={styles.buttons}>
                  <CancelButton text={translate('ra.action.abort')} onClick={onComeBack} />
                  <CustomButton text={translate('ra.action.create')} onClick={onSaveUser} iconSrc={SaveIcon} disable={validate()} />
                </div>
              </CardContent> :
              <CardContent>
                <h4>{translate('operations.params.USER.assigningRoles')}</h4>
                <div className={styles.row_item}>
                  <span className={styles.row_item__label}>{translate('operations.params.USER.selectRoles')}</span>
                  <CustomInputItems selected={selectedRoles} modalProps={{ roles: selectedRoles, onSelect: setSelectedRoles, date: limitDate, changeDate: onChangeLimitDate }} Modal={SelectRolesModal} />
                </div>
                <h1 className={styles.header_comment}>{translate('messages.inputReason')}</h1>
                <CustomTextArea placeholder={translate('messages.commentText')} mode={'root'} value={comment} changeInput={onChangeCause} focus={false} limit={255} />
                <div className={styles.buttons}>
                  <CancelButton text={translate('ra.action.abort')} onClick={onComeBack} />
                  <CustomButton text={translate('ra.action.save')} onClick={onSaveRoles} iconSrc={SaveIcon} disable={!selectedRoles.length} />
                </div>
              </CardContent>
          }
        </Card>
        <CustomModal
          open={showChangePhotoModal}
          close={onCloseChangePhotoModal}
        >
          <UploadPhotoModal
            closeModal={onCloseChangePhotoModal}
            openFile={changePhoto}
            setPhoto={onGetScreenShot}
          />
        </CustomModal>
      </div>
    </div>
  )
}

export default CreateProfilePage;