import React, { useEffect } from "react";
import {
	useDataProvider, useListController,
	useRefresh, useListContext,
	useUnselectAll,
	useTranslate, useNotify
} from "react-admin";
import styles from './RemoveAccessModal.module.css';
import CloseIcon from "../../../assets/close.png";
import CancelButton from "../../buttons/cancelButton/CancelButton";
import CustomButton from "../../buttons/customButton/CustomButton";
import { IProfile } from "../../../utils/types";
import { useLocation } from "react-router-dom";
import CustomTextArea from '../../inputs/customTextArea/CustomTextArea';
import {transformRoleName} from "../../../utils/utils";

type RemoveAccessModalProps = {
	closeModal: () => void,
	record?: IProfile | undefined,
	context: { role: boolean, context: string }
}

const RemoveAccessModal = (props: RemoveAccessModalProps) => {
	const { closeModal, record, context } = props;
	const dataProvider = useDataProvider();
	const refresh = useRefresh();
	const notify = useNotify();
	const unselectAll = useUnselectAll(context.context === 'user' ? 'userRole' : context.role ? 'adminRole' : 'adminApp');
	const group = useListContext().filter!.memberOf;
	const translate = useTranslate();
	const { selectedIds } = useListController();
	const [, , appId, , , roleId] = useLocation().pathname.split('/');

	const steps = ['choose', 'confirm'];
	const [name, setName] = React.useState('');
	const [step, setStep] = React.useState<string>(steps[0])
	const [adminsApp, setAdminsApp] = React.useState<string[]>([]);
	const [adminsRole, setAdminsRole] = React.useState<string[]>([]);
	const [usersRole, setUsersRole] = React.useState<string[]>([]);
	const [comment, setComment] = React.useState('');

	useEffect(() => {
		checkGroup();
		//eslint-disable-next-line
	}, [])

	const onChangeCause = (v: string) => {
		setComment(v);
	}

	const onRemoveAccess = async () => {
		if (record) {
			dataProvider.update('group', {
				id: group, data: {
					member: {
						delete: record?.id
					},
					comment: comment
				}, previousData: {}
			})
				.then(() => {
					refresh();
					notify('ra.notification.revoked', { type: 'success', messageArgs: { smart_count: 1 }, undoable: true });
				})
		} else {
			await Promise.all(selectedIds.map((item: string) => {
				return dataProvider.update('group', {
					id: group, data: {
						member: {
							delete: item
						},
						comment: comment
					}, previousData: {}
				});
			}))
			refresh();
			notify('ra.notification.revoked', { type: 'success', messageArgs: { smart_count: 1 }, undoable: true });
			unselectAll();
		}
		closeModal();
	}

	const checkGroup = async () => {
		if (context && !context.role && context.context === 'admin') {
			const appData = await dataProvider.getOne('depsAppAdmin', { id: appId })
			setUsersRole(appData.data.roles);
			setAdminsRole(appData.data.roleAdmins);
			setAdminsApp(appData.data.appAdmins);

		} else if (context && context.role && context.context === 'admin') {
			const roleData = await dataProvider.getOne('depsRoleAdmin', { id: roleId })
			setUsersRole(roleData.data.roles);
			setAdminsRole(roleData.data.roleAdmins);
			setAdminsApp(roleData.data.appAdmins);

		} else {
			const role = await dataProvider.getOne('depsRole', { id: roleId })
			setUsersRole(role.data.roles);
			setAdminsRole(role.data.roleAdmins);
			setAdminsApp(role.data.appAdmins);
		}
	}

	dataProvider
		.getOne(`${roleId ? 'role' : 'app'}`, { id: roleId || appId })
		.then(response => {
			setName(response.data?.name)
		});

	if (!name) return <></>;

	const onComeBack = () => {
		setStep(steps[0]);
	}

	return (
		step === steps[0] ?
			<div className={styles.root}>
				<div className={styles.close_button} onClick={closeModal}>
					<img src={CloseIcon} alt="" />
				</div>
				<h1 className={styles.header}>{translate('messages.revokeAccess')}</h1>
				<div className={styles.text}>{translate('messages.revokeAccessTo', { user: record ? `${translate('messages.fromUser')} ${record?.displayName || record?.givenName || record?.id}` : `${selectedIds.length} ${translate('messages.fromUsers')}`, name: transformRoleName(name, 44) })}</div>

				{
					usersRole.length ||
					adminsApp.length ||
					adminsRole.length ?
					<div
						className={styles.text}>{translate('messages.revokeAccessMessage', {users: record ? `${record?.displayName || record?.givenName || record?.id} ${translate('messages.willLost')}` : `${translate('operations.entities.USER')} (${selectedIds.length}) ${translate('messages.willLose')}`})}</div> :
						null
				}
				<ul className={styles.list}>
					{usersRole.length ? usersRole.map((item: string) => <li className={styles.list_item} key={item}>{transformRoleName(item, 54)}</li>) : null}
					{adminsApp.length ? adminsApp.map((item: string) => <li className={styles.list_item} key={item}>{`${translate('messages.appAdministration')} ${transformRoleName(item, 54)}`}</li>) : null}
					{adminsRole.length ? adminsRole.map((item: string) => <li className={styles.list_item} key={item}>{`${translate('messages.roleAdministration')} ${transformRoleName(item, 54)}`}</li>) : null}
				</ul>

				<div className={styles.buttons}>
					<CancelButton text={translate('ra.action.cancel')} onClick={closeModal} />
					<CustomButton text={translate('ra.action.revoke')} onClick={() => setStep(steps[1])} />
				</div>
			</div> :
			<div className={styles.root}>
				<div className={styles.close_button} onClick={closeModal}>
					<img src={CloseIcon} alt="" />
				</div>

				<h1 className={styles.header}>{translate('messages.inputReason')}</h1>
				<CustomTextArea placeholder={translate('messages.commentText')} mode={'root'} value={comment} changeInput={onChangeCause} limit={255} focus={false} />
				<div className={styles.buttons_wrapper}>
					<CancelButton text={translate('ra.action.comeback')} onClick={onComeBack} />
					<div className={styles.buttons}>
						<CancelButton text={translate('ra.action.cancel')} onClick={closeModal} />
						<CustomButton text={translate('ra.action.confirm')} onClick={() => onRemoveAccess()} />
					</div>
				</div>
			</div>
	)

}

export default RemoveAccessModal;