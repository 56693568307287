import React from "react";
import styles from './UserItem.module.css';
import CustomCheckbox from "../../inputs/customCheckbox/CustomCheckbox";
import AvatarIcon from '../../../assets/avatar.png';

type UserItemProps = {
  selected: boolean,
  onChange: (v: boolean, name: string | number) => void,
  text: string,
  id: string | number,
}

const UserItem = (props: UserItemProps) => {
  const {selected, onChange, text, id} = props;

  const onChangeSelected = (e: any) => {
    onChange(e.target.checked, id);
  }

  return (
    <div className={selected ? `${styles.selected} ${styles.root}` : styles.root}>
      <div className={styles.checkbox}>
        <CustomCheckbox checked={selected} change={onChangeSelected} indeterminate={false} around={false} />
      </div>
      <div className={styles.icon}>
        <img src={AvatarIcon} alt={''}/>
      </div>
      <span className={styles.name}>{text}</span>
    </div>
  )
}

export default UserItem;