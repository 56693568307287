import React from "react";
import styles from './RemoveUserModal.module.css';
import CloseIcon from '../../../assets/close.png';
import CancelButton from "../../buttons/cancelButton/CancelButton";
import CustomButton from "../../buttons/customButton/CustomButton";
import { useTranslate } from "react-admin";

type RemoveUserModalProps = {
  closeModal: () => void,
  onRemoveUser: () => void,
  names: string | string[],
}

const RemoveUserModal = (props: RemoveUserModalProps) => {
  const {onRemoveUser, closeModal, names} = props;
	const translate = useTranslate();

  return (
    <div className={styles.root}>
      <div className={styles.close_button} onClick={closeModal}>
        <img src={CloseIcon} alt=""/>
      </div>
      <h1 className={styles.header}>{translate('messages.deleteUser')}</h1>
      <span className={styles.text}>{`${translate('messages.remove')} ${Array.isArray(names) ? [translate('messages.multipleUsers')] : [translate('messages.fromUser')]} ${Array.isArray(names) ? `(${names.length})` : names}?`}</span>
      <div className={styles.buttons}>
        <CancelButton text={translate('ra.action.cancel')} onClick={closeModal} />
        <CustomButton text={translate('ra.action.delete')} onClick={onRemoveUser}/>
      </div>
    </div>
  )
}

export default RemoveUserModal;