import React from "react";
import { useDataProvider, useTranslate } from "react-admin";
import styles from './AddMenuButton.module.css';
import Icon from '../../../assets/Add.png';
import CustomPopover from "../../popovers/customPopover/CustomPopover";
import AddProfileMenu from "../../popovers/addProfileMenu/AddProfileMenu";
import { IApp } from "../../../utils/types";

type AddMenuButtonProps = {
  isCollapse: boolean,
  user: any
}

const AddMenuButton = (props: AddMenuButtonProps) => {
  const { isCollapse, user } = props;
  const dataProvider = useDataProvider();
  const [anchorElCollapseIcon, setAnchorElCollapseIcon] = React.useState(null);
  const [apps, setApps] = React.useState<any[]>([]);
  const collapseIconRef = React.useRef(null);
  const open = Boolean(anchorElCollapseIcon);
  const translate = useTranslate();

  const handleClose = (e: any) => {
    e.stopPropagation();
    setAnchorElCollapseIcon(null);
  };

  const showPopover = (e: any) => {
    if (!open) setAnchorElCollapseIcon(collapseIconRef.current);
    else setAnchorElCollapseIcon(null);
  };

  const closePopover = () => {
    setAnchorElCollapseIcon(null);
  }

  const isAdminApp = () => {
    return apps.map((app: IApp) => app.adminGroup).reduce((isMatch: boolean, group: string) => isMatch || (user?.memberOf && user?.memberOf.includes(group)), false);
  }

  React.useEffect(() => {
    dataProvider.getList('app', { sort: { field: "id", order: "ASC" }, pagination: { page: 1, perPage: 1000 }, filter: {} })
      .then(res => {
        setApps(res.data);
      })
  }, [dataProvider])

  return (
    user?.role === 1 || isAdminApp() ?
      <div className={styles.root}>
        <button className={styles.button} ref={collapseIconRef} onClick={showPopover}>
          {
            isCollapse ?
              <img src={Icon} alt="" /> :
              <span className={styles.text}>{translate('ra.action.add')}</span>
          }
        </button>
        <CustomPopover
          open={open}
          anchorEl={anchorElCollapseIcon}
          handleClose={handleClose}
          ChildComponent={() => <AddProfileMenu closePopover={closePopover} isAdmin={user?.role === 1} />}
        />
      </div>
      :
      null
  )
}

export default AddMenuButton;