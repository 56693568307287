import * as React from "react";
import { Card, CardContent } from '@mui/material';
import { FunctionField, ReferenceField, ShowBase, TextField, useTranslate } from "react-admin";
import styles from './ApprovalUserCard.module.css';
import { IProfile } from "../../../utils/types";
import { USER_ROLES } from "../../../utils/constants";

const ApprovalUserCard = () => {
  const translate = useTranslate();

  return (
    <ShowBase>
      <Card>
        <CardContent>
          <div className={styles.root}>
            <h1 className={styles.header}>{translate('operations.params.USER.userInformation')}</h1>
            <div className={styles.item}>
              <span className={styles.item_name}>{translate('operations.params.USER.userName')}</span>
              <span className={styles.item_value}><ReferenceField reference={'user'} source={'owner'} link={(record: any) => `/user/${record.owner}/show`}><FunctionField render={(record: IProfile) => <div className={styles.user_field}><span>{`${record.sn ? record.sn : ''} ${record.givenName}`}</span></div>} /></ReferenceField></span>
            </div>
            <div className={styles.item}>
              <span className={styles.item_name}>{translate('operations.params.USER.type')}</span>
              <span className={styles.item_value}><ReferenceField reference={'user'} source={'owner'} link={false}><FunctionField render={(record: IProfile) => (USER_ROLES[record.role])} /></ReferenceField></span>
            </div>
            <div className={styles.item}>
              <span className={styles.item_name}>{translate('operations.params.USER.department')}</span>
              <span className={styles.item_value}><ReferenceField reference={'user'} source={'owner'} link={false}><TextField source="department" /></ReferenceField></span>
            </div>
            <div className={styles.item}>
              <span className={styles.item_name}>{translate('operations.params.USER.position')}</span>
              <span className={styles.item_value}><ReferenceField reference={'user'} source={'owner'} link={false}><TextField source="title" /></ReferenceField></span>
            </div>
          </div>
        </CardContent>
      </Card>
    </ShowBase>
  )
};

export default ApprovalUserCard;