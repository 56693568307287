import React from "react";
import {Checkbox} from "@mui/material";
import './CustomCheckbox.css';

type CustomCheckboxProps = {
  checked: boolean,
  change: (e: any) => void,
  indeterminate: boolean,
  around: boolean,
}

const CustomCheckbox = (props: CustomCheckboxProps) => {
  const {checked, change, indeterminate, around} = props;
  return (
    <div className={'custom_checkbox'}>
      <Checkbox
        checked={checked}
        onChange={change}
        indeterminate={indeterminate}
        icon={
          around ? <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="12" cy="12" r="8.5" fill="white" stroke="#E8EAED"/>
            </svg> :
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <mask id="path-1-inside-1_1_958" fill="white">
                <path fillRule="evenodd" clipRule="evenodd" d="M15.8997 21H8.0985C5.0484 21 3 18.8598 3 15.6756V8.3244C3 5.1402 5.0484 3 8.0985 3H15.9006C18.9507 3 21 5.1402 21 8.3244V15.6756C21 18.8598 18.9507 21 15.8997 21Z"/>
              </mask>
              <path d="M15.8997 20H8.0985V22H15.8997V20ZM8.0985 20C6.81612 20 5.81267 19.5556 5.12777 18.8403C4.43924 18.1212 4 17.0513 4 15.6756H2C2 17.4841 2.58496 19.0764 3.68315 20.2234C4.78498 21.3743 6.33077 22 8.0985 22V20ZM4 15.6756V8.3244H2V15.6756H4ZM4 8.3244C4 6.94875 4.43924 5.87884 5.12777 5.15969C5.81266 4.44436 6.81612 4 8.0985 4V2C6.33078 2 4.78498 2.62574 3.68315 3.77656C2.58496 4.92356 2 6.51585 2 8.3244H4ZM8.0985 4H15.9006V2H8.0985V4ZM15.9006 4C17.183 4 18.1867 4.44437 18.8719 5.15978C19.5606 5.87897 20 6.94887 20 8.3244H22C22 6.51573 21.4147 4.92343 20.3163 3.77647C19.2143 2.62573 17.6683 2 15.9006 2V4ZM20 8.3244V15.6756H22V8.3244H20ZM20 15.6756C20 17.0511 19.5606 18.121 18.8718 18.8402C18.1866 19.5556 17.1826 20 15.8997 20V22C17.6678 22 19.214 21.3743 20.3162 20.2236C21.4147 19.0766 22 17.4843 22 15.6756H20Z" fill="#E8EAED" mask="url(#path-1-inside-1_1_958)"/>
            </svg>
        }
        checkedIcon={ around ?
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="9" fill="black"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.8132 15.1227C10.6222 15.1227 10.4292 15.0497 10.2832 14.9027L7.9092 12.5297C7.6162 12.2367 7.6162 11.7627 7.9092 11.4697C8.2022 11.1767 8.6762 11.1767 8.9692 11.4697L10.8132 13.3117L15.0292 9.0967C15.3222 8.8037 15.7962 8.8037 16.0892 9.0967C16.3822 9.3897 16.3822 9.8637 16.0892 10.1567L11.3432 14.9027C11.1972 15.0497 11.0052 15.1227 10.8132 15.1227Z" fill="white"/>
          </svg> :
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M15.8997 21H8.0985C5.0484 21 3 18.8598 3 15.6756V8.3244C3 5.1402 5.0484 3 8.0985 3H15.9006C18.9507 3 21 5.1402 21 8.3244V15.6756C21 18.8598 18.9507 21 15.8997 21Z" fill="black"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.8132 15.1227C10.6222 15.1227 10.4292 15.0497 10.2832 14.9027L7.9092 12.5297C7.6162 12.2367 7.6162 11.7627 7.9092 11.4697C8.2022 11.1767 8.6762 11.1767 8.9692 11.4697L10.8132 13.3117L15.0292 9.0967C15.3222 8.8037 15.7962 8.8037 16.0892 9.0967C16.3822 9.3897 16.3822 9.8637 16.0892 10.1567L11.3432 14.9027C11.1972 15.0497 11.0052 15.1227 10.8132 15.1227Z" fill="white"/>
          </svg>
        }
        indeterminateIcon={
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M15.8997 21H8.0985C5.0484 21 3 18.8598 3 15.6756V8.3244C3 5.1402 5.0484 3 8.0985 3H15.9006C18.9507 3 21 5.1402 21 8.3244V15.6756C21 18.8598 18.9507 21 15.8997 21Z" fill="black"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M15.0624 12.75H8.93765C8.42007 12.75 8 12.414 8 12C8 11.586 8.42007 11.25 8.93765 11.25H15.0624C15.5799 11.25 16 11.586 16 12C16 12.414 15.5799 12.75 15.0624 12.75Z" fill="white"/>
          </svg>

        }
      />
    </div>
  )
}

export default CustomCheckbox;