import React from "react";
import { useNavigate } from "react-router-dom";
import { useDataProvider, useGetOne, useNotify, useRedirect, useTranslate } from "react-admin";
import { Card, CardContent } from '@mui/material';
import styles from './EditPasswordPage.module.css';
import CustomTooltip from "../../tooltips/customTooltip/CustomTooltip";
import backIcon from "../../../assets/Back.png";
import CancelButton from "../../buttons/cancelButton/CancelButton";
import CustomButton from "../../buttons/customButton/CustomButton";
import CustomInputPassword from "../../inputs/customInputPassword/CustomInputPassword";
import { IProfile } from "../../../utils/types";
import { fetchProvider } from "../../../provider/authProvider";

type EditPasswordPageProps = {
  profile: IProfile
}

const EditPasswordPage = (props: EditPasswordPageProps) => {
  const { profile } = props;
  const navigate = useNavigate();
  const providerData = useDataProvider();
  const [password, setPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [validNewPassword, setValidNewPassword] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [retryPassword, setRetryPassword] = React.useState('');
  const [pwdLength, setPwdLength] = React.useState('');
  const [pwdNumber, setPwdNumber] = React.useState('');
  const [pwdSpecChar, setPwdSpecChar] = React.useState('');
  const [pwdUppercase, setPwdUppercase] = React.useState('');
  const translate = useTranslate();

  const onComeBack = () => {
    navigate(-1);
  }

  const onChangePassword = (v: string) => {
    setPassword(v);
  }

  const UseDebounce = (value: string, delay: number) => {
    const [debounceValue, setDebounceValue] = React.useState(value);

    React.useEffect(() => {
      const handler = setTimeout(() => {
        setDebounceValue(value);
      }, delay);
      return () => clearTimeout(handler);
    }, [value, delay]);

    return debounceValue;
  }

  const debounceNewPassword = UseDebounce(newPassword, 2000);

  React.useEffect(() => {
    if (password && password === debounceNewPassword) {
      setValidNewPassword(false);
      setErrorMessage(translate('operations.params.PWD.pwdMatches'));
    } else if (newPassword) {
      fetchProvider(`/password/check?password=${debounceNewPassword}`)
        .then(res => {
          if (!res.ok) throw res
        })
        .catch(error => {
          error.text().then((err: any) => {
            setValidNewPassword(false);
            const message = JSON.parse(err).error
            const value = JSON.parse(err).value
            if (message === 'USER_PWD_SPEC_CHAR') {
              setErrorMessage(translate('operations.params.PWD.pwdSpecialCharacters', { value: value }));
            } else if (message === 'USER_PWD_INVALID_CHAR') {
              setErrorMessage(translate('operations.params.PWD.pwdForbiddenCharacters'));
            } else if (message === 'USER_PWD_LENGTH') {
              setErrorMessage(translate('operations.params.PWD.pwdLength', { value: value }));
            } else if (message === 'USER_PWD_UPPERCASE') {
              setErrorMessage(translate('operations.params.PWD.pwdUppercase', { value: value }));
            } else if (message === 'USER_PWD_NUMBER') {
              setErrorMessage(translate('operations.params.PWD.pwdNumber', { value: value }));
            } else
              setErrorMessage(translate('operations.params.PWD.pwdPolicyRequirements'));
          })
        })
      setValidNewPassword(true);
      setErrorMessage('');
    }
    // eslint-disable-next-line
  }, [debounceNewPassword])

  useGetOne('settings', { id: 'pwd' }, {
    onSuccess: (data) => {
      setPwdLength(data.settings.USER_PWD_LENGTH);
      setPwdNumber(data.settings.USER_PWD_NUMBER);
      setPwdSpecChar(data.settings.USER_PWD_SPEC_CHAR);
      setPwdUppercase(data.settings.USER_PWD_UPPERCASE);
    }
  })

  async function onChangeNewPassword(v: string) {
    setNewPassword(v)
  }

  const onChangeRetryPassword = (v: string) => {
    setRetryPassword(v);
  }

  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify('ra.notification.updated', { type: 'success', messageArgs: { smart_count: 1 }, undoable: true });
    redirect('show', 'user', profile.id);
  };

  const savePassword = () => {
    if (newPassword !== retryPassword) {
      return notify('ra.notification.bad_item', { type: 'warning' });
    }

    providerData
      .update('user', { id: profile.id, data: { unicodePwd: newPassword, unicodePwdCur: password }, previousData: profile })
      .then(onSuccess)
      .catch((error) => notify(error.body.code === 1 ? 'ra.notification.pwdError' : error.body.code === 2 ? 'ra.notification.pwdNewError' : 'ra.notification.canceled', { type: 'warning' }))
  }

  const validation = () => {
    return Boolean(password.length) && validNewPassword && newPassword === retryPassword;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.root}>
        <header>
          <CustomTooltip title={translate('ra.action.comeback')}>
            <div className={styles.back_icon} onClick={onComeBack}>
              <img src={backIcon} alt="icon" />
            </div>
          </CustomTooltip>
          <h1>{translate('operations.params.PWD.changePassword')}</h1>
        </header>
        <Card>
          <CardContent>
            <p>{translate('operations.params.PWD.pwdContent', {length: pwdLength, number: pwdNumber, uppercase: pwdUppercase, specchar: pwdSpecChar})}</p>
            <div className={styles.row_item}>
              <span className={styles.row_item__label}>{translate('operations.params.PWD.unicodePwdCur')}</span>
              <CustomInputPassword placeholder={translate('operations.params.PWD.enterThePassword')} mode={'root'} value={password} changeInput={onChangePassword} errorMessage={''} focus={false} />
            </div>
            <div className={styles.row_item}>
              <span className={styles.row_item__label}>{translate('operations.params.PWD.newPassword')}</span>
              <CustomInputPassword placeholder={translate('operations.params.PWD.enterThePassword')} mode={validNewPassword ? 'root' : 'error'} value={newPassword} changeInput={onChangeNewPassword} errorMessage={errorMessage} focus={false} />
            </div>
            <div className={styles.row_item}>
              <span className={styles.row_item__label}>{translate('operations.params.PWD.repeatTheNewPassword')}</span>
              <CustomInputPassword placeholder={translate('operations.params.PWD.enterThePassword')} mode={newPassword === retryPassword || retryPassword === '' ? 'root' : 'error'} value={retryPassword} changeInput={onChangeRetryPassword} errorMessage={translate('operations.params.PWD.pwdNotMatch')} focus={false} />
            </div>
            <div className={styles.buttons}>
              <CancelButton text={translate('ra.action.abort')} onClick={onComeBack} />
              <CustomButton text={translate('ra.action.change')} onClick={savePassword} disable={!validation()} />
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

export default EditPasswordPage;