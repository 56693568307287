import React from "react";
import {
	useDataProvider, useNotify,
	useRefresh, useTranslate,
} from "react-admin";
import styles from './RemoveRoleAccessModal.module.css';
import CloseIcon from "../../../assets/close.png";
import CancelButton from "../../buttons/cancelButton/CancelButton";
import CustomButton from "../../buttons/customButton/CustomButton";
import { IProfile, IRole } from "../../../utils/types";
import CustomTextArea from '../../inputs/customTextArea/CustomTextArea';
import {transformRoleName} from "../../../utils/utils";

type RemoveRoleAccessModalProps = {
	closeModal: () => void,
	record: IRole,
	user: IProfile
}

const RemoveRoleAccessModal = (props: RemoveRoleAccessModalProps) => {
	const { closeModal, record, user } = props;
	const dataProvider = useDataProvider();
	const refresh = useRefresh();
	const translate = useTranslate();
	const notify = useNotify();
	const group = record?.userGroup;

	const steps = ['choose', 'confirm'];
	const [step, setStep] = React.useState<string>(steps[0])
	const [adminsApp, setAdminsApp] = React.useState<string[]>([]);
	const [adminsRole, setAdminsRole] = React.useState<string[]>([]);
	const [usersRole, setUsersRole] = React.useState<string[]>([]);
	const [comment, setComment] = React.useState('');

	const onChangeCause = (v: string) => {
		setComment(v);
	}

	const onRemoveAccess = () => {
		dataProvider.update('group', {
			id: group, data: {
				member: {
					delete: user?.id
				},
				comment: comment
			}, previousData: {}
		})
			.then(() => {
				refresh();
				notify('ra.notification.revoked', { type: 'success', messageArgs: { smart_count: 1 }, undoable: true });
			});
		closeModal();
	}

	const checkGroup = async () => {
		const role = await dataProvider.getOne('depsRole', { id: record.id })
		setUsersRole(role.data.roles);
		setAdminsRole(role.data.roleAdmins);
		setAdminsApp(role.data.appAdmins);
		setStep(steps[1]);
	}

	return (
		step === steps[0] ?
			<div className={styles.root}>
				<div className={styles.close_button} onClick={closeModal}>
					<img src={CloseIcon} alt="" />
				</div>
				<h1 className={styles.header}>{translate('messages.revokeAccess')}</h1>
				<div className={styles.text}>{translate('messages.revokeAccessTo', { user: `${translate('messages.fromUser')} ${user.givenName && user.givenName.length ? user.givenName : user.id}`, name: record.name.length && record.name.length > 44 ? record.name.slice(0, 44).concat('...') : record.name })}</div>
				<div className={styles.buttons}>
					<CancelButton text={translate('ra.action.cancel')} onClick={closeModal} />
					<CustomButton text={translate('ra.action.confirm')} onClick={checkGroup} />
				</div>
			</div> :
			<div className={styles.root}>
				<div className={styles.close_button} onClick={closeModal}>
					<img src={CloseIcon} alt="" />
				</div>
				<span className={styles.title}>{translate('messages.revokeAccess')}</span>
				{record.name && (usersRole.length || adminsApp.length || adminsRole.length)
					? <>
						<div className={styles.text}>{translate('messages.revokeAccessRoleMessage', { role: record.name })}</div>
						<ul className={styles.list}>
							{usersRole.length ? usersRole.map((item: string) => <li className={styles.list_item} key={item}>{transformRoleName(item, 40)}</li>) : null}
							{adminsApp.length ? adminsApp.map((item: string) => <li className={styles.list_item} key={item}>{`${translate('messages.appAdministration')} ${transformRoleName(item, 40)}`}</li>) : null}
							{adminsRole.length ? adminsRole.map((item: string) => <li className={styles.list_item} key={item}>{`${translate('messages.roleAdministration')} ${transformRoleName(item, 40)}`}</li>) : null}
						</ul>
					</>
					: <></>
				}
				<h1 className={styles.header_textarea}>{translate('messages.inputReason')}</h1>
				<CustomTextArea placeholder={translate('messages.commentText')} mode={'root'} value={comment} changeInput={onChangeCause} limit={255} focus={false} />
				<div className={styles.buttons}>
					<CancelButton text={translate('ra.action.cancel')} onClick={closeModal} />
					<CustomButton text={translate('ra.action.confirm')} onClick={() => onRemoveAccess()} />
				</div>
			</div>
	)
}

export default RemoveRoleAccessModal;