import React from "react";
import { useTranslate } from "react-admin";
import styles from './ChangePhoneMenu.module.css';
import CustomPopoverMenuItem from "../customPopoverMenuItem/CustomPopoverMenuItem";

type ChangePhoneMenuProps = {
  closePopover: () => void,
  onChange: () => void,
}

const ChangePhoneMenu = (props: ChangePhoneMenuProps) => {
  const {closePopover, onChange} = props;
  const translate = useTranslate();
  const onClickWrapper = (e: any) => {
    e.stopPropagation();
  }

  const editPhone = () => {
    onChange();
    closePopover();
  }

  return (
    <div className={styles.root} onClick={onClickWrapper}>
      <div className={styles.middle}>
        <CustomPopoverMenuItem text={translate('ra.action.change')} onClick={editPhone}/>
      </div>
    </div>
  )
}

export default ChangePhoneMenu;