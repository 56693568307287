import React from "react";
import {
	useRecordContext,
	ShowBase,
	TextField,
	ReferenceField,
	FunctionField,
	useGetIdentity,
	useTranslate
} from "react-admin";
import { Card, CardContent } from '@mui/material';
import styles from './ApprovalCard.module.css';
import { IApproval, IProfile } from "../../../utils/types";
import AvatarIcon from "../../../assets/avatar.png";
import ApprovalIcon from '../../../assets/Done.png';
import CancelIcon from '../../../assets/closeBlack.png';
import { dateFormat } from "../../../utils/utils";

type ApprovalCardProps = {
	onConfirm: () => void,
	onCancel: () => void,
	editable: boolean
}

const ApprovalCard = (props: ApprovalCardProps) => {
	const { onConfirm, onCancel, editable } = props;
	const approval: IApproval = useRecordContext();
	const translate = useTranslate();
	const { identity: user } = useGetIdentity();

	return (
		<ShowBase>
			<Card>
				<CardContent>
					<div className={styles.root}>
						{
							approval.status === 'pending' && editable ?
								<div className={styles.confirm_buttons}>
									<div className={styles.button_approval} onClick={onConfirm}>
										<img src={ApprovalIcon} alt="" className={styles.button_icon} />
										<span>{translate('ra.action.approve')}</span>
									</div>
									<div className={styles.button_cancel} onClick={onCancel}>
										<img src={CancelIcon} alt="" className={styles.button_icon} />
										<span>{translate('ra.action.deny')}</span>
									</div>
								</div> : null
						}
						<h1 className={styles.header}>{translate('operations.params.APPROVAL.info')}</h1>
						<div className={styles.item}>
							<span className={styles.item_name}>{translate('operations.params.APPROVAL.type')}</span>
							<span className={styles.item_value}>{{ 'photo': translate('operations.params.APPROVAL.changePhoto'), 'access': translate('operations.params.APPROVAL.forAccess') }[approval.type] || ''}</span>
						</div>
						{
							approval.type === 'access' ?
								<div className={styles.item}>
									<span className={styles.item_name}>{translate('operations.params.APPROVAL.role')}</span>
									<span className={styles.item_value}><ReferenceField reference={'role'} source={'target'} link={'show'}><TextField source={'name'} /></ReferenceField></span>
								</div> : null
						}
						<div className={styles.item}>
							<span className={styles.item_name}>{translate('operations.params.APPROVAL.createdAt')}</span>
							<span className={styles.item_value}><FunctionField source={'createdAt'} render={(record: IApproval) => dateFormat(record.createdAt, { showTime: true, showMilliseconds: true })} /></span>
						</div>
						<div className={styles.item}>
							<span className={styles.item_name}>{translate('operations.params.APPROVAL.statusReq')}</span>
							<span className={styles.item_value}>{{ 'pending': <div className={styles.status_pending}><div /><span>{translate('operations.params.APPROVAL.pending')}</span></div>, 'approved': <div className={styles.status_approved}><div /><span>{translate('operations.params.APPROVAL.approved')}</span></div>, 'canceled': <div className={styles.status_canceled}><div /><span>{translate('operations.params.APPROVAL.canceled')}</span></div> }[approval.status]}</span>
						</div>
						<div className={styles.item}>
							<span className={styles.item_name}>{translate('operations.params.APPROVAL.reqAuthor')}</span>
							<span className={styles.item_value}><ReferenceField reference={'user'} source={'owner'} link={(record: any) => `/user/${record.owner}/show`}><FunctionField render={(record: IProfile) => <div className={styles.user_field}><span>{`${record.sn ? record.sn : ''} ${record.givenName}`}</span></div>} /></ReferenceField></span>
						</div>
						{
							approval.type === 'access' ?
								<div className={styles.item}>
									<span className={styles.item_name}>{translate('operations.params.APPROVAL.authorComment')}</span>
									<span className={styles.item_value}>{approval.description}</span>
								</div> : null
						}
						{
							approval.type === 'photo' && approval.status === 'pending' ?
								<div className={styles.item}>
									<span className={styles.item_name}>{translate('operations.params.APPROVAL.prevPhoto')}</span>
									<span className={styles.item_value}><ReferenceField reference={'user'} source={'owner'} link={(record: any) => `/user/${record.owner}/show`}><FunctionField render={(record: IProfile) => <div className={styles.user_field}><div className={styles.icon} style={{ backgroundImage: `url(${record.photo && record.photo.length ? record.photo : AvatarIcon})`, backgroundPosition: 'center center', backgroundSize: 'cover' }} /></div>} /></ReferenceField></span>
								</div> : null
						}
						{
							approval.type === 'photo' ?
								<div className={styles.item}>
									<span className={styles.item_name}>{translate('operations.params.APPROVAL.newPhoto')}</span>
									<span className={styles.item_value}><div className={styles.user_field}><div className={styles.icon} style={{ backgroundImage: `url(${approval.target && approval.target.length ? approval.target : AvatarIcon})`, backgroundPosition: 'center center', backgroundSize: 'cover' }} /></div></span>
								</div> : null
						}
						{
							approval.status !== 'pending' ?
								<div className={styles.item}>
									<span className={styles.item_name}>{translate('operations.params.APPROVAL.executor')}</span>
									<span className={styles.item_value}><ReferenceField reference={'user'} source={'decider'} emptyText={translate('operations.params.APPROVAL.notFound')} link={(record: any) => user?.role === 1 ? `/user/${record.decider}/show` : ''}><FunctionField render={(record: IProfile) => <div className={styles.user_field}><span>{`${record.sn ? record.sn : ''} ${record.givenName}`}</span></div>} /></ReferenceField></span>
								</div> : null
						}
						{
							approval.status === 'canceled' ?
								<div className={styles.item}>
									<span className={styles.item_name}>{translate('operations.params.APPROVAL.reason')}</span>
									<span className={styles.item_value}>{approval.comment}</span>
								</div> : null
						}
						{
							approval.updatedAt !== approval.createdAt ?
								<div className={styles.item}>
									<span className={styles.item_name}>{translate('operations.params.APPROVAL.updatedAtReq')}</span>
									<span className={styles.item_value}><FunctionField source={'updatedAt'} render={(record: IApproval) => dateFormat(record.updatedAt, { showTime: true, showMilliseconds: true })} /></span>
								</div> : null
						}
					</div>
				</CardContent>
			</Card>
		</ShowBase>
	)
}

export default ApprovalCard;