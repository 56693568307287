import React from 'react';
import styles from './CustomPopoverMenuItem.module.css';

type CustomPopoverMenuItemProps = {
  text: string,
  onClick: () => void,
  disable?: boolean,
  icon?: JSX.Element | null,
}

const CustomPopoverMenuItem = (props: CustomPopoverMenuItemProps) => {
  const {text, onClick, disable, icon} = props;
  const onClickWrapper = (e: any) => {
    e.stopPropagation();
    if (onClick) onClick();
  }
  return (
    <div className={disable ? `${styles.custom_menu_item_popover} ${styles.disabled}` : styles.custom_menu_item_popover} onClick={onClickWrapper}>
      {
        icon ?
          <div className={styles.icon}>
            {icon}
          </div> : null
      }
      <div className={styles.custom_menu_item_popover__text}>
        <span>{text}</span>
      </div>
    </div>
  )
}

export default CustomPopoverMenuItem;