import React from "react";
import {useRefresh, useNotify, useDelete, useRedirect, useTranslate} from "react-admin";
import styles from './RemoveRoleModal.module.css';
import CloseIcon from "../../../assets/close.png";
import CancelButton from "../../buttons/cancelButton/CancelButton";
import CustomButton from "../../buttons/customButton/CustomButton";
import { IRole } from "../../../utils/types";

type RemoveRoleModalProps = {
  closeModal: () => void,
  record: IRole,
}

const RemoveRoleModal = (props: RemoveRoleModalProps) => {
  const {closeModal, record} = props;
  const refresh = useRefresh();
  const notify = useNotify();
  const redirect = useRedirect();
  const translate = useTranslate();
  const [deleteOne] = useDelete(
    'role',
    {id: record?.id, previousData: record},
    {
      mutationMode: 'undoable',
      onSuccess: () => {
        redirect('show', 'app', record.appId);
        notify('ra.notification.deleted', { type: 'success', messageArgs: { smart_count: 1 }, undoable: true });
        refresh();
      },
      onError: () => notify('ra.notification.canceled', { type: 'warning' })
    }
  );

  const onRemoveRole = () => {
    deleteOne();
    closeModal();
  }

  return (
    <div className={styles.root}>
      <div className={styles.close_button} onClick={closeModal}>
        <img src={CloseIcon} alt=""/>
      </div>
      <h1 className={styles.header}>{translate('messages.removeRoleTitle')}</h1>
      {
        record.deletable ?
          <div className={styles.wrapper}>
            <span className={styles.text}>{translate('messages.removeRole_')}</span>
            <span className={styles.role_name}>{`${record.name}?`}</span>
            <div className={styles.buttons}>
              <CancelButton text={translate('ra.action.cancel')} onClick={closeModal} />
              <CustomButton text={translate('ra.action.remove')} onClick={onRemoveRole}/>
            </div>
          </div> :
          <div className={styles.wrapper}>
            <span className={styles.text}>
              {translate('messages.theRoleIsAssignedToUsers_', { name: record.name})}
            </span>
            <span className={styles.text}>{translate('messages.removeRole')}</span>
            <span className={styles.text}>{translate('messages.activeDirectoryGroupsRemain')}</span>
            <div className={styles.buttons}>
              <CancelButton text={translate('ra.action.cancel')} onClick={closeModal} />
              <CustomButton text={translate('ra.action.remove')} onClick={onRemoveRole}/>
            </div>
          </div>
      }
    </div>
  )
}

export default RemoveRoleModal;