import React from "react";
import { useDataProvider, useTranslate } from "react-admin";
import styles from './SelectRolesModal.module.css';
import CustomInput from "../../inputs/customInput/CustomInput";
import CancelButton from "../../buttons/cancelButton/CancelButton";
import CustomButton from "../../buttons/customButton/CustomButton";
import CloseIcon from "../../../assets/close.png";
import UserItem from "../../listItem/userItem/UserItem";
import {IAccessRoles, IRole} from "../../../utils/types";
import CustomRadioButton from "../../inputs/customRadioButton/CustomRadioButton";
import CustomDatePicker from "../../inputs/customDatePicker/CustomDatePicker";
import { transformRoleName } from "../../../utils/utils";

type SelectRolesModalProps = {
  close: () => void,
  roles: IRole[],
  onSelect: (v: IRole[]) => void,
  date?: Date | null,
  changeDate?: (v: Date | null) => void
}

const UseDebounce = (value: string, delay: number) => {
  const [debounceValue, setDebounceValue] = React.useState(value);

  React.useEffect(()  => {
    const handler = setTimeout(() => {
      setDebounceValue(value);
    }, delay);
    return () => clearTimeout(handler);
  }, [value, delay]);

  return debounceValue;
}

const SelectRolesModal = (props: SelectRolesModalProps) => {
  const {close, roles, onSelect, date, changeDate} = props;
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const steps = ['choose', 'confirm'];
  const [search, setSearch] = React.useState('');
  const [data, setData] = React.useState<IRole[]>([]);
  const [selected, setSelected] = React.useState<IRole[]>([]);
  const [limit, setLimit] = React.useState(Boolean(date));
  const [limitDate, setLimitDate] = React.useState<Date | null>(date ? date : null);
  const [step, setStep] = React.useState<string>(steps[0])
  const [rolesDepsList, setRolesDepsList] = React.useState<IAccessRoles[]>();

  const onChangeLimit = (v:string) => {
    setLimit(v === translate('messages.limitExpiration'));
  }

  const onChangeLimitDate = (v: Date | null) => {
    setLimitDate(v);
  }

  const onChangeSearch = (v: string) => {
    setSearch(v);
  }

  const onChangeSelectedAdmins = (v:boolean, id: string | number) => {
    if (!v) setSelected((prev: IRole[]) => prev.filter((item) => item.id !== id));
    else setSelected((prev: IRole[]) => [...prev, data.filter((item: IRole) => item.id === id)[0]]);
  }

  const checkRoles = async () => {
    const accessList: any = await Promise.all(
      selected.map((role: IRole) => new Promise(async (resolve) => {
        const roleDeps = await dataProvider.getOne('depsRole', {id: role.id});
        resolve({
          role,
          appAdminsList: roleDeps.data.appAdmins,
          roleAdminsList: roleDeps.data.roleAdmins,
          roleUsersList: roleDeps.data.roles,
        });
      }))
    )
    setRolesDepsList(accessList);
    if (accessList.reduce((result: boolean, item: IAccessRoles) => result || item.roleUsersList.length || item.appAdminsList.length || item.roleAdminsList.length, false)) setStep(steps[1]);
    else onSelectRoles();
  }

  const onComeBack = () => {
    setStep(steps[0]);
  }

  const onSelectRoles = () => {
    if (changeDate && limit) changeDate(limitDate);
    else if (changeDate && !limit) changeDate(null);
    onSelect(selected);
    close();
  }

  const debounceSearch = UseDebounce(search, 1000);

  React.useEffect(() => {
    dataProvider.getList('role', {sort: {field: "appId", order: "ASC"}, pagination: {page: 1, perPage: 1000}, filter: {q: debounceSearch}})
      .then(res => {
        setData(res.data.filter((item: IRole) => item.editable));
      })
  },[debounceSearch, dataProvider])

  React.useEffect(() => {
    if (data && !selected.length) {
      const arr = data
        .filter((item: IRole) => item.id && roles.map((item: IRole) => item.id).includes(item.id));
      setSelected(arr);
    }
    // eslint-disable-next-line
  }, [data, roles]);

  return (
    step === steps[0] ?
    <div className={styles.root}>
      <div className={styles.close_button} onClick={close}>
        <img src={CloseIcon} alt=""/>
      </div>
      <span className={styles.title}>{translate('messages.addRole')}</span>
      <CustomInput placeholder={translate('ra.action.search')} mode={'root'} value={search} changeInput={onChangeSearch} errorMessage={''} focus={false} />
      <div className={styles.table}>
        <div className={styles.table_header}>
          <span>{translate('operations.params.USER.givenName')}</span>
        </div>
        <div className={styles.table_body}>
          {
            data.length ? data.map((item: any) => (
              <UserItem selected={selected.map((item: IRole) => item.id).includes(item.id)} onChange={onChangeSelectedAdmins} text={item.fullName} id={item.id} key={item.id}/>
            )) : <span className={styles.not_found}>{translate('messages.notFound')}</span>
          }
        </div>
      </div>
      <div className={styles.limits}>
        <span>{translate('messages.expirationDate')}</span>
        <CustomRadioButton
          list={[translate('messages.notLimitExpiration'), translate('messages.limitExpiration')]}
          value={limit ? translate('messages.limitExpiration') : translate('messages.notLimitExpiration')}
          changeValue={onChangeLimit}
          directionHorizontal={false}
        />
      </div>
      {
        limit ?
          <div className={styles.input_date}>
            <span>{translate('messages.expirationDateTo')}</span>
            <CustomDatePicker onChangeDate={onChangeLimitDate} date={limitDate ?? new Date()}/>
          </div> : null
      }
      <div className={styles.buttons}>
        <CancelButton text={translate('ra.action.cancel')} onClick={close} />
        <CustomButton text={translate('ra.action.choose')} onClick={checkRoles}/>
      </div>
    </div> :
      <div className={styles.root}>
        <div className={styles.close_button} onClick={close}>
          <img src={CloseIcon} alt="" />
        </div>
        <span className={styles.title}>{translate('messages.giveAccess')}</span>
        {
          rolesDepsList!.map((roleItem: IAccessRoles) => (
            roleItem.roleUsersList.length || roleItem.appAdminsList.length || roleItem.roleAdminsList.length ?
            <div className={styles.roles_wrapper}>
              <span className={styles.text}>{`${translate('messages.assigningRole')} ${transformRoleName(roleItem.role.name, 64)} ${translate('messages.userWillHaveAccessTo')}`}</span>
              <ul className={styles.list}>
                {roleItem.roleUsersList.length ? roleItem.roleUsersList.map((item: string) => <li className={styles.list_item} key={item}>{transformRoleName(item, 58)}</li>) : null}
                {roleItem.appAdminsList.length ? roleItem.appAdminsList.map((item: string) => <li className={styles.list_item} key={item}>{`${translate('messages.appAdministration')} ${transformRoleName(item, 58)}`}</li>) : null}
                {roleItem.roleAdminsList.length ? roleItem.roleAdminsList.map((item: string) => <li className={styles.list_item} key={item}>{`${translate('messages.roleAdministration')} ${transformRoleName(item, 58)}`}</li>) : null}
              </ul>
            </div> : null
          ))
        }
        <div className={styles.buttons_wrapper}>
          <CancelButton text={translate('ra.action.comeback')} onClick={onComeBack} />
          <div className={styles.buttons}>
            <CancelButton text={translate('ra.action.cancel')} onClick={close} />
            <CustomButton text={translate('ra.action.confirm')} onClick={onSelectRoles} />
          </div>
        </div>
      </div>
  )
}

export default SelectRolesModal;