import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useShowController, ShowBase, TabbedShowLayout, Tab, useGetIdentity, useTranslate, Loading } from "react-admin";
import styles from './ShowAppPage.module.css';
import CustomBreadcrumbs from "../../customBreadcrumbs/CustomBreadcrumbs";
import AppCard from "../../cards/appCard/AppCard";
import AppInfoCard from "../../cards/appInfoCard/AppInfoCard";
import AppAdminsPage from "../appAdminsPage/AppAdminsPage";
import AppRolesPage from "../appRolesPage/AppRolesPage";
import ProfileCard from "../../cards/profileCard/ProfileCard";
import MainInfoProfileCard from "../../cards/mainInfoProfileCard/MainInfoProfileCard";
import EmailCard from "../../cards/emailCard/EmailCard";
import PhoneCard from "../../cards/phoneCard/PhoneCard";
import PasswordCard from "../../cards/passwordCard/PasswordCard";
import EmploymentCard from "../../cards/employmentCard/EmploymentCard";
import RolePage from "../rolePage/RolePage";
import CreateRolePage from "../createRolePage/CreateRolePage";
import UserRolesPage from "../userRolesPage/UserRolesPage";
import UsersAppPage from "../usersAppPage/UsersAppPage";
import { IApp, IProfile, IRole } from "../../../utils/types";
import { fetchProvider } from "../../../provider/authProvider";

const ShowAppPage = () => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const route = decodeURI(pathname.split('show/')[1]);
	const { record } = useShowController();
	const translate = useTranslate();
	const { identity: user, isLoading } = useGetIdentity();
	const [app, setApp] = React.useState<IApp>({} as IApp)
	const [admin, setAdmin]: any = React.useState<IProfile>({} as IProfile);
	const [role, setRole]: any = React.useState<IRole>({} as IRole);
	const [parentId, setParentId]: any = React.useState(Number(localStorage.getItem('parentId')));
	const [loading, setLoading] = React.useState(false);
	const roleId = route && route.includes('role') && !route.includes('create') && !route.includes('admin') ? Number(route.split('role/')[1].split('/')[0]) : 0;
	const adminId = route && route.includes('admin') ? route.split('/')[1] : '';

	React.useEffect(() => {
		if (record) setApp(record)
	}, [record]);

	React.useEffect(() => {
		if (roleId && roleId !== 0 && (!role.id || (role.id && role.id !== roleId))) {
			setLoading(true);
			fetchProvider(`/api/role/${roleId}`)
				.then(response => response.json())
				.then(data => {
					setRole(data);
					setLoading(false);
				})
				.catch(() => setLoading(false));
		}
		if (adminId !== '' && (!admin.id || (admin.id && admin.id !== adminId))) {
			setLoading(true);
			fetchProvider(`/api/user/${adminId}`)
				.then(response => response.json())
				.then(data => {
					setAdmin(data);
					setLoading(false);
				})
				.catch(() => setLoading(false));
		}
	}, [roleId, adminId, route]);

	const onNavigateBreadcrumbs = (e: any) => {
		if (e.target.innerText === translate('operations.entities.APP')) {
			navigate('/app');
			onChangeParentId(0)
		} else if (route && route.includes('role') && role && e.target.innerText === role.name) navigate(pathname.replace('/settings', ''));
		else if (parentId !== 0) onChangeParentId(0);
		else navigate(`${pathname.split('show/')[0]}show`);
	}

	const getBreadcrumbs = (): string => {
		if (route && route.includes('admin')) return `${admin ? admin.givenName : route.split('/')[1]},${app.name}`;
		else if (route && route.includes('role')) {
			if (route && route.includes('settings')) return `${translate('operations.params.APP.settings')},${role ? role.name : route.split('/')[1]},${app.name}`;
			else return route.split('/')[1] !== 'create' ? `${role ? role.name : route.split('/')[1]},${app.name}` : app.name
		}
		else if (route && route === '2' && parentId !== 0) return `${role ? role.name : parentId},${app.name}`;
		else return app.name;
	}

	const onChangeParentId = (v: number) => {
		setParentId(v);
		localStorage.setItem('parentId', String(v));
	}

	if ( !record || !user || isLoading || loading || (adminId !== '' && (!admin.id || (admin.id && admin.id !== adminId))) ) return <Loading />
	
	return (
		<div className={styles.wrapper}>
			<div className={styles.root}>
				<CustomBreadcrumbs breadcrumb={getBreadcrumbs()} onClickBreadcrumb={onNavigateBreadcrumbs} title={translate('operations.entities.APP')} />
				{
					route && (route.includes('settings') || route.includes('create')) ?
						<CreateRolePage /> :
						<ShowBase>
							{
								route && route.includes('admin') ?
									<TabbedShowLayout
										sx={{
											minWidth: "-webkit-fill-available",
											width: "-moz-available"
										}}
									>
										<Tab label={translate('operations.params.USER.userProfile')} path={`admin/${route.split('/')[1]}`} defaultChecked>
											<div className={styles.profile}>
												<h1>{translate('operations.entities.PROFILE')}</h1>
												<ProfileCard profile={admin} editable={admin.editable} deletable={app.editable || false} isAdmin={user?.role === 1 || app?.editable || false}/>
												<MainInfoProfileCard profile={admin} editable={(user?.role === 1 || app.editable || false) && admin.role === 4} />
												<EmailCard profile={admin} editable={((user?.role === 1 || app.editable || false) && admin.role === 4) || user?.id === admin.id} />
												<PhoneCard profile={admin} editable={((user?.role === 1 || app.editable || false) && admin.role === 4) || user?.id === admin.id} />
												{
													user?.id === admin.id ? <PasswordCard profile={admin} /> : null
												}
												<EmploymentCard profile={admin} />
											</div>
										</Tab>
										<Tab label={translate('operations.entities.ROLE')} path={`admin/${route.split('/')[1]}/roles`}>
											<UserRolesPage roles={admin.roles && admin.roles.length ? admin.roles : []} user={admin} />
										</Tab>
									</TabbedShowLayout> :
									route && route.includes('role') ?
										<TabbedShowLayout
											sx={{
												minWidth: "-webkit-fill-available",
												width: "-moz-available"
											}}
										>
											<Tab label={translate('operations.params.ROLE.roleProfile')} path={`role/${route.split('/')[1]}`} defaultChecked>
												<div className={styles.profile}>
													<RolePage id={route.split('/')[1]} getRole={setRole} userRole={user.role} />
												</div>
											</Tab>
											<Tab label={translate('operations.entities.ROLE')} path={`role/${route.split('/')[1]}/childs`}>
												<AppRolesPage app={app} parentId={Number(route.split('/')[1])} setParentId={onChangeParentId} />
											</Tab>
											{
												user.role === 1 || app.editable || role.editable ?
													<Tab label={translate('operations.entities.ADMINISTRATORS')} path={`role/${route.split('/')[1]}/admns`}>
														<AppAdminsPage app={app} role={role} context={'admin'} />
													</Tab>
													: null
											}
											{
												user.role === 1 || app.editable || role.editable ?
													<Tab label={translate('operations.entities.USER')} path={`role/${route.split('/')[1]}/users`}>
														<AppAdminsPage app={app} role={role} context={'user'} />
													</Tab>
													: null
											}
										</TabbedShowLayout> :
										<TabbedShowLayout
											sx={{
												'& .RaDatagrid-headerCell': {
													borderColor: "#e0e0e0",
													backgroundColor: "#fff0",
												},
												minWidth: "-webkit-fill-available",
												width: "-moz-available"
											}}
										>
											<Tab label={translate('operations.params.APP.applicationProfile')}>
												<div className={styles.profile}>
													<AppCard user={user} />
													<AppInfoCard />
												</div>
											</Tab>
											<Tab label={translate('operations.entities.ROLE')}>
												<AppRolesPage app={app} parentId={parentId} setParentId={onChangeParentId} />
											</Tab>
											{
												user.role === 1 || app.editable ?
													<Tab label={translate('operations.entities.ADMINISTRATORS')}>
														<AppAdminsPage app={app} context={'admin'} />
													</Tab>
													:
													null
											}
											{
												user.role === 1 || app.editable ?
													<Tab label={translate('operations.entities.USER')}>
														<UsersAppPage app={app} />
													</Tab>
													:
													null
											}
										</TabbedShowLayout>
							}
						</ShowBase>
				}
			</div>
		</div>
	)
}

export default ShowAppPage;