import React from "react";
import styles from './CustomBreadcrumbs.module.css';
import arrowRight from "../../assets/chevron_right.png";

type CustomBreadcrumbsProps = {
  breadcrumb: string,
  onClickBreadcrumb: (e: any) => void,
  title: string,
}

const CustomBreadcrumbs = (props: CustomBreadcrumbsProps) => {
  const {breadcrumb, onClickBreadcrumb, title} = props;
  return (
    <div className={styles.breadcrumbs}>
      <span className={styles.breadcrumb} onClick={onClickBreadcrumb}>{title}</span>
      {
        breadcrumb !== '.' ?
          breadcrumb.split(',').reverse().map((item: string) => (
            <div className={styles.breadcrumb} key={item}>
              <img src={arrowRight} alt="" />
              <span onClick={onClickBreadcrumb}>{item}</span>
            </div>
          ))
          : null
      }
    </div>
  )
}

export default CustomBreadcrumbs;