import React from "react";
import styles from './CustomInputItems.module.css';
import CustomModal from "../../modals/customModal/CustomModal";
import { IRole } from "../../../utils/types";
import { useTranslate } from 'react-admin';

type CustomInputGroupProps = {
  selected: IRole[],
  Modal: React.JSXElementConstructor<any>,
  modalProps: Object
}

const CustomInputGroup = (props: CustomInputGroupProps) => {
  const { selected, Modal, modalProps } = props;
  const [open, setOpen] = React.useState(false);
  const translate = useTranslate();

  const onClose = () => {
    setOpen(false);
  }

  const onOpen = () => {
    setOpen(true);
  }

  return (
    <div className={styles.root}>
      <div className={styles.input}>
        <span className={styles.button} onClick={onOpen}>{translate('ra.action.choose')}</span>
        {selected.length ?
          <span className={styles.chips}>{[selected[0].name, selected.length < 2 ? null : `${translate('messages.still')} ${selected.length - 1}`].filter(Boolean).map((item: any, i) => <span className={i > 0 ? styles.more_chip : styles.chip} key={item}>{item}</span>)}</span>
          : null
        }
      </div>
      <CustomModal
        open={open}
        close={setOpen}
        children={<Modal close={onClose} {...modalProps} />} />
    </div>
  )
}

export default CustomInputGroup;