import React from "react";
import {
	ShowBase,
	TabbedShowLayout,
	Tab,
	useShowController,
	useNotify,
	useRefresh,
	useGetIdentity,
	Loading,
	useTranslate
} from "react-admin";
import { useNavigate } from "react-router-dom";
import styles from './ShowApprovalPage.module.css';
import CustomBreadcrumbs from "../../customBreadcrumbs/CustomBreadcrumbs";
import ApprovalCard from "../../cards/approvalCard/ApprovalCard";
import CustomModal from "../../modals/customModal/CustomModal";
import CancelApprovalModal from "../../modals/cancelApprovalModal/CancelApprovalModal";
import ApprovalUserCard from "../../cards/approvalUserCard/ApprovalUserCard";
import dataProvider from "../../../provider/dataProvider";
import {IApp, IApproval, IRole} from "../../../utils/types";
import ConfirmApprovalModal from "../../modals/confirmApprovalModal/ConfirmApprovalModal";

const ShowApprovalPage = () => {
	const navigate = useNavigate();
	const { record, isLoading } = useShowController();
	const notify = useNotify();
	const refresh = useRefresh();
	const [roles, setRoles] = React.useState<IRole[]>([]);
	const [apps, setApps] = React.useState<IApp[]>([]);
	const [showCancelModal, setShowCancelModal] = React.useState(false);
	const [showConfirmModal, setShowConfirmModal] = React.useState(false);
	const [approval, setApproval] = React.useState<IApproval>();
	const [adminsApp, setAdminsApp] = React.useState<string[]>([]);
	const [adminsRole, setAdminsRole] = React.useState<string[]>([]);
	const [usersRole, setUsersRole] = React.useState<string[]>([]);
	const [role, setRole] = React.useState<IRole>();
	const { identity: user } = useGetIdentity();
	const translate = useTranslate();

	const onNavigateBreadcrumbs = (e: any) => {
		if (e.target.innerText === translate('operations.entities.APPROVAL')) {
			navigate(`/approval`);
		}
	}

	const checkRoles = () => {
		if (approval?.type === 'access') {
			if (record && roles?.length) {
				const role = roles.find((item: IRole) => item.id === Number(record.target));
				setRole(role);
				setShowConfirmModal(true);
			}
		} else onConfirmApproval('');
	}

	const onConfirmApproval = (v: string) => {
		dataProvider.update('approval', { id: approval!.id, data: { status: 'approved', comment: v }, previousData: approval })
			.then(() => {
				notify('ra.notification.approved', { type: 'success', messageArgs: { smart_count: 1 }, undoable: true });
				refresh();
			})
			.catch(() => notify('ra.notification.canceled', { type: 'warning' }))
	}

	const onCancelApproval = (v: string) => {
		dataProvider.update('approval', { id: approval!.id, data: { status: 'canceled', comment: v }, previousData: approval })
			.then(() => {
				notify('ra.notification.refused', { type: 'success', messageArgs: { smart_count: 1 }, undoable: true });
				refresh();
			})
			.catch(() => notify('ra.notification.canceled', { type: 'warning' }))
	}

	const onShowCancelModal = () => {
		setShowCancelModal(true);
	}

	const onCloseCancelModal = () => {
		setShowCancelModal(false);
	}

	const onCloseConfirmModal = () => {
		setShowConfirmModal(false);
	}

	const getPermissionAdminApp = (): boolean => {
		return apps ? apps.reduce((isAdmin, item) => isAdmin || (user?.memberOf && user?.memberOf.includes(item.adminGroup)), false) : false;
	}

	const getPermissionAdminRole = (): boolean => {
		return roles ? roles.reduce((isAdmin, item) => isAdmin || (user?.memberOf && user?.memberOf.includes(item.adminGroup)), false) : false;
	}

	React.useEffect(() => {
		dataProvider.getList('role', { sort: { field: "name", order: "ASC" }, pagination: { page: 1, perPage: 1000 }, filter: {} })
			.then(res => {
				setRoles(res.data);
			})
		dataProvider.getList('app', { sort: { field: "name", order: "ASC" }, pagination: { page: 1, perPage: 1000 }, filter: {} })
			.then(res => {
				setApps(res.data);
			})
	}, [])

	React.useEffect( () => {
		if (record) {
			setApproval(record);
			dataProvider.getOne('depsRole', {id: record.target})
				.then(({data}) => {
					setAdminsApp(data.appAdmins);
					setAdminsRole(data.roleAdmins);
					setUsersRole(data.roles);
				});
		}
	}, [record, roles])

	if ( !record || !approval || isLoading ) return <Loading />

	return (
		<div className={styles.root}>
			<CustomBreadcrumbs breadcrumb={{ 'mail': translate('operations.params.APPROVAL.requestToChangeMail'), 'photo': translate('operations.params.APPROVAL.changePhoto'), 'access': translate('operations.params.APPROVAL.forAccess')}[approval!.type] || ''} onClickBreadcrumb={onNavigateBreadcrumbs} title={translate('operations.entities.APPROVAL')} />
			<ShowBase>
				<TabbedShowLayout>
					<Tab label={translate('operations.params.APPROVAL.aboutTheRequest')}>
						<ApprovalCard onConfirm={checkRoles} onCancel={onShowCancelModal} editable={(user?.role === 1 || getPermissionAdminApp() || getPermissionAdminRole()) && approval!.owner !== user?.id} />
						<ApprovalUserCard />
					</Tab>
				</TabbedShowLayout>
			</ShowBase>
			<CustomModal
				open={showCancelModal}
				close={onCloseCancelModal}
			>
				<CancelApprovalModal
					closeModal={onCloseCancelModal}
					onCancelApproval={onCancelApproval}
				/>
			</CustomModal>
			<CustomModal
				open={showConfirmModal}
				close={onCloseConfirmModal}
			>
				<ConfirmApprovalModal
					closeModal={onCloseConfirmModal}
					onApprove={onConfirmApproval}
					adminsApp={adminsApp}
					adminsRole={adminsRole}
					usersRole={usersRole}
					role={role?.name || ''}
				/>
			</CustomModal>
		</div>
	)
}

export default ShowApprovalPage;