import React from "react";
import { TextField } from "@mui/material";
import './CustomInputPassword.css';
import CustomTooltip from "../../tooltips/customTooltip/CustomTooltip";
import ShowPassIcon from '../../../assets/глаз1.png';
import HidePassIcon from '../../../assets/глаз2.png';
import { useTranslate } from 'react-admin';

type CustomInputPasswordProps = {
  placeholder: string,
  mode: string,
  value: string,
  changeInput: (v: string) => void,
  errorMessage: string,
  focus: boolean,
}

const CustomInputPassword = (props: CustomInputPasswordProps) => {
  const { placeholder, mode, value, changeInput, errorMessage, focus } = props;
  const [showPass, setShowPass] = React.useState(false);

  const changeValue = (e: any) => {
    changeInput(e.target.value);
  };

  const changeShowPass = () => {
    setShowPass(!showPass);
  };

  const styledClassName: string = {
    'root': 'custom_input',
    'error': 'custom_input error_state',
    'success': 'custom_input success_state',
  }[mode] || 'custom_input';
  const translate = useTranslate();

  return (
    <div className={'custom_input_wrapper'}>
      <TextField
        className={styledClassName}
        variant="outlined"
        autoComplete="off"
        value={value ? value : ''}
        placeholder={placeholder}
        onChange={changeValue}
        type={showPass ? 'text' : 'password'}
        helperText={mode === 'error' ? errorMessage : ''}
        autoFocus={focus}
      />
      <CustomTooltip title={!showPass ? translate('operations.actions.SHOW') : translate('operations.actions.HIDE')}>
        <div
          className={'custom_password_input__icon'}
          onClick={changeShowPass}
        >
          <img src={!showPass ? ShowPassIcon : HidePassIcon} alt="" />
        </div>
      </CustomTooltip>
    </div >
  );
}

export default CustomInputPassword;