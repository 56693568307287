import React from "react";
import { useDataProvider, useListController, useUnselectAll, useNotify, useTranslate, useRefresh } from "react-admin";
import styles from './AddBulkRoleModal.module.css';
import CustomInput from "../../inputs/customInput/CustomInput";
import CancelButton from "../../buttons/cancelButton/CancelButton";
import CustomButton from "../../buttons/customButton/CustomButton";
import CloseIcon from "../../../assets/close.png";
import UserItem from "../../listItem/userItem/UserItem";
import { IAccessRoles, IRole } from "../../../utils/types";
import CustomRadioButton from "../../inputs/customRadioButton/CustomRadioButton";
import CustomDatePicker from "../../inputs/customDatePicker/CustomDatePicker";
import CustomTextArea from '../../inputs/customTextArea/CustomTextArea';
import { transformRoleName } from "../../../utils/utils";

type AddRBulkRoleModalProps = {
	closeModal: () => void,
}

const UseDebounce = (value: string, delay: number) => {
	const [debounceValue, setDebounceValue] = React.useState(value);

	React.useEffect(() => {
		const handler = setTimeout(() => {
			setDebounceValue(value);
		}, delay);
		return () => clearTimeout(handler);
	}, [value, delay]);

	return debounceValue;
}

const AddBulkRoleModal = (props: AddRBulkRoleModalProps) => {
	const { closeModal } = props;
	const dataProvider = useDataProvider();
	const refresh = useRefresh();
	const notify = useNotify();
	const translate = useTranslate();
	const steps = ['choose', 'confirm'];
	const { selectedIds } = useListController();
	const unselectAll = useUnselectAll('user');
	const [search, setSearch] = React.useState('');
	const [data, setData] = React.useState<IRole[]>([]);
	const [selected, setSelected] = React.useState<IRole[]>([]);
	const [limit, setLimit] = React.useState(false);
	const [limitDate, setLimitDate] = React.useState<Date | null>(null);
	const [step, setStep] = React.useState<string>(steps[0])
	const [rolesDepsList, setRolesDepsList] = React.useState<IAccessRoles[]>();
	const [comment, setComment] = React.useState('');
	const [loading, setLoading] = React.useState(false);

	const onChangeCause = (v: string) => {
		setComment(v);
	}

	const onChangeLimit = (v: string) => {
		setLimit(v === translate('messages.limitExpiration'));
	}

	const onChangeLimitDate = (v: Date | null) => {
		setLimitDate(v);
	}

	const onChangeSearch = (v: string) => {
		setSearch(v);
	}

	const onChangeSelectedRoles = (v: boolean, id: string | number) => {
		if (!v) setSelected((prev: IRole[]) => prev.filter((item: IRole) => item.id !== id));
		else setSelected((prev: IRole[]) => [...prev, ...data.filter((role: IRole) => role.id === id)]);
	}

	const checkRoles = async () => {
		const accessList: any = await Promise.all(
			selected.map((role: IRole) => new Promise(async (resolve) => {
				const roleDeps = await dataProvider.getOne('depsRole', { id: role.id });
				resolve({
					role,
					appAdminsList: roleDeps.data.appAdmins,
					roleAdminsList: roleDeps.data.roleAdmins,
					roleUsersList: roleDeps.data.roles,
				});
			}))
		)
		setRolesDepsList(accessList);
		if (accessList.reduce((result: boolean, item: IAccessRoles) => result || item.roleUsersList.length || item.appAdminsList.length || item.roleAdminsList.length, false)) setStep(steps[1]);
		else onAddRoles();
	}

	const onAddRoles = () => {
		if (selected.length) {
			selected.map((role: IRole) => {
				if (role.userGroup) {
					selectedIds.map((user: string) => {
						const data: any = {
							id: user
						}
						if (limit) {
							limitDate?.setHours(Math.abs(new Date().getTimezoneOffset() / 60));
							limitDate?.setMinutes(0);
							data.expiresAt = limitDate?.toISOString();
						}
						dataProvider.update('group', {
							id: role.userGroup, data: {
								member: {
									add: data
								},
								comment: comment
							}, previousData: {}
						})
							.then(() => {
								unselectAll();
								refresh();
								notify('ra.notification.rolesAdded', { type: 'success', messageArgs: { smart_count: 1 }, undoable: false });
							})
							.catch(() => notify('ra.notification.canceled', { type: 'warning' }))
						return false;
					})
				}
				return false;
			})
		}
		closeModal();
	}

	const onComeBack = () => {
		setStep(steps[0]);
	}

	const debounceSearch = UseDebounce(search, 1000);

	React.useEffect(() => {
		setLoading(true);
		dataProvider.getList('role', { sort: { field: "appId", order: "ASC" }, pagination: { page: 1, perPage: 1000 }, filter: { q: debounceSearch } })
			.then(res => {
				setData(res.data);
			})
			.finally(() => setLoading(false));
	}, [debounceSearch, dataProvider])

	return (
		step === steps[0] ?
			<div className={styles.root}>
				<div className={styles.close_button} onClick={closeModal}>
					<img src={CloseIcon} alt="" />
				</div>
				<span className={styles.title}>{translate('messages.addRole')}</span>
				<CustomInput placeholder={translate('ra.action.search')} mode={'root'} value={search} changeInput={onChangeSearch} errorMessage={''} focus={false} />
				<div className={styles.table}>
					<div className={styles.table_header}>
						<span>{translate('operations.params.USER.givenName')}</span>
					</div>
					<div className={styles.table_body}>
						{
							!loading && data.length ? data.map((item: any) => (
								<UserItem selected={selected.map((role: IRole) => role.id).includes(item.id)} onChange={onChangeSelectedRoles} text={item.fullName ? item?.fullName?.replaceAll('/', ' / ') : item.name} id={item.id} key={item.id} />
							)) :
								loading ? <span className={styles.not_found}>{translate('ra.message.loading')}</span> :
									<span className={styles.not_found}>{translate('ra.navigation.no_results')}</span>
						}
					</div>
				</div>
				<div className={styles.limits}>
					<span>{translate('messages.expirationDate')}</span>
					<CustomRadioButton
						list={[translate('messages.notLimitExpiration'), translate('messages.limitExpiration')]}
						value={limit ? translate('messages.limitExpiration') : translate('messages.notLimitExpiration')}
						changeValue={onChangeLimit}
						directionHorizontal={false}
					/>
				</div>
				{
					limit ?
						<div className={styles.input_date}>
							<span>{translate('messages.expirationDateTo')}</span>
							<CustomDatePicker onChangeDate={onChangeLimitDate} date={limitDate ?? new Date()} />
						</div> : null
				}
				<h1 className={styles.header}>{translate('messages.inputReason')}</h1>
				<CustomTextArea placeholder={translate('messages.commentText')} mode={'root'} value={comment} changeInput={onChangeCause} limit={255} focus={false} />
				<div className={styles.buttons}>
					<CancelButton text={translate('ra.action.cancel')} onClick={closeModal} />
					<CustomButton text={translate('ra.action.choose')} onClick={checkRoles} />
				</div>
			</div> :
			<div className={styles.root}>
				<div className={styles.close_button} onClick={closeModal}>
					<img src={CloseIcon} alt="" />
				</div>
				<span className={styles.title}>{translate('messages.giveAccess')}</span>
				<div className={styles.list_wrapper}>
					{
						rolesDepsList!.map((roleItem: IAccessRoles) => (
							roleItem.roleUsersList.length || roleItem.appAdminsList.length || roleItem.roleAdminsList.length ?
								<div className={styles.roles_wrapper}>
									<span className={styles.text}>{`${translate('messages.assigningRole')} ${transformRoleName(roleItem.role.name, 64)} ${translate('messages.selectedUsersWillHaveAccessTo')}`}</span>
									<ul className={styles.list}>
										{roleItem.roleUsersList.length ? roleItem.roleUsersList.map((item: string) => <li className={styles.list_item} key={item}>{transformRoleName(item, 58)}</li>) : null}
										{roleItem.appAdminsList.length ? roleItem.appAdminsList.map((item: string) => <li className={styles.list_item} key={item}>{`${translate('messages.appAdministration')} ${transformRoleName(item, 58)}`}</li>) : null}
										{roleItem.roleAdminsList.length ? roleItem.roleAdminsList.map((item: string) => <li className={styles.list_item} key={item}>{`${translate('messages.roleAdministration')} ${transformRoleName(item, 58)}`}</li>) : null}
									</ul>
								</div> : null
						))
					}
				</div>
				<div className={styles.buttons_wrapper}>
					<CancelButton text={translate('ra.action.comeback')} onClick={onComeBack} />
					<div className={styles.buttons}>
						<CancelButton text={translate('ra.action.cancel')} onClick={closeModal} />
						<CustomButton text={translate('ra.action.confirm')} onClick={() => onAddRoles()} />
					</div>
				</div>
			</div>
	)
}

export default AddBulkRoleModal;