import React from "react";
import styles from './CustomInputGroup.module.css';
import CustomModal from "../../modals/customModal/CustomModal";
import SelectGroupModal from "../../modals/selectGroupModal/SelectGroupModal";
import { useTranslate } from 'react-admin';

type CustomInputGroupProps = {
  selected: string,
  onChange: (v: string) => void,
}

const CustomInputGroup = (props: CustomInputGroupProps) => {
  const { selected, onChange } = props;
  const [open, setOpen] = React.useState(false);
  const translate = useTranslate();

  const onClose = () => {
    setOpen(false);
  }

  const onOpen = () => {
    setOpen(true);
  }

  return (
    <div className={styles.root}>
      <div className={styles.input}>
        <span className={styles.button} onClick={onOpen}>{translate('ra.action.choose')}</span>
        {
          selected?.length ? <span className={styles.chip}>{selected}</span> : null
        }
      </div>
      <CustomModal
        open={open}
        close={setOpen}
        children={<SelectGroupModal close={onClose} onSelect={onChange} selectedItem={selected} />} />
    </div>
  )
}

export default CustomInputGroup;