import React from "react";
import {
  useRefresh, useTranslate,
} from "react-admin";
import styles from './CancelApprovalModal.module.css';
import CloseIcon from "../../../assets/close.png";
import CancelButton from "../../buttons/cancelButton/CancelButton";
import CustomButton from "../../buttons/customButton/CustomButton";
import CustomTextArea from "../../inputs/customTextArea/CustomTextArea";

type CancelApprovalModalProps = {
  closeModal: () => void,
  onCancelApproval: (v: string) => void,
}

const CancelApprovalModal = (props: CancelApprovalModalProps) => {
  const {closeModal, onCancelApproval} = props;
  const refresh = useRefresh();
  const [cause, setCause] = React.useState('');
	const translate = useTranslate();

  const onChangeCause = (v: string) => {
    setCause(v);
  }

  const onCancel = () => {
    onCancelApproval(cause);
    refresh();
    closeModal();
  }

  return (
    <div className={styles.root}>
      <div className={styles.close_button} onClick={closeModal}>
        <img src={CloseIcon} alt=""/>
      </div>
      <h1 className={styles.header}>{translate('messages.accessDeny')}</h1>
      <span className={styles.text}>{translate('messages.inputRefusalReason')}</span>
      <CustomTextArea placeholder={translate('messages.commentText')} mode={'root'} value={cause} changeInput={onChangeCause} focus={false} limit={255}/>
      <div className={styles.buttons}>
        <CancelButton text={translate('ra.action.cancel')} onClick={closeModal} />
        <CustomButton text={translate('ra.action.deny')} onClick={onCancel} disable={!cause.length}/>
      </div>
    </div>
  )
}

export default CancelApprovalModal;