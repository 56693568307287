import React, { Fragment, ReactElement } from "react";
import {
	Datagrid,
	TextInput,
	TextField,
	FilterForm,
	SearchInput,
	FunctionField,
	List,
	UrlField, ReferenceManyField,
	ListBase, useRecordContext, useGetIdentity, useGetList, Loading, useTranslate, Button, useRefresh, BooleanField
} from "react-admin";
import { Stack } from "@mui/material";
import CustomCreateButton from "../../buttons/customCreateButton/CustomCreateButton";
import RowActionsButton from "../../buttons/rowActionsButton/RowActionsButton";
import AppIcon from "../../../assets/appIcon.png";
import styles from './AppsPage.module.css';
import CustomColumns from "../../buttons/customColumns/CustomColumns";
import ActionsAppMenu from "../../popovers/actionsAppMenu/ActionsAppMenu";
import CustomBulkDeleteButton from "../../buttons/customBulkDeleteButton/CustomBulkDeleteButton";
import RemoveBulkAppModal from "../../modals/removeBulkAppModal/RemoveBulkAppModal";
import { IApp, IApproval, IRole } from "../../../utils/types";
import LockIcon from '../../../assets/lock_FILL.png';
import OpenIcon from '../../../assets/lock_open.png';
import CustomModal from "../../modals/customModal/CustomModal";
import CreateApprovalModal from "../../modals/createApprovalModal/CreateApprovalModal";
import RoleAppMenu from "../../popovers/roleAppMenu/RoleAppMenu";
import { dateFormat } from "../../../utils/utils";
import MovingAppModal from '../../modals/movingAppModal/MovingAppModal';

type columnType = {
	source: string,
	label: string,
	active: boolean,
}

type ExpandedRolesProps = {
	app: IApp,
}

const AppsPage = () => {
	const translate = useTranslate();
	const [showModal, setShowModal] = React.useState('');
	const [savedMoving, setSavedMoving] = React.useState<boolean>(Boolean(JSON.parse(localStorage.getItem('moving')!)) || false);
	const [appColumns, setAppColumns] = React.useState([
		{ source: 'name', label: translate('operations.params.APP.title'), active: true },
		{ source: 'active', label: translate('operations.params.APP.active'), active: true },
		{ source: 'url', label: translate('operations.params.APP.appAddress'), active: true },
		{ source: 'createdAt', label: translate('operations.params.APP.createdAt'), active: true },
		{ source: 'description', label: translate('operations.params.APP.description'), active: true },
	]);
	const userFilters = [
		<SearchInput name={'q'} source={'appName'} alwaysOn />,
		<TextInput name={'address'} label={translate('operations.params.APP.appAddress')} source={'address'} />,
		<TextInput name={'description'} label={translate('operations.params.APP.description')} source={'description'} />
	]
	
	const refresh = useRefresh();

		const CustomListToolBar = (props: any) => (
		<Stack direction="row" justifyContent="space-between" whiteSpace="nowrap" sx={{ width: "100%"}}>
			<FilterForm style={{ flex: "auto"}} filters={userFilters} />
			{
				props.userRole === 1
				?
					<>
						{
							savedMoving
								? <Button
									sx={{marginRight: "20px"}}
									onClick={() => {
										localStorage.removeItem('moving');
										setSavedMoving(false);
										refresh() }
									}
									label={translate('operations.params.APP.cancelTheMove')}
								/>
								: null
						}
						
						<CustomCreateButton label={translate('operations.params.APP.addApplication')} />
					</>
				:
					null
			}
		</Stack>
	)
	const [showApprovalModal, setShowApprovalModal] = React.useState(false);
	const [roleId, setRoleId] = React.useState(0);
	const [pasteApp, setPasteApp] = React.useState<IApp>();
	const [pasteRole, setPasteRole] = React.useState<IRole>();
	const { identity: user, isLoading: isLoadingUser } = useGetIdentity();
	const myRoles = user?.roles ? user?.roles.map((item: any) => item.id) : [];
	const { data, isLoading } = useGetList('approval', { filter: { type: 'access', status: 'pending', owner: user?.id } });

	const onShowApprovalModal = (id: number) => {
		setRoleId(id);
		setShowApprovalModal(true);
	}

	const checkRolesInApp = (appId: number): boolean => {
		return user?.roles && user?.roles.map((item: any) => item.appId).includes(appId);
	}

	const onCloseApprovalModal = () => {
		setShowApprovalModal(false);
	}

	const onChangeAppColumns = (id: string) => {
		setAppColumns(oldState => oldState.map(item => {
			if (item.source === id) item.active = !item.active;
			return item;
		}));
	}

	const PostBulkActionButtons: ReactElement<any, string | React.JSXElementConstructor<any>> = (
		<Fragment>
			<CustomBulkDeleteButton Modal={RemoveBulkAppModal} text={translate('ra.action.delete')} modalProps={{}} />
		</Fragment>
	);

	const Header = () => {
		return null
	}

	const ExpandedRoles = (props: ExpandedRolesProps) => {
		const { app } = props;
		return (
			<Datagrid
				header={Header}
				bulkActionButtons={false}
				expand={<ExpandChildRole app={app} />}
				rowClick={'show'}
				isRowExpandable={row => Boolean(app.roles && app.roles.find((item: IRole) => item.parentId === row.id))}
				sx={{
					'& .RaDatagrid-headerCell': {
						borderColor: "#e0e0e0",
						backgroundColor: "#fff0",
					},
					'& .RaDatagrid-rowCell': {
						'&.column-name': {
							width: "available",
						},
						'&.column-icon': {
							width: 20,
						},
						'&.column-button': {
							maxWidth: 60,
							'& span': {
								textAlign: "-webkit-right",
								paddingRight: "24px",
							}
						},
					},
				}}
			>
				<FunctionField source={'icon'} render={(record: IRole) => (myRoles && myRoles.includes(record.id)) || record.editable ? <img src={OpenIcon} alt="" /> : <img src={LockIcon} alt="" />} />
				<TextField source={'name'} />
				<FunctionField source={'button'}
					render={
						(record: IRole) => record.editable || app.editable || user?.role === 1 ?
							<div className={styles.menu_button} >
								<RowActionsButton
									menuProps={{
										record: record,
										onPasteRole: setPasteRole,
										showModal: setShowModal,
									}}
									Menu={RoleAppMenu} />
							</div>
								: data && data.map((item: IApproval) => item.target).includes(String(record.id))
									?
									<span className={styles.access_button}>
										{translate('messages.accessRequested')}
									</span> :
									(myRoles && myRoles.includes(record.id)) ? null
									: <span
										onClick={(e: any) => { e.stopPropagation(); onShowApprovalModal(record.id) }}
										className={styles.access_button}>
										{translate('operations.params.APPROVAL.approveAccess')}
									</span>
					} />
			</Datagrid>
		)
	}

	const ExpandChildRole = (props: ExpandedRolesProps) => {
		const { app } = props;
		return (
			<ReferenceManyField target={'parentId'} reference={'role'}>
				<ExpandedRoles app={app} />
			</ReferenceManyField>
		)
	}

	const ExpandRole = () => {
		const record: IApp = useRecordContext();
		return (
			<ReferenceManyField target={'appId'} reference={'role'}>
				<ListBase
					disableSyncWithLocation
					filter={{ parentId: 0, appId: record.id, q: '' }}
				>
					<ExpandedRoles app={record} />
				</ListBase>
			</ReferenceManyField>
		)
	}

	const EmptyState = () => (
		<div className={styles.empty}>
			<span>{translate('messages.emptyLssist')}</span>
			{user?.role === 1
				?
					<CustomCreateButton label={translate('operations.params.APP.addApplication')} />
				:
					null
			}
		</div>
	)

	const onCloseModal = () => {
		setShowModal('');
		setPasteApp(undefined);
		setPasteRole(undefined);
		setSavedMoving(Boolean(JSON.parse(localStorage.getItem('moving')!)) || false);
	}

	if (isLoadingUser || isLoading) return <Loading />

	return (
		<div>
			<h1>{translate('operations.entities.APP')}</h1>
			<List
				actions={<CustomListToolBar userRole={user?.role} />}
				disableSyncWithLocation
				sort={{ field: 'name', order: 'ASC' }}
				exporter={false}
				empty={<EmptyState />}
			>
				<Datagrid
					rowClick={'show'}
					expand={<ExpandRole />}
					isRowExpandable={(row => row.roles && Boolean(row.roles.length))}
					isRowSelectable={() => user?.role === 1}
					bulkActionButtons={user?.role === 1 ? PostBulkActionButtons : false}
					sx={{
						'& .RaDatagrid-headerCell': {
							borderColor: "#e0e0e0",
							backgroundColor: "#fff0",
						},
						'& .RaDatagrid-rowCell': {
							maxWidth: 200,
							'&.column-undefined': {
								width: 40,
								'& span': {
									display: "flex",
									alignItems: "center",
								},
							},
							'&.column-url': {
								'& .MuiLink-root': {
									display: "block",
									whiteSpace: "nowrap",
									textOverflow: "ellipsis",
									overflow: "hidden",
								},
							},
							'&.column-description': {
								'& span': {
									display: "block",
									whiteSpace: "nowrap",
									textOverflow: "ellipsis",
									overflow: "hidden",
								},
							}
						},
						'& .RaDatagrid-expandedPanel': {
							'& .MuiTableCell-root': {
								paddingRight: 0,
								'& .MuiTableCell-root': {
									borderBottom: "none",
								},
							}
						}
					}}>
					{
						appColumns.filter((item: columnType) => item.active).map((item: columnType) => {
							switch (item.source) {
								case 'name': return <FunctionField source={'name'} label={translate('operations.params.APP.title')} render={(record: any) => <div className={styles.name}>{user?.role !== 1 ? <div className={styles.icon}><img src={checkRolesInApp(record.id) ? OpenIcon : LockIcon} alt="" /></div> : null}<div className={styles.icon} style={{ backgroundImage: `url(${record.logo && record.logo.length ? record.logo : AppIcon})`, backgroundPosition: 'center center', backgroundSize: 'cover' }} /><span>{record.name}</span></div>} key={item.source} />;
								case 'active': return <BooleanField source={'active'} label={translate('operations.params.APP.active')} key={item.source} valueLabelTrue={'operations.params.STATUS.active'} valueLabelFalse={'operations.params.STATUS.disabled'} />;
								case 'url': return <UrlField source={'url'} label={translate('operations.params.APP.appAddress')} key={item.source} />;
								case 'description': return <TextField source={'description'} label={translate('operations.params.APP.description')} key={item.source} />;
								case 'createdAt': return <FunctionField source={'createdAt'} label={translate('operations.params.APP.createdAt')} sx={{ maxWidth: 140, }} key={item.source} render={(record: any) => dateFormat(record.createdAt)} />;
								default: return <TextField source={item.source} label={item.label} key={item.source} />
							}
						})
					}
					<FunctionField
						label={<CustomColumns columns={appColumns} onChange={onChangeAppColumns} />}
						render={(record: IApp) => record?.editable
							? <RowActionsButton
								menuProps={{
									record: record,
									onPasteApp: setPasteApp,
									showModal: setShowModal,
									onSavedMoving: setSavedMoving
								}} Menu={ActionsAppMenu} />
							: null}
						sx={{
							maxWidth: 40
						}}
					/>
				</Datagrid>
			</List>
			<CustomModal
				open={showApprovalModal}
				close={onCloseApprovalModal}
			>
				<CreateApprovalModal
					closeModal={onCloseApprovalModal}
					roleId={roleId}
				/>
			</CustomModal>
			<CustomModal
				open={showModal?.includes('pasteApp')}
				close={onCloseModal}
			>
				<MovingAppModal
					closeModal={onCloseModal}
					onPasteApp={pasteApp!}
					onPasteRole={pasteRole}
				/>
			</CustomModal>
		</div>
	)
}

export default AppsPage;