import React from "react";
import { useNavigate } from 'react-router-dom';
import {useNotify, useTranslate, useUpdate} from "react-admin";
import { Card, CardContent } from '@mui/material';
import styles from './EditProfilePage.module.css';
import CustomTooltip from "../../tooltips/customTooltip/CustomTooltip";
import backIcon from '../../../assets/Back.png';
import CustomInput from "../../inputs/customInput/CustomInput";
import CustomSelect from "../../inputs/customSelect/CustomSelect";
import CustomRadioButton from "../../inputs/customRadioButton/CustomRadioButton";
import CancelButton from "../../buttons/cancelButton/CancelButton";
import CustomButton from "../../buttons/customButton/CustomButton";
import SaveIcon from '../../../assets/Save.png';
import { IProfile } from "../../../utils/types";
import {CAMPUS_DIGITS} from "../../../utils/constants";

type EditProfilePageProps = {
  profile: IProfile
}

const EditProfilePage = (props: EditProfilePageProps) => {
  const { profile } = props;
	const translate = useTranslate();
  const monthList = [
  translate('operations.params.MONTH.january'),
  translate('operations.params.MONTH.february'),
  translate('operations.params.MONTH.march'),
  translate('operations.params.MONTH.april'),
  translate('operations.params.MONTH.may'),
  translate('operations.params.MONTH.june'),
  translate('operations.params.MONTH.july'),
  translate('operations.params.MONTH.august'),
  translate('operations.params.MONTH.september'),
  translate('operations.params.MONTH.october'),
  translate('operations.params.MONTH.november'),
  translate('operations.params.MONTH.december'),
];
  const getDateFormat = (query: string) => {
    const dateArray = profile?.description?.split('.') || ['', 1, ''];
    return {
      'date': dateArray[0] || '1',
      'month': monthList[Number(dateArray[1]) - 1] || translate('operations.params.MONTH.january'),
      'year': dateArray[2] || '2000',
    }[query];
  }
  const [sex, setSex] = React.useState(profile ? ({ [translate('operations.params.USER.maleM')]: translate('operations.params.USER.male'), [translate('operations.params.USER.femaleF')]: translate('operations.params.USER.female') }[profile?.initials] || translate('operations.params.USER.none')) : translate('operations.params.USER.notSpecified'));
  const [isResident, setIsResident] = React.useState(profile && profile.facsimileTelephoneNumber && Number(profile?.facsimileTelephoneNumber.split('/')[0]) ? translate('operations.params.USER.residentYes') : translate('operations.params.USER.residentNo'));
  const [name, setName] = React.useState(profile ? profile?.givenName : '');
  const [surname, setSurname] = React.useState(profile ? profile?.sn : '');
  const [login, setLogin] = React.useState(profile ? profile?.id : '');
  const [date, setDate] = React.useState(String(getDateFormat('date')));
  const [month, setMonth] = React.useState(String(getDateFormat('month')));
  const [year, setYear] = React.useState(String(getDateFormat('year')));
  const [id, setId] = React.useState(profile && profile.facsimileTelephoneNumber && Number(profile?.facsimileTelephoneNumber.split('/')[0]) ? profile?.postalCode : '');
  const [validId, setValidtId] = React.useState(true);
  const [campus, setCampus] = React.useState(profile && profile.facsimileTelephoneNumber && !Number(profile?.facsimileTelephoneNumber.split('/')[0]) ? profile?.postalCode : '');
  const navigate = useNavigate();
  const notify = useNotify();

  const [updateProfile] = useUpdate(
    'user',
    {
      id: profile?.id,
      data: {
        sAMAccountName: login,
        givenName: name,
        sn: surname,
        description: `${date}.0${monthList.indexOf(month) + 1}.${year}`,
        initials: { [translate('operations.params.USER.male')]: translate('operations.params.USER.maleM'), [translate('operations.params.USER.female')]: translate('operations.params.USER.femaleF') }[sex] || translate('operations.params.USER.none'),
        facsimileTelephoneNumber: isResident === translate('operations.params.USER.residentYes') ? '1' : '0',
        postalCode: profile && profile.facsimileTelephoneNumber && Number(profile?.facsimileTelephoneNumber.split('/')[0]) ? id : campus
      }
    },
    {
      mutationMode: 'undoable',
      onSuccess: () => {
        notify('ra.notification.updated', {type: 'success', messageArgs: {smart_count: 1}, undoable: true})
        onComeBack();
      },
      onError: () => notify('ra.notification.canceled', { type: 'warning' })
    }
  );
  const onChangeSex = (v: string) => {
    setSex(v);
  }

  const onChangeResident = (v: string) => {
    setIsResident(v);
  }

  const onChangeName = (v: string) => {
    setName(v);
  }

  const onChangeSurname = (v: string) => {
    setSurname(v);
  }

  const onChangeLogin = (v: string) => {
    setLogin(v);
  }

  const onChangeDate = (v: string) => {
    if ((/\d+$/.test(v) && Number(v) <= 31)) setDate(v);
    else if (v === '') setDate('');
  }

  const onChangeMonth = (v: string) => {
    setMonth(v);
  }

  const onChangeYear = (v: string) => {
    if ((/\d+$/.test(v) && Number(v) <= (new Date().getFullYear() - 10))) setYear(v);
    else if (v === '') setYear('');
  }

  const isValidId = (v: string): boolean => {
    const arr = v.slice(0, 9).split('').map((item: string) => Number(item));
    const checkSum = parseInt(v.slice(9), 10);
    const sum = arr[0] * 9 + arr[1] * 8 + arr[2] * 7 + arr[3] * 6 + arr[4] * 5 + arr[5] * 4 + arr[6] * 3 + arr[7] * 2 + arr[8];
    if (sum < 100 && sum === checkSum) {
      return true;
    } else if ((sum === 100 || sum === 101) && checkSum === 0) {
      return true;
    } else if (sum > 101 && (sum % 101 === checkSum || (sum % 101 === 100 && checkSum === 0))) {
      return true;
    } else {
      return false;
    }
  }

  const onChangeId = (v: string) => {
    if (/\d+$/.test(v) || v === '') {
      if (v.length === 11) setValidtId(isValidId(v));
      else if (v.length === 0) setValidtId(true);
      else setValidtId(false);
      setId(v);
    }
  }

  const onChangeCampus = (v: string) => {
    if (/\d+$/.test(v) || v === '') {
      setCampus(v);
    }
  }

  const onComeBack = () => {
    navigate(-1);
  }

  const saveChangeProfile = () => {
    updateProfile()
  }

  const validate = () => !name?.length || !surname?.length || (!date?.length || date === '0') || !month?.length || !year?.length || (isResident === translate('operations.params.USER.residentYes') ? !validId || !id?.length : campus?.length !== CAMPUS_DIGITS);

  React.useEffect(() => {
    if (profile) {
      setName(profile.givenName);
      setSurname(profile.sn);
      setSex({ [translate('operations.params.USER.maleM')]: translate('operations.params.USER.male'), [translate('operations.params.USER.femaleF')]: translate('operations.params.USER.female') }[profile?.initials] || translate('operations.params.USER.notSpecified'));
      setLogin(profile.id);
      setDate(String(getDateFormat('date')));
      setMonth(String(getDateFormat('month')));
      setYear(String(getDateFormat('year')));
    }
    // eslint-disable-next-line
  }, [profile])

  return (
    <div className={styles.wrapper}>
      <div className={styles.root}>
        <header>
          <CustomTooltip title={translate('ra.action.comeback')}>
            <div className={styles.back_icon} onClick={onComeBack}>
              <img src={backIcon} alt="icon" />
            </div>
          </CustomTooltip>
          <h1>{translate('operations.params.USER.editProfile')}</h1>
        </header>
        <Card>
          <CardContent>
            <div className={styles.row_item}>
              <span className={styles.row_item__label}>{translate('operations.params.USER.givenName')}</span>
              <CustomInput placeholder={translate('operations.params.USER.enterName')} mode={'root'} value={name} changeInput={onChangeName} errorMessage={''} focus={false} />
            </div>
            <div className={styles.row_item}>
              <span className={styles.row_item__label}>{translate('operations.params.USER.sn')}</span>
              <CustomInput placeholder={translate('operations.params.USER.enterLastName')} mode={'root'} value={surname} changeInput={onChangeSurname} errorMessage={''} focus={false} />
            </div>
            <div className={styles.row_item}>
              <span className={styles.row_item__label}>{translate('operations.params.USER.sAMAccountName')}</span>
              <CustomInput placeholder={translate('operations.params.USER.enterLogin')} mode={'root'} value={login} changeInput={onChangeLogin} errorMessage={''} focus={false} />
            </div>
            <div className={styles.row_item}>
              <span className={styles.row_item__label}>{translate('operations.params.USER.dateOfBirth')}</span>
              <div className={styles.row_item__group}>
                <div className={styles.row_item__group_input}>
                  <CustomInput placeholder={translate('operations.params.DAYS.day')} mode={'root'} value={date} changeInput={onChangeDate} errorMessage={''} focus={false} />
                </div>
                <div className={styles.row_item__group_input}>
                  <CustomSelect menuElements={monthList} defaultValue={month} changeValue={onChangeMonth} mode={'root'} />
                </div>
                <div className={styles.row_item__group_input}>
                  <CustomInput placeholder={translate('operations.params.DAYS.year')} mode={'root'} value={year} changeInput={onChangeYear} errorMessage={''} focus={false} />
                </div>
              </div>
            </div>
            <div className={styles.row_item}>
              <span className={styles.row_item__label}>{translate('operations.params.USER.initials')}</span>
              <CustomRadioButton value={sex} changeValue={onChangeSex} list={[translate('operations.params.USER.notSpecified'), translate('operations.params.USER.male'), translate('operations.params.USER.female')]} />
            </div>
            <div className={styles.row_item}>
              <span className={styles.row_item__label}>{translate('operations.params.USER.resident')}</span>
              <CustomRadioButton value={isResident} changeValue={onChangeResident} list={[translate('operations.params.USER.residentYes'), translate('operations.params.USER.residentNo')]} />
            </div>
            {
              isResident !== translate('operations.params.USER.residentYes') ?
                <div className={styles.row_item}>
                  <span className={styles.row_item__label}>{translate('operations.params.USER.campusNumber')}</span>
                  <CustomInput placeholder={translate('operations.params.USER.enterNumber')} mode={campus?.length === CAMPUS_DIGITS || campus?.length === 0 ? 'root' : 'error'} value={campus} changeInput={onChangeCampus} errorMessage={translate('operations.params.EMAIL.invalidFormat')} focus={false} />
                  {/*<span className={styles.generate_button}>Сгенерировать</span>*/}
                </div> :
                <div className={styles.row_item}>
                  <span className={styles.row_item__label}>{translate('operations.params.USER.postalCode')}</span>
                  <CustomInput placeholder={translate('operations.params.USER.enterPostalCode')} mode={validId ? 'root' : 'error'} value={id} changeInput={onChangeId} errorMessage={translate('operations.params.EMAIL.invalidFormat')} focus={false} />
                </div>
            }
            <div className={styles.buttons}>
              <CancelButton text={translate('ra.action.abort')} onClick={onComeBack} />
              <CustomButton text={translate('ra.action.save')} onClick={saveChangeProfile} iconSrc={SaveIcon} disable={validate()} />
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

export default EditProfilePage;