export const getBase64 = (file: any) => {
  return file ? new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  }) : file;
}

export const dateFormat = (date: Date | string, options: {showTime: boolean, showMilliseconds?: boolean} = { showTime: true, showMilliseconds: false }) =>
  (date && new Date(date).toLocaleString().replace(',', '').substring(0, options.showTime ? 19 : 10) + `${options.showMilliseconds ? `.${new Date(date).getMilliseconds()}` : ''}`) || '';

export const downloadURI = (uri: string) => {
  const link = document.createElement("a");
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export const transformRoleName = (name: string, maxLength: number): string => {
  if (name.length <= maxLength) return name.replaceAll('/', ' / ');
  const arr = name.split('/');
  return arr.map((item: string, index: number) => index === arr.length - 1 && item.length > maxLength ? `${item.slice(0, maxLength)}...` : item).join(' / ');
}

export const dispatchOpenPopoverEvent = () => {
  const openPopoverEvent = new Event('openPopover');
  document.dispatchEvent(openPopoverEvent);
}

export const dispatchCloseDatePickerEvent = () => {
  const closeDatePickerEvent = new Event('closeDatePicker');
  document.dispatchEvent(closeDatePickerEvent);
}