import React, { useEffect } from "react";
import styles from './ConfirmManyApprovalsModal.module.css';
import CloseIcon from "../../../assets/close.png";
import CancelButton from "../../buttons/cancelButton/CancelButton";
import CustomButton from "../../buttons/customButton/CustomButton";
import { useTranslate } from "react-admin";
import CustomTextArea from '../../inputs/customTextArea/CustomTextArea';
import { transformRoleName } from "../../../utils/utils";

type ConfirmManyApprovalsModalProps = {
	closeModal: () => void,
	onApprove: (id: number, v: string) => void,
	list: any,
}

const ConfirmManyApprovalsModal = (props: ConfirmManyApprovalsModalProps) => {
	const { closeModal, onApprove, list } = props;
	const translate = useTranslate();
	const [index, setIndex] = React.useState<number>(0);
	const [comment, setComment] = React.useState('');
	const [step, setStep] = React.useState('');
	const simpleList: any[] = list.filter((item: any) => !item.appAdminsList.length && !item.roleAdminsList.length && !item.roleUsersList.length);
	const confirmList: any[] = list.filter((item: any) => item.appAdminsList.length || item.roleAdminsList.length || item.roleUsersList.length);

	useEffect(() => {
		if (simpleList.length) { setStep('first comment') }
		else if (confirmList.length) setStep('creating');
		//eslint-disable-next-line
	}, [])

	const onChangeCause = (v: string) => {
		setComment(v);
	}

	const onApproveOne = () => {
		onApprove(confirmList[index].approval.id, comment);
		onSkip();
		setStep('creating');
	}

	const onComeBack = () => {
		setStep('creating');
	}

	const onComeForward = () => {
		setStep('comment');
	}

	const onSkip = () => {
		if (index !== confirmList.length - 1) {
			setIndex(index + 1)
		} else closeModal();
	}

	const onConfirmSimpleList = () => {
		simpleList.map((item: any) => onApprove(item.approval.id, comment));
		if (confirmList.length) {
			setStep('creating');
		} else closeModal();
	}

	return (
		step === 'creating' ?
			<div className={styles.root}>
				<div className={styles.close_button} onClick={closeModal}>
					<img src={CloseIcon} alt="" />
				</div>
				<span className={styles.title}>{translate('messages.giveAccess')}</span>
				<span className={styles.text}>{`${translate('messages.assigningAccessTo')} ${confirmList[index].roleName} ${translate('operations.params.USER.userName')} ${confirmList[index].userName} ${translate('messages.willHaveAccessTo')}`}</span>
				<ul className={styles.list}>
					{confirmList[index].roleUsersList.length ? confirmList[index].roleUsersList.map((item: string) => <li className={styles.list_item} key={item}>{transformRoleName(item, 58)}</li>) : null}
					{confirmList[index].appAdminsList.length ? confirmList[index].appAdminsList.map((item: string) => <li className={styles.list_item} key={item}>{`${translate('messages.appAdministration')} ${transformRoleName(item, 58)}`}</li>) : null}
					{confirmList[index].roleAdminsList.length ? confirmList[index].roleAdminsList.map((item: string) => <li className={styles.list_item} key={item}>{`${translate('messages.roleAdministration')} ${transformRoleName(item, 58)}`}</li>) : null}
				</ul>
				<div className={styles.buttons}>
					<CancelButton text={translate('ra.action.cancel')} onClick={closeModal} />
					<CancelButton text={translate('ra.action.skip')} onClick={onSkip} />
					<CustomButton text={translate('ra.action.confirm')} onClick={onComeForward} />
				</div>
			</div> : (
				<>
					<div className={styles.root}>
						<h1 className={styles.header}>{translate('messages.inputReason')}</h1>
						<CustomTextArea placeholder={translate('messages.commentText')} mode={'root'} value={comment} changeInput={onChangeCause} focus={false} limit={255} />
						<div className={styles.buttons_wrapper}>
							{step !== 'first comment' ? <CancelButton text={translate('ra.action.comeback')} onClick={onComeBack} /> : null}
							<div className={styles.buttons}>
								<CancelButton text={translate('ra.action.cancel')} onClick={closeModal} />
								<CustomButton text={translate('ra.action.confirm')} onClick={step === 'first comment' ? onConfirmSimpleList : onApproveOne} />
							</div>
						</div>
					</div>
				</>
			)
	)
}

export default ConfirmManyApprovalsModal;