import React, { Fragment, ReactElement, useEffect } from "react";
import {
	List,
	Datagrid,
	TextField,
	FilterForm,
	SearchInput,
	FunctionField,
	BooleanField,
	RowClickFunction,
	useListController,
	NullableBooleanInput,
	SelectArrayInput,
	ReferenceArrayInput,
	AutocompleteArrayInput,
	DateInputProps, useInput, Labeled, useTranslate, useListContext, useGetIdentity
} from "react-admin";
import { Stack } from "@mui/material";
import CustomCreateButton from "../../buttons/customCreateButton/CustomCreateButton";
import CustomFilterButton from "../../buttons/customFilterButton/CustomFilterButton";
import CustomColumns from "../../buttons/customColumns/CustomColumns";
import RowActionsButton from "../../buttons/rowActionsButton/RowActionsButton";
import ActionsProfileMenu from "../../popovers/actionsProfileMenu/ActionsProfileMenu";
import CustomBreadcrumbs from "../../customBreadcrumbs/CustomBreadcrumbs";
import styles from './UsersPage.module.css';
import FolderIcon from '../../../assets/folder.png';
import AvatarIcon from '../../../assets/avatar.png';
import { IProfile } from "../../../utils/types";
import CustomBulkDeleteButton from "../../buttons/customBulkDeleteButton/CustomBulkDeleteButton";
import RemoveBulkUsersModal from "../../modals/removeBulkUsersModal/RemoveBulkUsersModal";
import AddBulkRoleModal from "../../modals/addBulkRoleModal/AddBulkRoleModal";
import { USER_ROLES } from "../../../utils/constants";
import CustomDateRangePicker from "../../inputs/customDateRangePicker/CustomDateRangePicker";
import ReportsMenu from "../../popovers/reportsMenu/reportsMenu";
import { downloadURI } from "../../../utils/utils";

const UsersPage = () => {
	const translate = useTranslate();
	const { filterValues, setFilters, displayedFilters, setSort, sort } = useListController();
	const { identity: user } = useGetIdentity();
	const [userColumns, setUserColumns] = React.useState([
		{ source: 'displayName', label: translate('operations.params.USER.givenName'), active: true },
		{ source: 'userAccountControl', label: translate('operations.params.APPROVAL.status'), active: true },
		{ source: 'role', label: translate('operations.params.APPROVAL.type'), active: false },
		{ source: 'mail', label: translate('operations.params.USER.mail'), active: true },
		{ source: 'description', label: translate('operations.params.USER.dateOfBirth'), active: true },
		{ source: 'memberOf', label: translate('operations.entities.GROUP'), active: true },
		{ source: 'roles', label: translate('operations.entities.ROLE'), active: true },
		{ source: 'accountExpires', label: translate('messages.accountExpires'), active: false },
		{ source: 'whenCreated', label: translate('operations.params.APPROVAL.whenCreated'), active: true },
	])

	const labeledStyle = {
		backgroundColor: 'rgba(0, 0, 0, 0.04)',
		borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
		'& p span': {
			fontFamily: 'Roboto, sans-serif',
			fontStyle: 'normal',
			fontWeight: 400,
			fontSize: '.8rem',
			marginLeft: '10px',
			marginTop: '5px',
		}
	}

	useEffect(() => {
		if (sort.field === 'id') {
			setSort({
				field: 'displayName', order: 'ASC'
			});
		}
		// eslint-disable-next-line
	}, [])

	const CustomDateInput = (props: DateInputProps) => {
		const { field } = useInput(props);
		return (
			<Labeled label={translate('resources.user.fields.accountExpires')} sx={labeledStyle}>
				<CustomDateRangePicker
					startDate={Array.isArray(field.value) ? new Date(field.value[0]) : null}
					endDate={Array.isArray(field.value) ? new Date(field.value[1]) : null}
					onChangeDate={(d1, d2) => {
						const start = new Date(d1);
						const end = new Date(d2);
						start.setHours(0);
						start.setMinutes(0);
						start.setSeconds(0);
						start.setMilliseconds(0);
						end.setHours(23);
						end.setMinutes(59);
						end.setSeconds(59);
						setFilters({ ...filterValues, accountExpires: [start, end] }, displayedFilters)
					}
					}
				/>
			</Labeled>
		)
	}

	const userFilters = [
		<SearchInput name={'q'} source={'id'} alwaysOn />,
		<NullableBooleanInput source='userAccountControl' margin='none' fullWidth={true} sx={{ minWidth: 100 }}
			label="resources.operation.fields.success"
			nullLabel="operations.params.STATUS.any"
			falseLabel="operations.params.STATUS.disabled"
			trueLabel="operations.params.STATUS.active"
		/>,
		<CustomDateInput source='accountExpires'
			margin='none'
		/>,
		<NullableBooleanInput source='memberOfTeacherGroup' margin='none' fullWidth={true} sx={{ minWidth: 220 }}
			label="resources.user.fields.memberOfTeacherGroup"
			nullLabel="operations.params.STATUS.any"
		/>,
		<SelectArrayInput source="role" fullWidth={true} sx={{ minWidth: 180 }} choices={USER_ROLES.filter(Boolean).map(name => ({ id: USER_ROLES.indexOf(name), name }))} />,
		<ReferenceArrayInput
			source="memberOfGroup"
			reference="group"
			sort={{ field: 'name', order: 'ASC' }}
			perPage={1000}
		>
			<AutocompleteArrayInput
				fullWidth={true} sx={{ minWidth: 240 }}
				noOptionsText={'ra.navigation.no_results'}
			/>
		</ReferenceArrayInput>,
		<ReferenceArrayInput
			source="roles"
			reference="role"
			sort={{ field: 'appId', order: 'ASC' }}
			perPage={1000}
		>
			<AutocompleteArrayInput
				fullWidth={true} sx={{ minWidth: 240, maxWidth: 450 }}
				noOptionsText={'ra.navigation.no_results'}
				optionText="fullName"
			/>
		</ReferenceArrayInput>
	]

	const CustomListToolBar = () => {
		const { filterValues, filter } = useListContext();

		const uploadReport = (reportId: string) => {
			const reportFilter = JSON.stringify({ ...filter, ...filterValues });
			downloadURI(`/api/report/${reportId}?filter=${reportFilter}&_t=${new Date().getTime()}`)
		}

		return (
			<Stack direction="row" justifyContent="space-between" sx={{ width: "100%" }}>
				<FilterForm filters={userFilters} />
				<div className={styles.filters}>
					<CustomFilterButton filters={userFilters} />
					<Stack direction="row" sx={{ alignItems: "center" }}>
						<CustomCreateButton label={translate('operations.params.USER.addCounterparty')} />
						<ReportsMenu closePopover={uploadReport} />
					</Stack>
				</div>
			</Stack>
		)
	}

	if (!Object.keys(filterValues).includes('parentId')) setFilters({ ...filterValues, parentId: "." }, displayedFilters);

	const onChangeUserColumns = (id: string) => {
		setUserColumns(oldState => oldState.map(item => {
			if (item.source === id) item.active = !item.active;
			return item;
		}));
	}

	const onRowClick: RowClickFunction = (id, resource, record) => {
		if (record.type === 1) {
			setFilters({ ...filterValues, parentId: record.parentId !== '.' ? `${id},${record.parentId}` : id }, displayedFilters);
			return '/user'
		}
		return 'show';
	}

	const onNavigateBreadcrumbs = (e: any) => {
		if (e.target.innerText === translate('operations.entities.USER')) setFilters({ ...filterValues, parentId: '.' }, displayedFilters);
		else setFilters({ ...filterValues, parentId: filterValues.parentId.split(',').slice(filterValues.parentId.split(',').indexOf(e.target.innerText)).join(',') }, displayedFilters);
	}

	const PostBulkActionButtons: ReactElement<any, string | React.JSXElementConstructor<any>> = (
		<Fragment>
			<CustomBulkDeleteButton Modal={AddBulkRoleModal} text={translate('operations.params.ROLE.assignRole')} modalProps={{}} />
			<CustomBulkDeleteButton Modal={RemoveBulkUsersModal} text={translate('ra.action.delete')} modalProps={{}} />
		</Fragment>
	);

	return (
		<div>
			<CustomBreadcrumbs
				breadcrumb={filterValues.parentId || ''}
				onClickBreadcrumb={onNavigateBreadcrumbs}
				title={translate('operations.entities.USER')}
			/>
			<List
				actions={<CustomListToolBar />}
				sort={{ field: 'displayName', order: 'ASC' }}
				exporter={false}
			>
				<Datagrid
					rowClick={onRowClick}
					isRowSelectable={row => !Boolean(row.type)}
					bulkActionButtons={PostBulkActionButtons}
					sx={{
						'& .RaDatagrid-headerCell': {
							borderColor: "#e0e0e0",
							backgroundColor: "#fff0",
						},
						'& .RaDatagrid-rowCell': {
							maxWidth: 300,
							'&.column-undefined': {
								width: 40,
								'& span': {
									display: "flex",
									alignItems: "center",
								},
							},
							'&.column-photo': {
								width: 20,
								paddingRight: 0,
							}
						},
					}}>
					{
						userColumns.filter(item => item.active).map(item => {
							switch (item.source) {
								case 'userAccountControl': return <BooleanField source={item.source} label={item?.label} key={item.source} valueLabelTrue={'operations.params.STATUS.active'} valueLabelFalse={'operations.params.STATUS.disabled'} />;
								case 'role': return <FunctionField source={'role'} label={translate('operations.params.APPROVAL.type')} render={(record: IProfile) => <span>{USER_ROLES[record.role]}</span>} key={item.source} />;
								case 'memberOf': return <FunctionField source={'memberOf'} label={translate('operations.entities.GROUP')} render={(record: any) => record.memberOf && record.memberOf.length ? <div className={styles.chips}>{[record.memberOf[0], record.memberOf.length < 2 ? null : `${translate('messages.still')} ${record.memberOf.length - 1}`].filter(Boolean).map((item: any, i) => <div className={i > 0 ? styles.more_chip : styles.chip} key={item}>{item}</div>)}</div> : null} key={item.source} />;
								case 'roles': return <FunctionField source={'roles'} label={translate('operations.entities.ROLE')} render={(record: any) => record.roles && record.roles.length ? <div className={styles.chips}>{[record.roles[0].name, record.roles.length < 2 ? null : `${translate('messages.still')} ${record.roles.length - 1}`].filter(Boolean).map((item: any, i) => <div className={i > 0 ? styles.more_chip : styles.chip} key={item}>{item}</div>)}</div> : null} key={item.source} />;
								case 'displayName': return <FunctionField source={'displayName'} label={translate('operations.params.USER.givenName')} render={(record: any) => <div className={styles.name}>{!record.type ? <img className={styles.avatar} src={record.photo && record.photo.length ? record.photo : AvatarIcon} alt="" /> : <img className={styles.folder} src={FolderIcon} alt="" />}<span>{record.displayName || `${record.sn ? record.sn : ''} ${record.givenName || record.id}`}</span></div>} key={item.source} />;
								default: return <TextField source={item.source} label={item.label} key={item.source} />
							}
						}
						)
					}
					<FunctionField
						label={<CustomColumns columns={userColumns} onChange={onChangeUserColumns} />}
						render={(record: any) => !record.type ? <RowActionsButton menuProps={{ record: record, deletable: user?.role === 1 }} Menu={ActionsProfileMenu} /> : null}
						sx={{ maxWidth: 40 }}
					/>
				</Datagrid>
			</List>
		</div>
	)
}

export default UsersPage;