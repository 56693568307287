import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from './AddRoleButton.module.css';
import PlusIcon from '../../../assets/Add.png'
import { useTranslate } from 'react-admin';

type AddRoleButtonProps = {
  roleId?: number,
}

const AddRoleButton = (props: AddRoleButtonProps) => {
  const { roleId } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const translate = useTranslate();
  const onClick = () => {
    navigate(`${pathname.split('show/')[0]}show/role${roleId ? `/${roleId}` : ''}/create`);
  }
  return (
    <div onClick={onClick} className={styles.button}>
      <img src={PlusIcon} alt="" className={styles.icon} />
      <span className={styles.text}>{translate('messages.addRole')}</span>
    </div>
  )
}

export default AddRoleButton;