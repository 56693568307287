import React from "react";
import styles from './LogoutButton.module.css';
import LogoutIcon from '../../../assets/logout.png';
import { useTranslate } from 'react-admin';

type LogoutButtonProps = {
  onClick: () => void,
}

const LogoutButton = (props: LogoutButtonProps) => {
  const { onClick } = props;
  const translate = useTranslate();
  return (
    <div className={styles.root} onClick={onClick}>
      <img className={styles.icon} src={LogoutIcon} alt="" />
      <span className={styles.text}>{translate('ra.action.exit')}</span>
    </div>
  )
}

export default LogoutButton;