import React from "react";
import styles from './AdminAppMenu.module.css';
import CustomPopoverMenuItem from "../customPopoverMenuItem/CustomPopoverMenuItem";
import CustomModal from "../../modals/customModal/CustomModal";
import RemoveAccessModal from "../../modals/removeAccessModal/RemoveAccessModal";
import {useGetIdentity, useRedirect, useTranslate} from "react-admin";

type AdminAppMenuProps = {
  closePopover: () => void
  record?: any,
  context: { role: boolean, context: string },
  isAdmin: boolean
}

const AdminAppMenu = (props: AdminAppMenuProps) => {
  const { closePopover, record, context, isAdmin } = props;
  const [showAdminsModal, setShowAdminsModal] = React.useState(false);
  const { identity: user } = useGetIdentity();
  const redirect = useRedirect();
	const translate = useTranslate();
  const onClickWrapper = (e: any) => {
    e.stopPropagation();
  }

  const onShowAdminsModal = () => {
    setShowAdminsModal(true);
  }

  const onCloseAdminsModal = () => {
    setShowAdminsModal(false);
    closePopover();
  }

  const createContragent = () => {
    redirect(`/create_contragent?user=${record.id}`);
  }

  return (
    <div className={styles.root} onClick={onClickWrapper}>
      <div className={styles.middle}>
        <CustomPopoverMenuItem text={translate('messages.revokeAccess')} onClick={onShowAdminsModal} />
        {
          (user?.role === 1 || isAdmin) && !record?.userAccountControl && record?.role !== 4 && record?.role !== 1 && context.context === 'user' ? <CustomPopoverMenuItem text={translate('operations.params.USER.makeCounterparty')} onClick={createContragent} /> : null
        }
      </div>
      <CustomModal
        open={showAdminsModal}
        close={onCloseAdminsModal}
      >
        <RemoveAccessModal closeModal={onCloseAdminsModal} record={record} context={context}/>
      </CustomModal>
    </div>
  )
}

export default AdminAppMenu;