import React, { Fragment, ReactElement } from "react";
import {
	List,
	Datagrid,
	TextField,
	SearchInput,
	FilterForm,
	FunctionField,
	ReferenceManyField, useRecordContext, ListBase, useGetIdentity, useListController, useTranslate
} from "react-admin";
import { Stack } from "@mui/material";
import { IApp, IApproval, IRole } from "../../../utils/types";
import CustomColumns from "../../buttons/customColumns/CustomColumns";
import RowActionsButton from "../../buttons/rowActionsButton/RowActionsButton";
import AddRoleButton from "../../buttons/addRoleButton/AddRoleButton";
import RoleAppMenu from "../../popovers/roleAppMenu/RoleAppMenu";
import CustomBulkDeleteButton from "../../buttons/customBulkDeleteButton/CustomBulkDeleteButton";
import RemoveBulkRoleModal from "../../modals/removeBulkRoleModal/RemoveBulkRoleModal";
import OpenIcon from "../../../assets/lock_open.png";
import LockIcon from "../../../assets/lock_FILL.png";
import styles from "../appsPage/AppsPage.module.css";
import CreateApprovalModal from "../../modals/createApprovalModal/CreateApprovalModal";
import CustomModal from "../../modals/customModal/CustomModal";

type AppRolesPageProps = {
	app: IApp,
	parentId: number,
	setParentId: (v: number) => void,
}

const AppRolesPage = (props: AppRolesPageProps) => {
	const translate = useTranslate();
	const { app, parentId } = props;
	const [roleColumns, setRoleColumns] = React.useState([
		{ source: 'name', label: translate('operations.params.APP.title'), active: true },
		{ source: 'description', label: translate('operations.params.APP.description'), active: true },
	]);
	const [showApprovalModal, setShowApprovalModal] = React.useState(false);
	const { identity: user } = useGetIdentity();
	const [roleId, setRoleId] = React.useState(0);
	const myRoles = user?.roles ? user?.roles.map((item: any) => item.id) : [];
	const { data: userApprovals } = useListController({ resource: 'approval', filter: { type: 'access', status: 'pending', owner: user?.id } })
	const { data: roles } = useListController({ resource: 'role', filter: { appId: app.id }, perPage: 1000 });

	const onShowApprovalModal = (id: number) => {
		setRoleId(id);
		setShowApprovalModal(true);
	}

	const onCloseApprovalModal = () => {
		setShowApprovalModal(false);
	}

	// const getPermissionAdminApp = (): boolean => {
	//   return user?.memberOf && user?.memberOf.includes(app.adminGroup);
	// }

	// const getPermissionAdminRole = (role: IRole): boolean => {
	// 	return user?.memberOf && user?.memberOf.includes(role.adminGroup);
	// }

	const PostBulkActionButtons: ReactElement<any, string | React.JSXElementConstructor<any>> = (
		<Fragment>
			<CustomBulkDeleteButton Modal={RemoveBulkRoleModal} text={translate('ra.action.delete')} modalProps={{}} />
		</Fragment>
	);

	const roleFilters = [
		<SearchInput name={'q'} source={'id'} alwaysOn />
	]

	const CustomListToolBar = () => (
		<Stack direction="row" justifyContent="space-between" sx={{ width: "100%" }}>
			<FilterForm filters={roleFilters} />
			{user?.role === 1 ? <AddRoleButton roleId={parentId} /> : null}
		</Stack>
	)

	const onChangeRolesColumns = (id: string) => {
		setRoleColumns(oldState => oldState.map(item => {
			if (item.source === id) item.active = !item.active;
			return item;
		}));
	}

	const Header = () => {
		return null
	}

	const ExpandedRoles = () => {
		return (
			<Datagrid
				header={Header}
				bulkActionButtons={false}
				expand={<ExpandChildRole />}
				rowClick={'show'}
				isRowExpandable={row => roles && Boolean(roles.find((item: IRole) => item.parentId === row.id))}
			>
				<FunctionField render={(record: IRole) => (myRoles && myRoles.includes(record.id)) || record.editable ? <img src={OpenIcon} alt="" /> : <img src={LockIcon} alt="" />} />
				<TextField source={'name'} />
				<FunctionField render={(record: IRole) => (myRoles && myRoles.includes(record.id)) || record.editable ? null : user?.role === 1 || record.editable || app.editable ? null : userApprovals && userApprovals.map((item: IApproval) => item.target).includes(String(record.id)) ? <span className={styles.access_button}>{translate('messages.accessRequested')}</span> : <span onClick={(e: any) => { e.stopPropagation(); onShowApprovalModal(record.id) }} className={styles.access_button}>{translate('operations.params.APPROVAL.approveAccess')}</span>} />
				<FunctionField
					label={<CustomColumns columns={roleColumns} onChange={onChangeRolesColumns} />}
					render={(record: any) => (!record.type && user?.role === 1) || record.editable || app.editable ? <RowActionsButton menuProps={{ record: record }} Menu={RoleAppMenu} /> : null}
					sx={{ maxWidth: 40 }}
				/>
			</Datagrid>
		)
	}

	const ExpandChildRole = () => {
		return (
			<ReferenceManyField target={'parentId'} reference={'role'}>
				<ExpandedRoles />
			</ReferenceManyField>
		)
	}

	const ExpandRole = () => {
		const record = useRecordContext();
		return (
			<ReferenceManyField target={'appId'} reference={'role'}>
				<ListBase
					filter={{ parentId: record.id, appId: app.id }}
					disableSyncWithLocation
				>
					<ExpandedRoles />
				</ListBase>
			</ReferenceManyField>
		)
	}

	const EmptyState = () => (
		<div className={styles.empty}>
			<span>{translate('messages.emptyList')}</span>
			{user?.role === 1 ?
				<AddRoleButton roleId={parentId} />
				:
				null
			}
		</div>
	)

	return (
		<div>
			<List
				actions={<CustomListToolBar />}
				disableSyncWithLocation
				resource={'role'}
				sort={{ field: 'name', order: 'ASC' }}
				filter={{ appId: app.id, parentId: parentId }}
				exporter={false}
				empty={<EmptyState />}
				sx={{
					minWidth: "-webkit-fill-available",
					width: "-moz-available",
					overflow: "auto"
				}}
			>
				<Datagrid
					rowClick={'show'}
					expand={<ExpandRole />}
					isRowExpandable={row => roles && Boolean(roles.find((item: IRole) => item.parentId === row.id))}
					isRowSelectable={() => user?.role === 1}
					bulkActionButtons={user?.role === 1 ? PostBulkActionButtons : false}
					sx={{
						'& .RaDatagrid-headerCell': {
							borderColor: "#e0e0e0",
							backgroundColor: "#fff0",
						},
						'& .RaDatagrid-rowCell': {
							maxWidth: 190,
							'&.column-undefined': {
								width: 76,
								'& span': {
									display: "flex",
									alignItems: "center",
									justifyContent: "flex-end"
								},
								'&:not(:last-child)': {
									paddingRight: 0,
								}
							},
						},
						'& .RaDatagrid-expandedPanel': {
							'& .MuiTableCell-root': {
								paddingRight: 0,
								'& .MuiTableCell-root': {
									borderBottom: "none",
									'&:last-child': {
										'&.column-undefined': {
											paddingRight: '16px',
										}
									}
								}
							}
						}
					}}
				>
					{
						roleColumns.filter(item => item.active).map(item => {
							switch (item.source) {
								default: return <TextField source={item.source} label={item.label} key={item.source} />
							}
						}
						)
					}
					<FunctionField render={(record: IRole) => (myRoles && myRoles.includes(record.id)) || record!.editable || app.editable || user?.role === 1 ? null : userApprovals && userApprovals.map((item: IApproval) => item.target).includes(String(record.id)) ? <span className={styles.access_button}>{translate('messages.accessRequested')}</span> : <span onClick={(e: any) => { e.stopPropagation(); onShowApprovalModal(record.id) }} className={styles.access_button}>{translate('operations.params.APPROVAL.approveAccess')}</span>} />
					<FunctionField
						label={<CustomColumns columns={roleColumns} onChange={onChangeRolesColumns} />}
						render={(record: any) => !record.type && (user?.role === 1 || record.editable || app.editable) ? <RowActionsButton menuProps={{ record: record }} Menu={RoleAppMenu} /> : null}
						sx={{ maxWidth: 40 }}
					/>
				</Datagrid>
			</List>
			<CustomModal
				open={showApprovalModal}
				close={onCloseApprovalModal}
			>
				<CreateApprovalModal
					closeModal={onCloseApprovalModal}
					roleId={roleId}
				/>
			</CustomModal>
		</div>
	)
}

export default AppRolesPage;