import React from "react";
import {
  useListController,
  useRefresh,
  useNotify,
  useUnselectAll,
  useDeleteMany, useDataProvider, useTranslate,
} from "react-admin";
import styles from './RemoveBulkAppModal.module.css';
import CloseIcon from "../../../assets/close.png";
import CancelButton from "../../buttons/cancelButton/CancelButton";
import CustomButton from "../../buttons/customButton/CustomButton";
import {IApp} from "../../../utils/types";

type RemoveBulkAppModalProps = {
  closeModal: () => void,
}

const RemoveBulkAppModal = (props: RemoveBulkAppModalProps) => {
  const {closeModal} = props;
  const {selectedIds} = useListController();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();
  const unselectAll = useUnselectAll('app');
  const [apps, setApps] = React.useState<IApp[]>([]);
  const selected = selectedIds.length ? apps?.filter((item: IApp) => selectedIds.includes(item.id)) : [];
  const [deleteMany] = useDeleteMany(
    'app',
    {ids: selectedIds},
    {
      mutationMode: 'undoable',
      onSuccess: () => {
        notify('ra.notification.deleted', { type: 'success', messageArgs: { smart_count: 1 }, undoable: true });
        unselectAll();
        refresh();
      },
      onError: () => notify('ra.notification.canceled', { type: 'warning' })
    }
  )

  const onRemoveApp = () => {
    deleteMany();
    closeModal();
  }

  React.useEffect(() => {
    dataProvider.getMany('app', {ids: selectedIds})
      .then(({data}) => setApps(data))
  }, [dataProvider, selectedIds])

  return (
    <div className={styles.root}>
      <div className={styles.close_button} onClick={closeModal}>
        <img src={CloseIcon} alt=""/>
      </div>
      {
        selectedIds.length > 1
          ? <h1 className={styles.header}>{translate('messages.removeManyAppTitle')}</h1>
          : <h1 className={styles.header}>{translate('messages.removeAppTitle')}</h1>
      }
      {
        !apps.filter((app: IApp) => !app.deletable).length ?
          <div className={styles.wrapper}>
            {
              selectedIds.length > 1
                ?
                  <>
                    <span className={styles.text}>{translate('messages.removeManyApps', { count: selected?.length })}</span>
                    <ul className={styles.list}>
                      {selected?.map((item: IApp) => <li className={styles.list_item} key={item.id}>{item.name}</li>)}
                    </ul>
                  </>
                : <span className={styles.text}>{translate('messages.removeApp', { name: apps[0]?.name })}</span>
            }
            <div className={styles.buttons}>
              <CancelButton text={translate('ra.action.cancel')} onClick={closeModal} />
              <CustomButton text={translate('ra.action.remove')} onClick={onRemoveApp} />
            </div>
          </div> :
          <div className={styles.wrapper}>
            <span className={styles.text}>
              {translate(apps.filter((app: IApp) => !app.deletable).length > 1
                ? 'messages.applicationManyRolesAreAssignedToUsers'
                : 'messages.applicationRolesAreAssignedToUsers')}
            </span>
            <ul className={styles.list}>
              {apps.filter((app: IApp) => !app.deletable).map((app: IApp) => <li className={styles.list_item} key={app.id}>{app.name}</li>)}
            </ul>
            {
              selectedIds.length > 1
              ? <>
                  <span className={styles.text}>{translate('messages.removeManyApps_')}</span>
                  <ul className={styles.list}>
                    {apps.map((app: IApp) => <li className={styles.list_item} key={app.id}>{app.name}</li>)}
                  </ul>
                </>
              : <span className={styles.text}>{translate('messages.removeApp_')}</span>
            }
            <span className={styles.text}>{translate('messages.activeDirectoryGroupsRemain')}</span>
            <div className={styles.buttons}>
              <CancelButton text={translate('ra.action.cancel')} onClick={closeModal} />
              <CustomButton text={translate('ra.action.remove')} onClick={onRemoveApp} />
            </div>
          </div>
      }
    </div>
  )
}

export default RemoveBulkAppModal;