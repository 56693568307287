import React from "react";
import {
  useRefresh,
  useNotify,
  useDelete, useRedirect, useTranslate,
} from "react-admin";
import styles from './RemoveAppModal.module.css';
import CloseIcon from "../../../assets/close.png";
import CancelButton from "../../buttons/cancelButton/CancelButton";
import CustomButton from "../../buttons/customButton/CustomButton";
import {IApp} from "../../../utils/types";

type RemoveAppModalProps = {
  closeModal: () => void,
  record: IApp,
}

const RemoveAppModal = (props: RemoveAppModalProps) => {
  const {closeModal, record} = props;
  const refresh = useRefresh();
  const notify = useNotify();
  const redirect = useRedirect();
  const translate = useTranslate();
  const [deleteOne] = useDelete(
    'app',
    {id: record?.id, previousData: record},
    {
      mutationMode: 'undoable',
      onSuccess: () => {
        redirect('list', 'app');
        notify('ra.notification.deleted', { type: 'success', messageArgs: { smart_count: 1 }, undoable: true });
        refresh();
      },
      onError: () => notify('ra.notification.canceled', { type: 'warning' })
    }
  );

  const onRemoveApp = () => {
    deleteOne();
    closeModal();
  }

  return (
    <div className={styles.root}>
      <div className={styles.close_button} onClick={closeModal}>
        <img src={CloseIcon} alt=""/>
      </div>
      <h1 className={styles.header}>{translate('messages.removeAppTitle')}</h1>
      {
        record.deletable ?
        <div className={styles.wrapper}>
          <span className={styles.text}>{translate('messages.removeApp', {name: record.name})}</span>
          <div className={styles.buttons}>
            <CancelButton text={translate('ra.action.cancel')} onClick={closeModal}/>
            <CustomButton text={translate('ra.action.remove')} onClick={onRemoveApp}/>
          </div>
        </div> :
        <div className={styles.wrapper}>
          <span className={styles.text}>
            {translate('messages.applicationRolesAreAssignedToUsers_', {name: record.name})}
          </span>
          <span className={styles.text}>{translate('messages.removeApp_')}</span>
          <span className={styles.text}>{translate('messages.activeDirectoryGroupsRemain')}</span>
          <div className={styles.buttons}>
            <CancelButton text={translate('ra.action.cancel')} onClick={closeModal}/>
            <CustomButton text={translate('ra.action.remove')} onClick={onRemoveApp}/>
          </div>
        </div>
      }
    </div>
  )
}

export default RemoveAppModal;