import React from "react";
import { ShowBase, useShowController, TabbedShowLayout, Tab, useListController, useGetIdentity, Loading, useTranslate } from 'react-admin';
import ProfileCard from "../../cards/profileCard/ProfileCard";
import MainInfoProfileCard from "../../cards/mainInfoProfileCard/MainInfoProfileCard";
import EmailCard from "../../cards/emailCard/EmailCard";
import styles from './ProfilePage.module.css';
import PhoneCard from "../../cards/phoneCard/PhoneCard";
import PasswordCard from "../../cards/passwordCard/PasswordCard";
import EmploymentCard from "../../cards/employmentCard/EmploymentCard";
import CustomBreadcrumbs from "../../customBreadcrumbs/CustomBreadcrumbs";
import { useNavigate } from "react-router-dom";
import UserRolesPage from "../userRolesPage/UserRolesPage";

const ProfilePage = () => {
	const { record } = useShowController();
	const { filterValues, setFilters, displayedFilters, isLoading } = useListController({ resource: 'user' });
	const { identity: user, isLoading: isLoadingUser } = useGetIdentity();

	const [profile, setProfile] = React.useState({
		givenName: record ? record.id : "",
		sn: "",
		description: "",
		sAMAccountName: "",
		user: "",
		mail: "",
		mobile: "",
		initials: "",
		streetAddress: "",
		postalCode: "",
		facsimileTelephoneNumber: "",
		company: "",
		department: "",
		title: "",
		whenCreated: "",
		accountExpires: "",
		pwdLastSet: "",
		id: record ? record.id : "",
		memberOf: "",
		photo: "",
		userAccountControl: "",
		parentId: "",
		type: 0,
		role: 0,
		roles: [],
		displayName: '',
	});
	const username = localStorage.getItem('username');
	const navigate = useNavigate();
	const translate = useTranslate();

	const onNavigateBreadcrumbs = (e: any) => {
		if (e.target.innerText !== profile.givenName) {
			if (e.target.innerText === translate('operations.entities.USER')) {
				setFilters({ ...filterValues, parentId: '.' }, displayedFilters);
				setTimeout(() => {
					navigate(`/user`);
				}, 600)
			}
			else if (e.target.innerText === filterValues.parentId.split(',')[0]) navigate(`/user`);
			else {
				setFilters({ ...filterValues, parentId: filterValues.parentId.split(',').slice(filterValues.parentId.split(',').indexOf(e.target.innerText)).join(',') }, displayedFilters, true);
				setTimeout(() => {
					navigate(`/user`);
				}, 800)
			}
		}
	}

	React.useEffect(() => {
		if (record) {
			setProfile({
				...profile,
				givenName: record.id,
				whenCreated: record.whenCreated,
				...record,
				memberOf: (record.memberOf || []).join(", ")
			})
		}
		// eslint-disable-next-line
	}, [record])

	if (isLoadingUser || isLoading) return <Loading />

	return (
		username !== profile.id ?
			<div className={styles.wrapper}>
				<CustomBreadcrumbs breadcrumb={`${profile.givenName},${filterValues.parentId}` || ''} onClickBreadcrumb={onNavigateBreadcrumbs} title={translate('operations.entities.USER')} />
				<ShowBase>
					<TabbedShowLayout sx={{ width: '100%' }} syncWithLocation={false}>
						<Tab label={translate('operations.params.USER.userProfile')}>
							<div className={styles.root}>
								<h1>{translate('operations.entities.PROFILE')}</h1>
								<ProfileCard
									profile={profile}
									editable={user?.role === 1}
									deletable={user?.role === 1}
									isAdmin={user?.role === 1}
								/>
								<MainInfoProfileCard profile={profile} editable={profile?.role === 4} />
								<EmailCard profile={profile} editable={user?.role === 1} />
								<PhoneCard profile={profile} editable={user?.role === 1} />
								<EmploymentCard profile={profile} />
							</div>
						</Tab>
						<Tab label={translate('operations.entities.ROLE')} path={`roles`}>
							<UserRolesPage roles={profile.roles && profile.roles.length ? profile.roles : []} user={profile} />
						</Tab>
					</TabbedShowLayout>
				</ShowBase>
			</div> :
			<div className={styles.wrapper}>
				<ShowBase>
					<div className={styles.root}>
						<h1>{translate('operations.entities.PROFILE')}</h1>
						<ProfileCard
							profile={profile}
							editable={false}
							deletable={false}
							isAdmin={user?.role === 1 || user?.isAppAdmin}
						/>
						<MainInfoProfileCard profile={profile} editable={profile?.role === 4 && user?.id !== profile?.id}/>
						<EmailCard profile={profile} editable={true} />
						<PhoneCard profile={profile} editable={true} />
						<PasswordCard profile={profile} />
						<EmploymentCard profile={profile} />
					</div>
				</ShowBase>
			</div>
	)
}

export default ProfilePage;