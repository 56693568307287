import React from 'react';
import { Admin, Resource, defaultTheme, CustomRoutes, useTranslate } from 'react-admin';
import { Route } from "react-router-dom";
import './App.css';
import ProfilePage from "../../components/pages/profilePage/ProfilePage";
import CustomLayout from "../../components/customLayout/CustomLayout";
import dataProvider from "../../provider/dataProvider";
import authProvider from "../../provider/authProvider";
import i18nProvider from "../../provider/i18nProvider";
import UsersPage from "../../components/pages/usersPage/UsersPage";
import CommonEditProfilePages from "../../components/pages/commonEditProfilePages/CommonEditProfilePages";
import CreateProfilePage from "../../components/pages/createProfilePage/CreateProfilePage";
import AppsPage from "../../components/pages/appsPage/AppsPage";
import CreateAppPage from "../../components/pages/createAppPage/CreateAppPage";
import DashboardPage from "../../components/pages/dashboardPage/dashboardPage";
import LoginPage from "../../components/pages/loginPage/loginPage";
import ShowAppPage from "../../components/pages/showAppPage/ShowAppPage";
import OperationPage from '../../components/pages/operationsPage/OperationsPage';
import ApprovalsPage from "../../components/pages/approvalsPage/ApprovalsPage";
import ShowApprovalPage from "../../components/pages/showApprovalPage/ShowApprovalPage";
import ShowRolePage from "../../components/pages/showRolePage/ShowRolePage";
import ConfirmEmailPage from "../../components/pages/confirmEmailPage/ConfirmEmailPage";
import CreateContragentPage from "../../components/pages/createContragentPage/CreateContragentPage";

const theme = {
	...defaultTheme,
	sidebar: {
		width: 350,
		closedWidth: 72,
	},
};

function App() {
	const translate = useTranslate();
	return (
		<div className="App">
			<Admin layout={CustomLayout}
				loginPage={LoginPage}
				dataProvider={dataProvider}
				authProvider={authProvider}
				theme={theme}
				i18nProvider={i18nProvider}
				dashboard={DashboardPage}
				requireAuth
			>
				{<CustomRoutes>
					<Route path="/create_contragent" element={<CreateContragentPage />} />
				</CustomRoutes>}
				{<CustomRoutes noLayout>
					<Route path="/confirm_email" element={<ConfirmEmailPage />} />
				</CustomRoutes>}
				<Resource
					name={"app"}
					options={{ label: translate('operations.entities.APP') }}
					list={AppsPage}
					create={CreateAppPage}
					show={ShowAppPage}
					edit={CreateAppPage}
				/>
				<Resource
					name={"user"}
					options={{ label: translate('operations.entities.USER') }}
					list={UsersPage}
					show={ProfilePage}
					edit={CommonEditProfilePages}
					create={CreateProfilePage}
				/>
				<Resource
					name="operation"
					list={<OperationPage />}
				/>
				<Resource
					name={"approval"}
					list={ApprovalsPage}
					show={ShowApprovalPage}
				/>
				<Resource
					name="role"
					show={ShowRolePage}
				/>
				<Resource
					name="profile"
					show={ProfilePage}
				/>
			</Admin>
		</div>
	);
}

export default App;
