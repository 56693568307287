import React from "react";
import {
  useListController,
  useRefresh,
  useNotify,
  useUnselectAll,
  useDeleteMany,
  useTranslate,
  useDataProvider
} from "react-admin";
import styles from './RemoveBulkRoleModal.module.css';
import CloseIcon from "../../../assets/close.png";
import CancelButton from "../../buttons/cancelButton/CancelButton";
import CustomButton from "../../buttons/customButton/CustomButton";
import {IApp, IRole} from "../../../utils/types";

type RemoveBulkRoleModalProps = {
  closeModal: () => void,
}

const RemoveBulkRoleModal = (props: RemoveBulkRoleModalProps) => {
  const {closeModal} = props;
  const dataProvider = useDataProvider();
  const {selectedIds, data} = useListController({perPage: 1000});
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();
  const unselectAll = useUnselectAll('role');
  const selected = selectedIds.length && data ? data.filter((item: IRole) => selectedIds.includes(item.id)) : [];
  const [roles, setRoles] = React.useState<IRole[]>([]);
  const [deleteMany] = useDeleteMany(
    'role',
    {ids: selectedIds},
    {
      mutationMode: 'undoable',
      onSuccess: () => {
        notify('ra.notification.deleted', { type: 'success', messageArgs: { smart_count: 1 }, undoable: true });
        unselectAll();
        refresh();
      },
      onError: () => notify('ra.notification.canceled', { type: 'warning' })
    }
  )

  const onRemoveRole = () => {
    deleteMany();
    closeModal();
  }

  React.useEffect(() => {
    dataProvider.getMany('role', {ids: selectedIds})
      .then(({data}) => setRoles(data))
  }, [dataProvider, selectedIds])

  return (
    <div className={styles.root}>
      <div className={styles.close_button} onClick={closeModal}>
        <img src={CloseIcon} alt=""/>
      </div>
      {
        selectedIds.length > 1
          ? <h1 className={styles.header}>{translate('messages.removeManyRoleTitle')}</h1>
          : <h1 className={styles.header}>{translate('messages.removeRoleTitle')}</h1>
      }
      {
        !roles.filter((role: IRole) => !role.deletable).length
        ?
          <div className={styles.wrapper}>
            <span className={styles.text}>{translate('messages.removeManyRoles', {count: selected.length})}</span>
            <ul className={styles.list}>
              {selected.map((item: IApp) => <li className={styles.list_item} key={item.id}>{item.name}</li>)}
            </ul>
            <div className={styles.buttons}>
              <CancelButton text={translate('ra.action.cancel')} onClick={closeModal} />
              <CustomButton text={translate('ra.action.remove')} onClick={onRemoveRole}/>
            </div>
          </div>
        :
          <div className={styles.wrapper}>
            <span className={styles.text}>
              {translate(roles.filter((role: IRole) => !role.deletable).length > 1
                  ? 'messages.theRoleIsAssignedToManyUsers'
                  : 'messages.theRoleIsAssignedToUsers')}
            </span>
            <ul className={styles.list}>
              {roles.filter((role: IRole) => !role.deletable).map((item: IRole) => <li className={styles.list_item} key={item.id}>{item.name}</li>)}
            </ul>
            {
              selectedIds.length > 1
              ? <>
                  <span className={styles.text}>{translate('messages.removeManyRoles_')}</span>
                  <ul className={styles.list}>
                    {roles.map((item: IRole) => <li className={styles.list_item} key={item.id}>{item.name}</li>)}
                  </ul>
                </>
              : <span className={styles.text}>{translate('messages.removeRole')}</span>
            }
            <span className={styles.text}>{translate('messages.activeDirectoryGroupsRemain')}</span>
            <div className={styles.buttons}>
              <CancelButton text={translate('ra.action.cancel')} onClick={closeModal} />
              <CustomButton text={translate('ra.action.remove')} onClick={onRemoveRole}/>
            </div>
          </div>
      }
    </div>
  )
}

export default RemoveBulkRoleModal;