import React from "react";
import styles from "./ColumnsList.module.css";
import {IColumns} from "../../../utils/types";
import CustomCheckbox from "../../inputs/customCheckbox/CustomCheckbox";
import { useTranslate } from "react-admin";

type ColumnsListProps = {
  list: IColumns[];
  onChange: (id: string) => void,
}

const ColumnsList = (props: ColumnsListProps) => {
  const {list, onChange} = props;
	const translate = useTranslate();
  return (
    <div className={styles.root}>
      <span className={styles.header}>{translate('operations.params.APP.displayingColumns')}</span>
      {
        list.map(item => (
          <div key={item.source} className={styles.item}>
            <CustomCheckbox checked={item.active} change={() => onChange(item.source)} indeterminate={false} around={false}/>
            <span className={styles.label}>{item.label}</span>
          </div>
        ))
      }
    </div>
  )
}

export default ColumnsList;