import React from "react";
import styles from "./CustomColumns.module.css";
import ColumnIcon from "../../../assets/column.png";
import { IColumns } from "../../../utils/types";
import CustomPopover from "../../popovers/customPopover/CustomPopover";
import ColumnsList from "../../popovers/columnsList/ColumnsList";
import CustomTooltip from "../../tooltips/customTooltip/CustomTooltip";
import { dispatchOpenPopoverEvent } from "../../../utils/utils";
import { useTranslate } from 'react-admin';

type CustomColumnsProps = {
  columns: IColumns[],
  onChange: (id: string) => void,
}

const CustomColumns = (props: CustomColumnsProps) => {
  const { columns, onChange } = props;
  const [anchorElCollapseIcon, setAnchorElCollapseIcon] = React.useState(null);
  const collapseIconRef = React.useRef(null);
  const open = Boolean(anchorElCollapseIcon);
  const translate = useTranslate();

  const handleClose = (e: any) => {
    e.stopPropagation();
    setAnchorElCollapseIcon(null);
  };

  const showPopover = (e: any) => {
    e.stopPropagation();
    if (!open) {
      dispatchOpenPopoverEvent();
      setAnchorElCollapseIcon(collapseIconRef.current);
    } else setAnchorElCollapseIcon(null);
  };

  return (
    <div className={styles.root}>
      <CustomTooltip title={translate('ra.action.display')} >
        <img src={ColumnIcon} alt="" className={styles.icon} ref={collapseIconRef} onClick={showPopover} />
      </CustomTooltip>
      <CustomPopover
        open={open}
        anchorEl={anchorElCollapseIcon}
        handleClose={handleClose}
        ChildComponent={() => <ColumnsList onChange={onChange} list={columns} />}
      />
    </div>
  )
}

export default CustomColumns;