import React from "react";
import styles from './AddAdminButton.module.css';
import PlusIcon from '../../../assets/Add.png'

type AddAdminButtonProps = {
  onClick: () => void,
  text: string,
}

const AddAdminButton = (props: AddAdminButtonProps) => {
  const {onClick, text} = props;

  return (
    <div onClick={onClick} className={styles.button}>
      <img src={PlusIcon} alt="" className={styles.icon}/>
      <span className={styles.text}>{text}</span>
    </div>
  )
}

export default AddAdminButton;