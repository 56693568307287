import React from 'react';
import { Popper, ClickAwayListener } from "@material-ui/core";
import './CustomPopover.css';

type CustomPopoverProps = {
	anchorEl: HTMLAnchorElement | null,
	open: boolean,
	handleClose: (e: any) => void,
	ChildComponent: () => JSX.Element,
}

const CustomPopover = (props: CustomPopoverProps) => {
	const {
		anchorEl,
		open,
		handleClose,
		ChildComponent,
	} = props;

	const onHandleClick = (event: any) => {
		if (!anchorEl?.contains(event.target)) handleClose(event);
	}

	React.useEffect(() => {
		if (open) {
			document.addEventListener('openPopover', onHandleClick);
			return () => document.removeEventListener('openPopover', onHandleClick);
		}
		//eslint-disable-next-line
	}, [open]);

	return (
		<ClickAwayListener onClickAway={onHandleClick}>
			<Popper
				className="custom_popover"
				open={open}
				anchorEl={anchorEl}
			>
				<ChildComponent />
			</Popper>
		</ClickAwayListener>
	);
};

export default CustomPopover;
