import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardContent } from '@mui/material';
import styles from './EditEmailPage.module.css';
import CustomTooltip from "../../tooltips/customTooltip/CustomTooltip";
import backIcon from "../../../assets/Back.png";
import CustomInput from "../../inputs/customInput/CustomInput";
import CancelButton from "../../buttons/cancelButton/CancelButton";
import CustomButton from "../../buttons/customButton/CustomButton";
import { IProfile } from "../../../utils/types";
import { useDataProvider, useNotify, useTranslate } from "react-admin";

type EditEmailPageProps = {
  profile: IProfile
}

const EditEmailPage = (props: EditEmailPageProps) => {
  const { profile } = props;
  const navigate = useNavigate();
  const providerData = useDataProvider();
  const initEmail = profile ? profile?.mail : '';
  const [email, setEmail] = React.useState(profile ? profile?.mail : '');
  const [code, setCode] = React.useState('');
  const [step, setStep] = React.useState('input');
	const translate = useTranslate();

  const onComeBack = () => {
    navigate(-1);
  }

  const onChangeEmail = (v: string) => {
    setEmail(v);
  }

  const notify = useNotify();

  const onSuccess = (res: any) => {
    if (!res?.data?.mail)
      return setStep('confirm');
    else onComeBack();
  };

  const nextStep = () => {
    providerData.update('user', { id: profile.id, data: { mail: email }, previousData: profile })
      .then(onSuccess)
      .catch(() => notify('ra.notification.canceled', { type: 'warning' }))
  }

  const confirmEmail = () => {
    providerData.create('confirmEmail', { data: { token: code }})
      .then(() => {
        notify('ra.notification.updated', { type: 'success', messageArgs: { smart_count: 1 }, undoable: false });
        onComeBack();
      })
      .catch((error) => {
        notify(error.body.error === 'Token is invalid' ? 'ra.notification.codeError' : 'ra.notification.canceled', {type: 'warning'})
      });
  }

  const onChangeCode = (v: string) => {
    setCode(v);
  }

  const validateEmail = () => {
    return !Boolean(email?.length && email
      .toLowerCase()
      .match(
        // eslint-disable-next-line
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*){2,}|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]{2,}\.)+[a-zA-Z]{2,}))$/
      ));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.root}>
        <header>
          <CustomTooltip title={translate('ra.action.comeback')}>
            <div className={styles.back_icon} onClick={onComeBack}>
              <img src={backIcon} alt="icon" />
            </div>
          </CustomTooltip>
          {
            step === 'input' ? <h1>{profile?.mail && profile?.mail.length ? translate('operations.params.EMAIL.changeEmail') : translate('operations.params.EMAIL.addEmail')}</h1> : <h1>{`${translate('operations.params.EMAIL.confirmEmail')} ${email}`}</h1>
          }
        </header>
        <Card>
          <CardContent>
            <div className={styles.row_item}>
              <span className={styles.row_item__label}>{step === 'input' ? translate('operations.params.EMAIL.enterEmailAddress') : translate('operations.params.EMAIL.enterTheSentCode')}</span>
              {
                step === 'input' ?
                  <CustomInput placeholder={translate('operations.params.EMAIL.enterYourEmailAddress')} mode={!validateEmail() || !email?.length ? 'root' : 'error'} value={email} changeInput={onChangeEmail} errorMessage={translate('operations.params.EMAIL.invalidFormat')} focus={false} /> :
                  <CustomInput placeholder={translate('operations.params.EMAIL.enterTheCode')} mode={'root'} value={code} changeInput={onChangeCode} errorMessage={''} focus={false} />
              }
            </div>
            <div className={styles.buttons}>
              <CancelButton text={translate('ra.action.abort')} onClick={onComeBack} />
              {
                step === 'input' ?
                  <CustomButton text={translate('ra.action.continue')} onClick={nextStep} disable={validateEmail() || initEmail === email}/> :
                  <CustomButton text={translate('ra.action.confirm')} onClick={confirmEmail} disable={!code.length}/>
              }
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

export default EditEmailPage;