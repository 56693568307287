import React from "react";
import styles from './CustomBulkDeleteButton.module.css';
import CustomModal from "../../modals/customModal/CustomModal";

type CustomBulkDeleteButtonProps = {
  Modal: (p: any) => JSX.Element,
  text: string,
  modalProps: Object,
}

const CustomBulkDeleteButton = (props: CustomBulkDeleteButtonProps) => {
  const {Modal, text, modalProps} = props;
  const [open, setOpen] = React.useState(false);

  const onShowModal = () => {
    setOpen(true);
  }

  const onCloseModal = () => {
    setOpen(false);
  }

  return (
    <div>
      <button className={styles.button} onClick={onShowModal}>{text}</button>
      <CustomModal
        open={open}
        close={onCloseModal}
      >
        <Modal
          {...modalProps}
          closeModal={onCloseModal}
        />
      </CustomModal>
    </div>
  )
}

export default CustomBulkDeleteButton;