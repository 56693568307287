import React from "react";
import {
  useRefresh,
  useNotify,
  useTranslate,
} from "react-admin";
import styles from './MovingAppModal.module.css';
import CloseIcon from "../../../assets/close.png";
import CancelButton from "../../buttons/cancelButton/CancelButton";
import CustomButton from "../../buttons/customButton/CustomButton";
import { fetchProvider } from "../../../provider/authProvider";
import { IApp, IRole } from '../../../utils/types';

type MovingAppModalProps = {
  closeModal: () => void,
  onPasteApp: IApp,
  onPasteRole?: IRole,
}

const MovingAppModal = (props: MovingAppModalProps) => {
  const { closeModal, onPasteApp, onPasteRole } = props;
	const [error, setError] = React.useState<string>('');
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();
  let savedMoving = JSON.parse(localStorage.getItem('moving')!);  
  const onConfirmPasteId = async () => {
    return await fetchProvider(`/api/moveApp/${savedMoving.id}`,
      'POST',
      {
        targetAppId: onPasteApp?.id,
        targetRoleId: onPasteRole?.id,
      }
    )
      .then(res => {
        if (!res.ok) throw res;
        notify('operations.params.STATUS.success', { type: 'success' });
        refresh();
        localStorage.removeItem('moving');
        closeModal();
      })
      .catch(error => {
        error.text().then((err: any) => {
          JSON.parse(err).error === "can't move app into itself"
            ? setError('operations.params.APP.cantMoveAppIntoItself')
            : JSON.parse(err).error === "app not found"
              ? setError('operations.params.APP.appNotFound')
              : setError('messages.error')
        }
        );
      })
  }

  return (
    <div className={styles.root}>
      <div className={styles.close_button} onClick={closeModal}>
        <img src={CloseIcon} alt="" />
      </div>
      {
        !error
          ? <h1 className={styles.header}>{translate('operations.params.APP.moveTheApplication')}</h1>
          : <h1 className={styles.header}>{translate('messages.error')}!</h1>
      }
      
      {
        !error
          ?
            <div className={styles.wrapper}>
              <span className={styles.text}>
                {
                  translate('operations.params.APP.confirmMovingApp',
                    {
                      app: savedMoving?.name,
                      role: onPasteApp
                        ? onPasteApp?.name
                        : onPasteRole?.name
                    }
                  )
                }
              </span>
              <span className={styles.text}>{translate('operations.params.APP.movingAppInfo')}</span>
              <div className={styles.buttons}>
                <CancelButton
                  text={translate('ra.action.cancel')}
                  onClick={closeModal}
                />
                <CustomButton
                  text={translate('operations.params.APP.move')}
                  onClick={onConfirmPasteId}
                />
              </div>
            </div>
          :
            <div className={styles.wrapper}>
            <span className={styles.text}>{translate(`${error}`)}</span>
            <div className={styles.buttons}>
              <CustomButton text={translate('ra.action.close')} onClick={closeModal}/>
            </div>
      </div>
      }
    </div>
  )
}

export default MovingAppModal;