import * as React from "react";
import { Card, CardContent } from '@mui/material';
import { useNavigate } from "react-router-dom";
import styles from './PhoneCard.module.css';
import DotsMenuButton from "../../buttons/dotsMenuButton/DotsMenuButton";
import { IProfile } from "../../../utils/types";
import AddButton from "../../buttons/addButton/AddButton";
import { useTranslate } from "react-admin";

type PhoneCardProps = {
  profile: IProfile,
  editable: boolean
}

const PhoneCard = (props: PhoneCardProps) => {
  const { profile, editable } = props;
  const navigate = useNavigate();
  const translate = useTranslate();

  const editPhone = () => {
    navigate(`/user/${profile.id}/edit/phone`);
  }

  return (
    <Card>
      <CardContent>
        <div className={styles.root}>
          <h1 className={styles.header}>{translate('operations.params.PHONE.phone')}</h1>
          <span className={styles.phone}>{profile.mobile}</span>
          {
            editable && profile.mobile && profile.mobile.length ? <div className={styles.button}>
              <DotsMenuButton onChange={editPhone} />
            </div> :
              editable && (!profile.mobile || !profile.mobile.length) ?
                <div className={styles.button}>
                  <AddButton
                    text={translate('ra.action.add')}
                    onClick={editPhone} />
                </div> : null
          }
        </div>
      </CardContent>
    </Card>
  )
};

export default PhoneCard;